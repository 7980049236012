/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

export default class AssetLinks extends React.Component {
  render() {
    const data = [{
      "relation": ["delegate_permission/common.handle_all_urls"],
      "target": {
        "namespace": "android_app",
        "package_name": "com.kikt.kiktapp",
        "sha256_cert_fingerprints":
        ["01:27:15:96:40:EF:F1:BB:24:59:04:2E:85:E0:92:30:F1:74:D4:6D:03:1B:F9:08:3F:C0:84:DB:33:C9:C9:D4"]
      }
    }];
    return (
        <div>
          <pre>
            {
              JSON.stringify(data, null, 2)
            }
          </pre>
        </div>
    );
  }
}
