import React from "react";
import {Container} from "reactstrap"

export default class PrivacyPolicy extends React.Component {
  render(){
    return(
      <Container>
      <div>
        <h1>
          Privacy Policy
        </h1>
        <h3>
          LAST UPDATED
        </h3>
        <p>
          June 16th, 2020
        </p>
        <h3>
          OUR COMMITMENT TO PRIVACY
        </h3>
        <p>
        Kikt LLC takes privacy seriously. Our goal is to protect your information. Any questions or concerns regarding your personal information and how it will be used should be directed to contact@kikt.io.
        </p>
        <h3>
          APPLICABILITY OF PRIVACY POLICY
        </h3>
        <p>
        This privacy policy applies to all information collected through our Services from former and current Kikt users, including you. “Services” refers to any products, services, content, features, technologies, or functions, and all related websites, applications and services offered to you by Kikt in connection with a Kikt account. We continue to share the information of former users.
        Throughout this policy, we use the term "personal information" to describe information that can be used to identify a specific person. Personal information does not include information that has been anonymized so that it does not identify a specific user.
        </p>
        <h3>
          THE INFORMATION WE COLLECT
        </h3>
        <p>
        If you open a Kikt account, we may collect the following information from you:
        </p>
        <ul>
          <li>Identification Information - your name, street address, email address, date of birth, and SSN (or other governmental issued verification numbers).</li>
          <li>Device Information - information about you: (a) from your mobile device or computer such as your time zone, language setting and (b) from third parties for purposes of transaction processing, identity verification, fraud detection or prevention and other similar purposes.</li>
            <li>Financial Information - bank account online login information, bank account and routing numbers and credit cards linked to your Kikt account.</li>
        </ul>
        <p>
        At Kikt, we are committed to providing a safe, secure and excellent service. Therefore, before allowing you to use the Services we offer, we may require additional information (your address or other information) from you which shall not only be used to verify your identity but also to manage risk and compliance throughout our relationship. When you are using Kikt, we collect information about your account transactions for fraud prevention and other similar purposes.
Finally, Kikt may collect additional information from or about you in other ways not specifically described here. For example, we may collect information through your contact with our customer support team or from results from your response to a survey.
        </p>
        <h3>
          INFORMATION FROM CHILDREN
        </h3>
        <p>
        Kikt is not intended for the use of children under the age of 13. If we obtain knowledge that we have stored personal information from a child under the age of 13, we will immediately delete it, unless we are legally obligated to retain such data. Contact us if you believe that we have mistakenly or unintentionally collected data from a child under the age of 13.
        </p>
        <h3>
          HOW WE USE COOKIES
        </h3>
        <p>
          When you visit or use Kikt we may use cookies. We use Cookies to recognize you as a customer.
        </p>
        <h3>
          HOW WE PROTECT & STORE PERSONAL INFORMATION
        </h3>
        <p>
        We use third party servers located in data centers in the United States to store and process your personal information. We protect this personal information using physical, electronic and procedural safeguards in compliance with applicable US federal and state regulations. We also use computer safeguards such as firewalls and data encryption, and access to your personal information shall only be granted to employees who require it to fulfill their job responsibilities.
        </p>
        <p>
        We are committed to ensuring security on our systems. However, we cannot guarantee that personal information may not be accessed, modified or destroyed by breach of our safeguards. Therefore, we recommend that you take the necessary precautions to secure your personal data as well, including never sharing your Kikt password with anyone.
        </p>
        <p>
        If Kikt obtains knowledge of a systems security breach, we may attempt to notify you electronically so that you can take appropriate steps to protect your data. By using Kikt, you agree that Kikt may communicate with you electronically. Kikt may post a notice on our website or mobile application in the case of a security breach. We may also send you an email at the address you have provided on your Kikt account. Depending on where you live, you may have the legal right to receive notice of a security breach in writing. To receive free written notice of a security breach (or to withdraw your consent from receiving electronic notice of a security breach), please email us at contact@kikt.io.
        </p>
        <h3>
          HOW WE USE THE PERSONAL INFORMATION WE COLLECT
        </h3>
        <p>
        Our main goal in collecting your personal information is to provide you with a safe, efficient, fun and customized experience. We may use your personal information to:
        </p>
        <ul>
          <li>provide the services and customer support you request;</li>
          <li>process transactions and send notices about your transactions or your network activity;</li>
          <li>resolve disputes, collect fees, and troubleshoot problems;</li>
          <li>prevent potentially fraudulent, prohibited or illegal activities, and enforce our User Agreement through the use of our risk and fraud tools which may include use of Account Information, Identification Information, Financial Information, Device Information, Social Web Information and Geolocation Information;</li>
          <li>create an account connection between your account and a third-party account or platform;</li>
          <li>customize, personalize, measure, and improve our services and the content and layout of our website;</li>
          <li>send you updates about new products and services that we are offering to customers;</li>
          <li>compare information for accuracy and verify it with third parties;</li>
          <li>perform other duties as required by law.</li>
        </ul>
        <h3>
          HOW WE SHARE PERSONAL INFORMATION WITHIN THE KIKT NETWORK
        </h3>
        <p>
        To process payments on Kikt, we need to share some of your personal information with the person or company that you are paying or is paying you. Your contact information, sign-up date, the number of payments you have received and other verification metrics like social graph activity may be provided to users or companies when you transact with them, or through Kikt.
        </p>
        <p>
        We work with merchants to enable them to accept payments from you using Kikt. In order to do so, a vendor may have to share information about you with us, such as your mobile phone number or Kikt username upon payment. This information is used by us to confirm to the merchant that you are a Kikt user and that the merchant should enable Kikt as a method of payment for your purchase.
        </p>
        <p>
        In any case, we will not share your credit card number or bank account information with anyone you have completed a transaction with through Kikt, except with your express permission or if we are required to do so in order to comply with legal matters.
        </p>
        <h3>
        HOW WE SHARE PERSONAL INFORMATION WITH OTHER PARTIES
        </h3>
        <p>Kikt does not share your personal information with third parties for promotional or marketing purposes.</p>
        <p>We may share your personal information with:</p>
        <ul>
          <li>Companies that Kikt plans to merge with or be acquired by or, in the event of any bankruptcy, a bankruptcy estate. Should such a combination occur, we will require that the new combined entity follow this privacy policy with respect to your personal information. If your personal information could be used contrary to this policy, you will receive prior notice and the opportunity to communicate preferences you may have, if applicable.</li>
          <li>Law enforcement, government officials, or other third parties if Kikt is compelled to do so by a subpoena, court order or similar legal procedure, when it is necessary to do so to comply with law, or where the disclosure of personal information is reasonably necessary to prevent physical harm or financial loss, to report suspected illegal activity, or to investigate violations of the Kikt User Agreement, or as otherwise required by law.</li>
          <li>Third party service providers who assist us in providing services to you or who provide fraud detection or similar services on our or any vendor’s behalf.</li>
          <li>The other Kikt user participating in the transaction and, depending on the privacy setting of each Kikt account transaction, your Kikt friends and the Kikt friends of the other user participating in the transaction.</li>
          <li>Service providers under contract who help with parts of our business operations (for example, fraud prevention, payment processing, or technology services). Our contracts dictate that these service providers only use your information in connection with the services they perform for us and not for their own benefit.</li>
          <li>Other third parties with your consent or at your direction to do so, including if you authorize an account connection with a third-party account or platform.</li>
        </ul>
        <p>For the purposes of this privacy policy, an "account connection" with such a third party is a connection you authorize or enable between your Kikt account and a non-Kikt account, payment instrument, or platform that you lawfully control or own. When you authorize such a connection, Kikt and the third-party will exchange your personal information and other information directly. Examples of account connections include, without limitation: linking your Kikt account to a social media account or social messaging service; connecting your Kikt account to a third-party data aggregation or financial services company, if you provide such company with your Kikt account log-in credentials; or using your Kikt account to make payments to a merchant or allowing a merchant to charge your Kikt account.</p>
        <p>Kikt does not send your personal information to third-party social networks unless you have specifically requested or authorized us to do so. When you broadcast information to such third-party social networks, such information is no longer under the control of Kikt and is subject to the terms of use and privacy policies of such third parties.</p>
        <h3>
          HOW YOU CAN ACCESS OR CHANGE YOUR PERSONAL INFORMATION
        </h3>
        <p>
        You can review and update your personal information in the account settings at any time by logging in to your Kikt account.
        </p>
        <h3>
          LINKS TO OTHER SERVICES OR SITES
        </h3>
        <p>
        The Services may contain links to (or allow you to link to) other third-party services or websites. Kikt does not control the data collection of third-party services or websites that can be reached through such links. We recommend that our users be aware when they are linking to a third-party service or website and we urge them to read the privacy statements of any third-party service or website that collects personally identifiable information.
        </p>
        <h3>
          CHANGES TO OUR PRIVACY POLICY
        </h3>
        <p>
        Kikt is always improving. As our Services evolve, we may occasionally update this privacy policy. If we make changes to this privacy policy, we will post the revised privacy policy to the website, and we will also revise the "last updated date" stated above. If we make material changes in the way we use personal information, we will notify you by posting an announcement on our mobile application and website or by sending you an e-mail. It is your responsibility to periodically review this privacy policy; users are bound by any changes to the privacy policy by using the Services after such changes have been first posted.
        </p>
        <h3>
          HOW TO CONTACT US
        </h3>

        <p>
        If you have questions or concerns regarding this privacy policy, or any feedback pertaining to your privacy and the Services that you would like us to consider, please email us at contact@kikt.io.
        </p>
      </div>
      </Container>
    )
  }
}
