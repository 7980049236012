/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import "./assets/scss/argon-design-system-react.scss";

// import Index from "./views/Index.jsx";
import Landing from "./views/examples/Landing.jsx";
import Login from "./views/examples/Login.jsx";
// import Profile from "./views/examples/Profile.jsx";
import Register from "./views/examples/Register.jsx";
import ForgotPassword from "./views/examples/ForgotPassword.jsx";
import PrivacyPolicy from "../../privacy/PrivacyPolicy";
import TermsOfService from "../../privacy/TermsOfService";
import TestQRCode from "./views/examples/TestQRCode.jsx";
import KiktQRCode from "./views/examples/KiktQRCode.jsx";
import Support from "./views/examples/Support.jsx";
import AmazonWatch from "./views/examples/AmazonWatch.jsx";
import Success from "./views/examples/Success.jsx";
import MyDocument from "./views/examples/MyDocument.jsx";
import HowTo from "./views/examples/HowTo.jsx";
import AppleAppSiteAssociation from "./views/examples/AppleAppSiteAssociation";
import AssetLinks from "./views/examples/AssetLinks";
import KiktId from "./views/examples/KiktId.jsx";
import First100Promo from "./views/examples/First100Promo.jsx";
import ARSimulation from "./views/examples/ARSimulation.jsx";
import VaccineVerificationLink from "./views/examples/VaccineVerificationLink.jsx";
import PDFPass from "./views/VaccinePass/PDFPass.js"

export default class Unauthorized extends React.Component{
  render(){
    return(
      <BrowserRouter>
        <Switch>
          <Route path="/" exact render={props => <Landing {...props} />} />
          <Route
            path="/landing-page"
            exact
            render={props => <Landing {...props} />}
          />
          <Route path="/login" exact render={props => <Login {...props} />} />
          <Route
            path="/register"
            exact
            render={props => <Register {...props} />}
          />
          <Route
            path="/forgot-password"
            exact
            render={props => <ForgotPassword/>}
          />
          <Route
            path="/augmented-reality"
            exact
            render={props => <ARSimulation/>}
          />
          {/*<Route
            path="/how-to-register"
            exact
            render={props => <HowTo {...{file:require("./assets/how-to/HOW-TO-PERSONAL-ACCOUNT.pdf")}}/>}
          />
          <Route
            path="/how-to-add-things"
            exact
            render={props => <HowTo {...{file:require("./assets/how-to/HOW-TO-MENUS.pdf")}}/>}
          />
          <Route
            path="/how-to-integrate"
            exact
            render={props => <HowTo {...{file:require("./assets/how-to/HOW-TO-INTEGRATE.pdf")}}/>}
          />
          <Route
            path="/how-to-all"
            exact
            render={props => <HowTo {...{file:require("./assets/how-to/HOW-TOs.pdf")}}/>}
          />*/}
          <Route
            path="/kikt-qr-code/:id"
            exact
            render={props => <MyDocument {...{type:"merchant",...props}}/>}
          />
          <Route
            path="/personal-kikt-qr-code/:id"
            exact
            render={props => <MyDocument {...{type:"customer",...props}}/>}
          />
          <Route
            path="/privacy-policy"
            exact
            render={props=><PrivacyPolicy {...props} />}
          />
          <Route
            path="/terms-of-service"
            exact
            render={props=><TermsOfService {...props} />}
          />
          <Route
            path="/test-qr-code"
            exact
            render={props=><TestQRCode {...props}/>}
          />
          <Route
            path="/kikt-qr-code"
            exact
            render={props=><KiktQRCode {...props}/>}
          />
          <Route
            path="/kikt-merchant-id"
            exact
            render={props=><KiktId {...{type:"merchant",...props}}/>}
          />
          <Route
            path="/kikt-customer-id"
            exact
            render={props=><KiktId {...{type:"customer",...props}}/>}
          />
          <Route
            path="/support"
            exact
            render={props=><Support {...props}/>}
          />
          <Route
            path="/vaccine-verification"
            exact
            render={props=><VaccineVerificationLink {...props}/>}
          />
          <Route
            path="/amazon-watch"
            exact
            render={props=><AmazonWatch {...props}/>}
          />
          <Route
            path="/brooklyn-hospital-center"
            exact
            render={props=><AmazonWatch {...props}/>}
          />
          <Route
            path="/success"
            exact
            render={props=><Success {...props}/>}
          />
          <Route
            path="/.well-known/apple-app-site-association"
            exact
            render={props=><AppleAppSiteAssociation {...props}/>}
          />
          <Route
            path="/.well-known/assetlinks.json"
            exact
            render={props=><AssetLinks {...props}/>}
          />
          <Route
            path="/first-100-promo"
            exact
            render={props=><First100Promo {...props}/>}
          />
          <Route
            path="/pdf-pass/:id"
            exact
            render={props => <PDFPass {...props}/>}
          />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    )
  }
}
