import React from 'react';
import QRCode from 'qrcode.react';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import {withGetScreen} from 'react-getscreen';
import {isAndroid, isIOS} from 'react-device-detect';

// Create Document Component
let height = 1.4137*width
let width = 800

class MyDocument extends React.Component{
  state={
    src:""
  }
// <div>
//   <img src={this.props.file} style={{width:width,height:height}}/>
  // <QRCode
  //   style={{position:"absolute",alignSelf:"center",justifyContent:"center",top:440,left:200,display:"block"}}
  //   getRef={c => (this.svg = c)}
  //   id={"ExpoCustomerId"}
  //   value={JSON.stringify({customerId:this.props.match.params.id})}
  //   size={400}
  //   level={"H"}
  //   includeMargin={true}
  // />
//   </div>


    render(){
      return(
        <div style={{backgroundColor:"#6656A5",width:"100%",minHeight:"100vh",justifyContent:"center",alignItems:"center",display:"flex",height:"100%",flexDirection:"column"}}>
            <h1 style={{textAlign:"center",fontWeight:"bold",color:"white",fontSize:35,maxWidth:"100vh",marginTop:(this.props.type==="customer")?30:0}} className="img-fluid floating">
              {this.props.type==="merchant"?
                "Get access to menus, make payments, and receive rewards with Kikt!"
                :
                "Send me money through Kikt!"
              }
            </h1>
            <div style={{height:450,alignItems:"center",backgroundColor:"white",width:"85%",maxWidth:450,display:"flex",justifyContent:"center",flexDirection:"column",borderRadius:10,border:'10px solid #ECAE57',marginTop:10}} className="img-fluid floating">
              <h1 style={{textAlign:"center",fontWeight:"bold",color:"#6656A5",marginBottom:15,fontSize:45}}>
                Scan me!
              </h1>
              <QRCode
                getRef={c => (this.svg = c)}
                id={"ExpoCustomerId"}
                value={JSON.stringify({customerId:this.props.match.params.id})}
                size={275}
                level={"H"}
              />
            </div>
            {this.props.isMobile()?
              <div style={{flexDirection:"column",display:"flex",height:250,justifyContent:"center",marginTop:10}}>
                {/*<h2 style={{textAlign:"center",fontWeight:"bold",color:"white"}}>
                  www.kikt.io
                </h2>*/}
                {/*<Button
                 // href="https://play.google.com/store/apps/details?id=com.kikt.kiktapp"
                 style={{background:"transparent",borderWidth:0,margin:10}}>*/}
                 <a href="https://www.kikt.io">
                   <img
                     alt="Kikt Logo"
                     src={require("../../assets/img/icons/WhiteLogoNoBorder.png")}
                     className="img-fluid floating"
                     style={{width:75*2.58,height:75,cursor:"pointer"}}
                   />
                 </a>
                 <a href="https://play.google.com/store/apps/details?id=com.kikt.kiktapp">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    style={{width:75*2.58,height:75,cursor:"pointer"}}
                    src={require("../../assets/img/icons/common/google-play-badge.svg")}
                  />
                </a>
                {/*</Button>*/}
                {/*<Button style={{background:"transparent",borderWidth:0,margin:10}}>*/}
                <a href="https://apps.apple.com/us/app/id1490925873">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    style={{width:75*2.58,height:75,cursor:"pointer"}}
                    src={require("../../assets/img/icons/common/app-store-badge.svg")}
                  />
                </a>
                {/*</Button>*/}
              </div>
              :
              <div style={{flexDirection:"row",width:"85%",display:"flex",justifyContent:"space-between",height:250,marginTop:20,maxWidth:"90vh"}}>
              <div style={{flexDirection:"column",marginLeft:40,display:"flex",height:250}}>
                {/*<h2 style={{textAlign:"center",fontWeight:"bold",color:"white"}}>
                  www.kikt.io
                </h2>*/}
                {/*<Button
                 // href="https://play.google.com/store/apps/details?id=com.kikt.kiktapp"
                 style={{background:"transparent",borderWidth:0,margin:10}}>*/}
                 <a href="https://play.google.com/store/apps/details?id=com.kikt.kiktapp">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    style={{width:75*2.58,height:75,cursor:"pointer"}}
                    src={require("../../assets/img/icons/common/google-play-badge.svg")}
                  />
                  </a>
                {/*</Button>*/}
                {/*<Button style={{background:"transparent",borderWidth:0,margin:10}}>*/}
                <a href="https://apps.apple.com/us/app/id1490925873">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    style={{width:75*2.58,height:75,cursor:"pointer"}}
                    src={require("../../assets/img/icons/common/app-store-badge.svg")}
                  />
                </a>
                {/*</Button>*/}
              </div>
              <a href="https://www.kikt.io">
                <div style={{flexDirection:"column",alignItems:"center",display:"flex",marginRight:40,justifyContent:"center",cursor:"pointer"}} className="img-fluid floating">
                  <h1 style={{textAlign:"center",fontWeight:"bold",color:"white",fotnSize:20}}>
                    Powered by
                  </h1>
                  <img
                    alt="Kikt Logo"
                    src={require("../../assets/img/icons/WhiteLogoNoBorder.png")}
                    width="220"
                    style={{}}
                  />
                </div>
              </a>
            </div>}
        </div>
      )
    }
}
export default withGetScreen(MyDocument)


// ReactPDF.render(<MyDocument />, `${__dirname}/output.pdf`);
