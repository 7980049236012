/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:f95816f8-5da2-4052-a1a5-bdcffbf66998",
    "aws_cognito_region": "us-east-1",
    "oauth": {},
    "aws_mobile_analytics_app_id": "e757beb2c619452f81f6818dc2914d55",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
