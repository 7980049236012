import React from "react";
// reactstrap components
import VaxContainer from "./VaxContainer";
import VaxHeader from "./VaxHeader";
import "./stylesheets/IntroScreen.scss";
import introScreenImage from "../../assets/img/vaxpass/introScreenImage.png";
import checkmark from "../../assets/img/vaxpass/checkmark.svg";
import { Link } from "react-router-dom";
import { BlueButton } from "./Buttons";

class WelcomeScreens extends React.Component {
  render() {
    return (
      <VaxContainer>
        <VaxHeader canGoBack={false} />
        <div className="mb-5 vax-intro-screen">
          <div className="d-flex" style={{marginLeft: "2vh"}}>
            <img
              src="https://screenshot-files-k1999.s3.amazonaws.com/intro1.png"
              alt=""
              className="mb-3 mx-auto intro-screen-img"
            ></img>
          </div>
          <h5 className="mt-3 vax-pass-img-caption">
            Businesses use <h5 className="vax-pass-text">VAX PASS</h5> to verify
            you've been vaccinated against or tested negative for Covid-19
          </h5>
          <hr className="divider"></hr>
          <div className="mx-5">
            <div className="d-flex title-text mb-3">
              <img src={checkmark} className="mr-2" alt=""></img>{" "}
              <h5 className="vax-pass-img-caption mb-0">SECURE</h5>
            </div>
            <h6 className="text-center text-light">
              Transfer of your data is encrypted and secure.
            </h6>
          </div>
          <hr className="divider"></hr>
          <div className="mx-5">
            <div className="d-flex title-text mb-3">
              <img src={checkmark} className="mr-2" alt=""></img>{" "}
              <h5 className="vax-pass-img-caption mb-0">PRIVATE</h5>
            </div>
            <h6 className="text-center text-light">
              After the verification of your data, access to your medical
              record is disabled.
            </h6>
          </div>
          <hr className="divider"></hr>
          <p className="mx-5 mt-3 mb-4">
            By tapping 'Next' you agree to our{" "}
            <Link to="/terms-of-service" className="white-text">
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link to="/privacy-policy" className="white-text">
              Privacy Policy
            </Link>
            .
          </p>
          <div>

          </div>
          <BlueButton
            onClick={() => this.props.switchScreen("SelectVerificationScreen")}
          >
            NEXT
          </BlueButton>
        </div>
      </VaxContainer>
    );
  }
}

export default WelcomeScreens;
