import React from "react";
import "./stylesheets/VaxContainer.scss";

export default function VaxContainer(props) {
  return (
    <div className="vax-container">
      <div className={(props.selectedInnerContainer)?"select-provider-inner-container":"vax-inner-container"}>{props.children}</div>
    </div>
  );
}
