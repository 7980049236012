/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";
import {Auth} from 'aws-amplify'

class AdminNavbar extends React.Component {
  constructor(props){
    super(props)
  }

  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-lg-inline-block"
              to="/"
            >
              {this.props.brandText}
            </Link>

            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle" style={{backgroundColor:"#5CCAEB"}}>
                    <i className="fa fa-user" style={{color:"white"}}/>
                  </span>
                    <Media className="ml-2 d-lg-block d-none">
                      <span className="mb-0 text-sm font-weight-bold">
                        {this.props.user.firstName} {this.props.user.lastName}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem to="/index" tag={Link}>
                    <i className="fa fa-laptop" />
                    <span>Dashboard</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/transactions" tag={Link}>
                    <i className="ni ni-calendar-grid-58" />
                    <span>Transactions</span>
                  </DropdownItem>
                  {this.props.user.merchantEnabled&&<DropdownItem to="/admin/menu" tag={Link}>
                    <i className="fa fa-cutlery" />
                    <span>Orders</span>
                  </DropdownItem>}
                  {this.props.user.merchantEnabled&&<DropdownItem to="/admin/monthlyStatements" tag={Link}>
                    <i className="ni ni-calendar-grid-58" />
                    <span>Monthly Statements</span>
                  </DropdownItem>}
                  {this.props.user.merchantEnabled&&<DropdownItem to="/admin/menu" tag={Link}>
                    <i className="ni ni-calendar-grid-58" />
                    <span>Menu</span>
                  </DropdownItem>}
                  {/*<DropdownItem to="/admin/transactions" tag={Link}>
                    <i className="ni ni-support-16" />
                    <span>Support</span>
                  </DropdownItem>*/}
                  <DropdownItem divider />
                  <DropdownItem onClick={()=>{Auth.signOut();window.location.reload()}} style={{cursor:"pointer"}}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
