import React from "react";
// reactstrap components
import { Container, Form } from "reactstrap";
import VaxContainer from "./VaxContainer";
import VaxHeader from "./VaxHeader";
import instsantVerificationMainImage from "../../assets/img/vaxpass/instantVerificationMainImage.png";
import "./stylesheets/InstantVerificationScreen.scss";
import { BlueButton } from "./Buttons";
import idIcon from "../../assets/img/vaxpass/idIcon.svg";
import userIcon from "../../assets/img/vaxpass/userIcon.svg";
import calendarIcon from "../../assets/img/vaxpass/calendarIcon.svg";
class InstantVerificationScreen extends React.Component {
  state = {
    username: "",
    password: "",
  };

  verify_record(e) {
    if (this.state.username === "" || this.state.password === "") {
      return;
    }
    e.preventDefault();
    let input = {
      username: this.state.username,
      password: this.state.password,
      login_url: this.props.loginUrl,
    };
    this.props.fetchData("/instant-verification", input);
    this.props.switchScreen("LoadingScreen");
  }

  render() {
    return (
      <VaxContainer>
        <VaxHeader
          canGoBack={true}
          goBack={() => this.props.switchScreen("SelectProvider")}
        />
        <Form>
          <div className="instant-verification-screen">
            <div className="d-flex justify-content-center mt-5 mb-4">
              <img
                src={instsantVerificationMainImage}
                alt=""
                className="logo-img"
                style={{height:"35vh"}}
              ></img>
            </div>
            <Container>
              <h6>
                VaxPass allows patients to temporarily share parts of their
                health summary, including immunizations
              </h6>

              <div className="inputwrapper">
                <h5 className="title">ENTER YOUR INFORMATION</h5>

                <div className="text-input-wrapper">
                  <div className="text-icon-left">
                    <img src={userIcon} alt=""></img>
                  </div>
                  <input
                    placeholder="Username"
                    className="text-input"
                    onChange={(e) =>
                      this.setState({ username: e.target.value })
                    }
                    required={true}
                  />
                </div>

                <div className="text-input-wrapper">
                  <div className="text-icon-left">
                    <img src={idIcon} alt=""></img>
                  </div>
                  <input
                    placeholder="Password"
                    className="text-input"
                    type="password"
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                    required={true}
                  />
                </div>
              </div>
            </Container>
            <BlueButton onClick={(e) => this.verify_record(e)} type="submit">
              VERIFY
            </BlueButton>
          </div>
        </Form>
      </VaxContainer>
    );
  }
}

export default InstantVerificationScreen;
