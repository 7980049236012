import React from "react";

export function BlueButton(props) {
  const {
    onClick,
    containerStyles = {},
    buttonStyles = {},
    children,
    className = "",
    type
  } = props;
  return (
    <div
      className={"d-flex vax-rounded-button-container " + className}
      style={containerStyles}
    >
      <button
        className="vax-rounded-button-blue"
        onClick={onClick}
        style={buttonStyles}
        type={type}
      >
        {children}
      </button>
    </div>
  );
}

export function BlackButton(props) {
  const {
    onClick,
    containerStyles = {},
    buttonStyles = {},
    children,
    className = "",
  } = props;
  return (
    <div
      className={"d-flex vax-rounded-button-container " + className}
      style={containerStyles}
    >
      <button
        className="vax-rounded-button-black"
        onClick={onClick}
        style={buttonStyles}
      >
        {children}
      </button>
    </div>
  );
}

export function WhiteButton(props) {
  const {
    onClick,
    containerStyles = {},
    buttonStyles = {},
    children,
    className = "",
  } = props;
  return (
    <div
      className={"d-flex vax-rounded-button-container " + className}
      style={containerStyles}
    >
      <button
        className="vax-rounded-button-outline-white"
        onClick={onClick}
        style={buttonStyles}
      >
        {children}
      </button>
    </div>
  );
}
