/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert,
  Label
} from "reactstrap";
import { ClipLoader } from 'react-spinners';
// core components
import DemoNavbar from "../../components/Navbars/DemoNavbar.jsx";
import SimpleFooter from "../../components/Footers/SimpleFooter.jsx";

import {Auth} from 'aws-amplify'

let email_reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/
let name_reg = /^[a-zA-Z]{2,30}$/
let password_reg = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{12,}/
let dob_reg = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/
let city_reg = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/
let postalCode_reg = /\d{5}([ \-]\d{4})?/
let state_reg = /\b([A-Z]{2})\b/
let ssn_reg = /^[0-9]{4}$/
let ein_reg = /^[1-9]\d?-\d{7}$/
let country_reg =/^(AF|AX|AL|DZ|AS|AD|AO|AI|AQ|AG|AR|AM|AW|AU|AT|AZ|BS|BH|BD|BB|BY|BE|BZ|BJ|BM|BT|BO|BQ|BA|BW|BV|BR|IO|BN|BG|BF|BI|KH|CM|CA|CV|KY|CF|TD|CL|CN|CX|CC|CO|KM|CG|CD|CK|CR|CI|HR|CU|CW|CY|CZ|DK|DJ|DM|DO|EC|EG|SV|GQ|ER|EE|ET|FK|FO|FJ|FI|FR|GF|PF|TF|GA|GM|GE|DE|GH|GI|GR|GL|GD|GP|GU|GT|GG|GN|GW|GY|HT|HM|VA|HN|HK|HU|IS|IN|ID|IR|IQ|IE|IM|IL|IT|JM|JP|JE|JO|KZ|KE|KI|KP|KR|KW|KG|LA|LV|LB|LS|LR|LY|LI|LT|LU|MO|MK|MG|MW|MY|MV|ML|MT|MH|MQ|MR|MU|YT|MX|FM|MD|MC|MN|ME|MS|MA|MZ|MM|NA|NR|NP|NL|NC|NZ|NI|NE|NG|NU|NF|MP|NO|OM|PK|PW|PS|PA|PG|PY|PE|PH|PN|PL|PT|PR|QA|RE|RO|RU|RW|BL|SH|KN|LC|MF|PM|VC|WS|SM|ST|SA|SN|RS|SC|SL|SG|SX|SK|SI|SB|SO|ZA|GS|SS|ES|LK|SD|SR|SJ|SZ|SE|CH|SY|TW|TJ|TZ|TH|TL|TG|TK|TO|TT|TN|TR|TM|TC|TV|UG|UA|AE|GB|US|UM|UY|UZ|VU|VE|VN|VG|VI|WF|EH|YE|ZM|ZW|AFG|ALB|DZA|ASM|AND|AGO|AIA|ATA|ATG|ARG|ARM|ABW|AUS|AUT|AZE|BHS|BHR|BGD|BRB|BLR|BEL|BLZ|BEN|BMU|BTN|BOL|BIH|BWA|BVT|BRA|IOT|VGB|BRN|BGR|BFA|BDI|KHM|CMR|CAN|CPV|CYM|CAF|TCD|CHL|CHN|CXR|CCK|COL|COM|COD|COG|COK|CRI|CIV|CUB|CYP|CZE|DNK|DJI|DMA|DOM|ECU|EGY|SLV|GNQ|ERI|EST|ETH|FRO|FLK|FJI|FIN|FRA|GUF|PYF|ATF|GAB|GMB|GEO|DEU|GHA|GIB|GRC|GRL|GRD|GLP|GUM|GTM|GIN|GNB|GUY|HTI|HMD|VAT|HND|HKG|HRV|HUN|ISL|IND|IDN|IRN|IRQ|IRL|ISR|ITA|JAM|JPN|JOR|KAZ|KEN|KIR|PRK|KOR|KWT|KGZ|LAO|LVA|LBN|LSO|LBR|LBY|LIE|LTU|LUX|MAC|MKD|MDG|MWI|MYS|MDV|MLI|MLT|MHL|MTQ|MRT|MUS|MYT|MEX|FSM|MDA|MCO|MNG|MSR|MAR|MOZ|MMR|NAM|NRU|NPL|ANT|NLD|NCL|NZL|NIC|NER|NGA|NIU|NFK|MNP|NOR|OMN|PAK|PLW|PSE|PAN|PNG|PRY|PER|PHL|PCN|POL|PRT|PRI|QAT|REU|ROU|RUS|RWA|SHN|KNA|LCA|SPM|VCT|WSM|SMR|STP|SAU|SEN|SCG|SYC|SLE|SGP|SVK|SVN|SLB|SOM|ZAF|SGS|ESP|LKA|SDN|SUR|SJM|SWZ|SWE|CHE|SYR|TWN|TJK|TZA|THA|TLS|TGO|TKL|TON|TTO|TUN|TUR|TKM|TCA|TUV|VIR|UGA|UKR|ARE|GBR|UMI|USA|URY|UZB|VUT|VEN|VNM|WLF|ESH|YEM|ZMB|ZWE)$/
let phone_reg = /^\d{10}$/

const initialState = {
  merchantTypeSelected:false,
  agrees:false,
  buttonDisabled:true,
  firstName:'',
  lastName:'',
  verificationCode:'',
  accountType:"",
  dateOfBirth:'UNSPECIFIED',
  phoneNumber:"",
  address1:'UNSPECIFIED',
  city:'UNSPECIFIED',
  state:'UNSPECIFIED',
  postalCode:'UNSPECIFIED',
  businessName:'UNSPECIFIED',
  ein:'UNSPECIFIED',
  ssn:'UNSPECIFIED',
  businessType:'UNSPECIFIED',
  controllerFirstName:"",
  controllerLastName:"",
  controllerTitle:"",
  controllerDateOfBirth:"",
  controllerAddress1:"UNSPECIFIED",
  controllerCity:"UNSPECIFIED",
  controllerStateProvinceRegion:"UNSPECIFIED",
  controllerPostalCode:"UNSPECIFIED",
  controllerSsn:"UNSPECIFIED",
  controller:"UNSPECIFIED",
  awaitingEmailVerification:false,
  alertMessage:'',
  showAlert:false,
  merchantTypeSelected:true,
  buttonDisabled:false,
  passwordVisible:false
}

class Register extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  _handleKeyDown = (e) =>{
    if (e.key==="Enter"){
      if (this.state.awaitingEmailVerification){
        this.confirm()
      }
    }
  }
  state = Object.assign({},initialState)
  renderMerchantPrompts(){
    if (this.state.awaitingEmailVerification){
      return(
        [
          {field:"Verification Code",icon:"fa fa-check",type:"verificationCode",dwollaFormat:"verificationCode"},
        ].map((item,i)=>{
          return(
            <FormGroup key={"code"}>
              <InputGroup className="input-group-alternative mb-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className={item.icon} />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder={item.field} type={item.type} onChange={(e)=>this.setState({[item.dwollaFormat]:e.target.value})} onKeyDown={(e)=>this._handleKeyDown(e)}/>
              </InputGroup>
            </FormGroup>
          )
        })
      )
    }
    else if (this.state.accountType==""){
      // return(
      //   <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
      //   {[
      //     {field:`Kikt for a Cause`,type:"decideAccount",description:"Raise Money for a Cause"},
      //     {field:"Kikt for Business",type:"businessAcount",description:"Process Business Payments"}
      //   ].map((item,i)=>{
      //     return(
      //       <div style={{alignItems:"center",justifyContent:"center",width:400}}>
      //       <h4 class="lead" style={{alignItems:"center",textAlign:"center"}}>
      //         {item.description}
      //       </h4>
      //       <Button
      //         color="secondary"
      //         type="button"
      //         key={"option"+i.toString()}
      //         onClick={()=>{this.setState({accountType:item.type});(item.type==="personalAccount")&&this.setState({buttonDisabled:false})}}
      //         style={{marginTop:10,marginBottom:30,width:200,alignSelf:"center",marginLeft:95}}
      //       >
      //         {item.field}
      //       </Button>
      //       </div>
      //     )
      //   })}</div>
      // )
      items = [
        {field:"Email",icon:"ni ni-email-83",type:"email",dwollaFormat:"email"},
        {field:"First Name",icon:"",type:"text",dwollaFormat:"firstName"},
        {field:"Last Name",icon:"",type:"text",dwollaFormat:"lastName"},
        {field:"Phone Number", icon:"",type:"text",dwollaFormat:"phoneNumber"},
        {field:"Password",icon:"ni ni-lock-circle-open",type:"password",dwollaFormat:"password"}
      ]
    return(
      items.map((item,i)=>{
        return(
          <FormGroup key={"field"+i.toString()}>
          <label
            className="form-control-label"
            htmlFor="input-username"
          >
          {item.field}
          </label>
            <InputGroup className="input-group-alternative mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className={item.icon} />
                </InputGroupText>
              </InputGroupAddon>
              <Input placeholder={item.field} type={((item.dwollaFormat==="password")&&(this.state.passwordVisible))?("text"):(item.type)} onChange={(e)=>this.setState({[item.dwollaFormat]:e.target.value})} value={this.state[item.dwollaFormat]}/>
              {item.dwollaFormat==="password"&&<InputGroupText>
                <i className={(this.state.passwordVisible)?"fas fa-eye-slash":"fas fa-eye"} onClick={()=>this.setState({passwordVisible:!this.state.passwordVisible})} style={{cursor:"pointer"}}/>
              </InputGroupText>}
            </InputGroup>
          </FormGroup>
        )
      })
    )
    }
    else if (!this.state.merchantTypeSelected&&(this.state.accountType==="businessAcount")){
      return(
        <div style={{display:"flex",flexDirection:"column"}}>
        {[
          {field:"Sole proprietorship",type:"soleProprietorship"},
          {field:"Unincorporated Association",type:"soleProprietorship"},
          {field:"Trust",type:"soleProprietorship"},
          {field:"Corporation",type:"corporation"},
          {field:"Publicly Traded Corporation",type:"corporation"},
          {field:"Non-Profit",type:"corporation"},
          {field:"LLCs",type:"llc"},
          {field:"Partnership, LP, or LLP",type:"partnership"},
        ].map((item,i)=>{
          return(
            <Button className="mt-4"
              color="secondary"
              type="button"
              key={"option"+i.toString()}
              onClick={()=>{console.log("PRESSED");this.setState({merchantTypeSelected:true,buttonDisabled:false,merchantType:item.type,businessType:item.type})}}
            >
              {item.field}
            </Button>
          )
        })}</div>
      )
    }
    else if (!this.state.merchantTypeSelected&&(this.state.accountType==="decideAccount")){
      return(
        <div style={{display:"flex",flexDirection:"column"}}>
        {[
          {field:"Personal Account",type:"personalAccount"},
          {field:"Non-Profit",type:"corporation"}
        ].map((item,i)=>{
          return(
            <Button className="mt-4"
              color="secondary"
              type="button"
              key={"option"+i.toString()}
              onClick={()=>{console.log("PRESSED");this.setState({merchantTypeSelected:true,buttonDisabled:false,accountType:item.type})}}
            >
              {item.field}
            </Button>
          )
        })}</div>
      )
    }
    else{
      var items = []
      if (this.state.accountType==="personalAccount"){
        items = [
          {field:"Email",icon:"ni ni-email-83",type:"email",dwollaFormat:"email"},
          {field:"First Name",icon:"",type:"text",dwollaFormat:"firstName"},
          {field:"Last Name",icon:"",type:"text",dwollaFormat:"lastName"},
          {field:"Phone Number (XXXXXXXXXX)",icon:"",type:"text",dwollaFormat:"phoneNumber"},
          {field:"Date of Birth YYYY-MM-DD",icon:"",type:"text",dwollaFormat:"dateOfBirth"},
          {field:"Title for your Cause",icon:"",type:"text",dwollaFormat:"dba"},
          {field:"Street Address",icon:"",type:"text",dwollaFormat:"address1"},
          {field:"City",icon:"",type:"text",dwollaFormat:"city"},
          {field:"State Abbreviation (i.e. NY)",icon:"",type:"text",dwollaFormat:"state"},
          {field:"Postal Code",icon:"",type:"text",dwollaFormat:"postalCode"},
          {field:"Last 4 SSN",icon:"",type:"text",dwollaFormat:"ssn"},
          {field:"Password",icon:"ni ni-lock-circle-open",type:"password",dwollaFormat:"password"},
        ]
      }
      else if (this.state.merchantType==="soleProprietorship"){
        items = [
          {field:"Email",icon:"ni ni-email-83",type:"email",dwollaFormat:"email"},
          {field:"First Name",icon:"",type:"text",dwollaFormat:"firstName"},
          {field:"Last Name",icon:"",type:"text",dwollaFormat:"lastName"},
          {field:"Phone Number (XXXXXXXXXX)",icon:"",type:"text",dwollaFormat:"phoneNumber"},
          {field:"Title i.e. CEO",type:"text",dwollaFormat:"title"},
          {field:"Date of Birth YYYY-MM-DD",icon:"",type:"text",dwollaFormat:"dateOfBirth"},
          {field:"Street Address",icon:"",type:"text",dwollaFormat:"address1"},
          {field:"City",icon:"",type:"text",dwollaFormat:"city"},
          {field:"State Abbreviation (i.e. NY)",icon:"",type:"text",dwollaFormat:"state"},
          {field:"Postal Code",icon:"",type:"text",dwollaFormat:"postalCode"},
          {field:"Business Name",icon:"",type:"text",dwollaFormat:"businessName"},
          {field:"Doing Business As",icon:"",type:"text",dwollaFormat:"dba"},
          {field:"Last 4 SSN",icon:"",type:"text",dwollaFormat:"ssn"},
          {field:"Password",icon:"ni ni-lock-circle-open",type:"password",dwollaFormat:"password"},
        ]
      }
      else{
        items = [
          {field:"Email",icon:"ni ni-email-83",type:"email",dwollaFormat:"email"},
          {field:"First Name",icon:"",type:"text",dwollaFormat:"firstName"},
          {field:"Last Name",icon:"",type:"text",dwollaFormat:"lastName"},
          {field:"Phone Number (XXXXXXXXXX)",icon:"",type:"text",dwollaFormat:"phoneNumber"},
          {field:"Date of Birth YYYY-MM-DD",icon:"",type:"text",dwollaFormat:"dateOfBirth"},
          {field:"Street Address",icon:"",type:"text",dwollaFormat:"address1"},
          {field:"City",icon:"",type:"text",dwollaFormat:"city"},
          {field:"State Abbreviation (i.e. NY)",icon:"",type:"text",dwollaFormat:"state"},
          {field:"Postal Code",icon:"",type:"text",dwollaFormat:"postalCode"},
          {field:"Business Name",icon:"",type:"text",dwollaFormat:"businessName"},
          {field:"Doing Business As",icon:"",type:"text",dwollaFormat:"dba"},
          {field:"EIN (XX-XXXXXXX)",icon:"",type:"text",dwollaFormat:"ein"},
          {field:"Controller's First Name",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"controllerFirstName"},
          {field:"Controller's Last Name",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"controllerLastName"},
          {field:"Controller's Title i.e. CEO",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"controllerTitle"},
          {field:"Controller's Date of Birth YYYY-MM-DD",secureTextEntry:false,autoCapitalize:"none",dwollaFormat:"controllerDateOfBirth"},
          {field:"Controller's Street Address",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"controllerAddress1"},
          {field:"Controller's City",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"controllerCity"},
          {field:"Controller's State (abbreviation i.e. NY)",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"controllerStateProvinceRegion"},
          {field:"Controller's Postal Code",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"controllerPostalCode"},
          {field:"Controller's Country (ISO code, i.e. US)",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"controllerCountry"},
          {field:"Controller's Last 4 SSN (if US resident)",secureTextEntry:true,autoCapitalize:"none",dwollaFormat:"controllerSsn"},
          {field:"Controller's Passport Number (if not US resident)",secureTextEntry:true,autoCapitalize:"none",dwollaFormat:"controllerSsn"},
          {field:"Password",icon:"ni ni-lock-circle-open",type:"password",dwollaFormat:"password"}
        ]
      }
      return(
        items.map((item,i)=>{
          return(
            <FormGroup key={"field"+i.toString()}>
            <label
              className="form-control-label"
              htmlFor="input-username"
            >
            {item.field}
            </label>
              <InputGroup className="input-group-alternative mb-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className={item.icon} />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder={item.field} type={item.type} onChange={(e)=>this.setState({[item.dwollaFormat]:e.target.value})}/>
              </InputGroup>
            </FormGroup>
          )
        })
      )
    }
  }
  reinitializeState(){
    this.setState(initialState)
  }
  async validateDataFormats(bypass){
    if (email_reg.test(this.state.email)==false){
      this.setState({showAlert:true,alertMessage:"Please enter a valid email"})
      return false
    }
    else if (this.state.firstName===""){
      this.setState({showAlert:true,alertMessage:"Please enter a valid first name"})
      return false
    }
    else if (this.state.lastName===""){
      this.setState({showAlert:true,alertMessage:"Please enter a valid last name"})
      return false
    }
    else if (password_reg.test(this.state.password)==false){
      this.setState({showAlert:true,alertMessage:"Password must contain at least one number, one uppercase and lowercase letter, and at least 12 characters"})
      return false
    }
    else if (phone_reg.test(this.state.phoneNumber)==false){
      this.setState({showAlert:true,alertMessage:"Invalid phone number (only American numbers supported). Note: Phone number must be in the format XXXXXXXXXX."})
      return false
    }
    else if(bypass==="newSignUp"){
      console.log("Killing validation of non-used fields")
      return true
    }
    else if ((dob_reg.test(this.state.dateOfBirth)==false) || (this.state.dateOfBirth == "UNSPECIFIED")){
      this.setState({showAlert:true,alertMessage:"Please enter your date of birth in a valid format, YYYY-MM-DD"})
      return false
    }
    else if (this.state.address1 == "UNSPECIFIED"){
      this.setState({showAlert:true,alertMessage:"Please enter a valid street address"})
      return false
    }
    else if ((city_reg.test(this.state.city)==false) || (this.state.city == "UNSPECIFIED")){
      this.setState({showAlert:true,alertMessage:"Please enter a valid city name"})
      return false
    }
    else if ((state_reg.test(this.state.state)==false) || (this.state.state == "UNSPECIFIED")){
      this.setState({showAlert:true,alertMessage:"Please enter a valid state abbreviation"})
      return false
    }
    else if ((postalCode_reg.test(this.state.postalCode)==false) || (this.state.postalCode == "UNSPECIFIED")){
      this.setState({showAlert:true,alertMessage:"Please enter a valid postal code"})
      return false
    }
    else  if (this.state.ssn=="ssn" && (ssn_reg.test(this.state.ssn)==false)){
      this.setState({showAlert:true,alertMessage:"Please enter the last 4 digits of your ssn"})
        return false
      }
    else if ((ein_reg.test(this.state.ein)==false && this.state.businessType!="soleProprietorship")&&(this.state.accountType!=="personalAccount")){
      this.setState({showAlert:true,alertMessage:"Please enter a valid ein, XX-XXXXXXX"})
      return false
    }
    return true
  }
  controllerSignUp(){
    try{
      if (this.state.businessType=="soleProprietorship"){return true}
      console.log("Evaluating Controller")
      if (name_reg.test(this.state.controllerFirstName)==false){
        this.setState({showAlert:true,alertMessage:"Please Enter a Valid First Name"})
        return false
      }
      else if (name_reg.test(this.state.controllerLastName)==false){
        this.setState({showAlert:true,alertMessage:"Please Enter a Valid Last Name"})
        return false
      }
      else if (this.state.controllerAddress1=="UNSPECIFIED"){
        this.setState({showAlert:true,alertMessage:"Please Enter a Street Address"})
        return false
      }
      else if (this.state.controllerPostalCode==false){
        this.setState({showAlert:true,alertMessage:"Please Enter a Postal Code"})
        return false
      }
      else if (dob_reg.test(this.state.controllerDateOfBirth)==false){
        this.setState({showAlert:true,alertMessage:"Please Enter a Valid Date of Birth YYYY-MM-DD"})
        return false
      }
      else if (ssn_reg.test(this.state.controllerSsn)==false&&(this.state.controllerCountry!="US")){
        this.setState({showAlert:true,alertMessage:"Please Enter a Valid SSN Number"})
        return false
      }
      else if (country_reg.test(this.state.controllerCountry)==false){
        if (this.state.controllerCountry!="US" && this.state.controllerpassportNumber=="UNSPECIFIED"){
          this.setState({showAlert:true,alertMessage:"If your identification is not from the US, please insert your passport number"})
          return false
        }
        else if ((this.state.controllerpassportNumber=="UNSPECIFIED")||(this.state.controllerpassportNumber=="")){
          this.setState({showAlert:true,alertMessage:"Please insert your passport number"})
          return false
        }
      }
      else{
        var jsonified_controller={}
        // If all the checks pass then we register information in JSON format and
        // continue with the Sign Up Process
        if (this.state.controllerCountry=="US"){
          jsonified_controller = {
            firstName:this.state.controllerFirstName,
            lastName:this.state.controllerLastName,
            title:this.state.controllerTitle,
            dateOfBirth: this.state.dateOfBirth,
            ssn: this.state.controllerSsn,
            address: {
              address1: this.state.controllerAddress1,
              city: this.state.controllerCity,
              stateProvinceRegion: this.state.controllerStateProvinceRegion,
              postalCode: this.state.controllerPostalCode,
              country: this.state.controllerCountry
            }
          }
        }
        else {
          jsonified_controller = {
            firstName:this.state.controllerFirstName,
            lastName:this.state.controllerLastName,
            title:this.state.controllerTitle,
            dateOfBirth: this.state.dateOfBirth,
            ssn: this.state.controllerSsn,
            address: {
              address1: this.state.controllerAddress1,
              city: this.state.controllerCity,
              stateProvinceRegion: this.state.controllerStateProvinceRegion,
              postalCode: this.state.controllerPostalCode,
              country: this.state.controllerCountry
            }
          }
        }
        this.setState({controller:JSON.stringify(jsonified_controller)});
      }
      console.log("Finished Evaluating Controller")
      return true
    }
    catch (err){
      console.log(err)
    }
  }
  async registerAccount(){
    try{
      console.log("Signing up user")
      this.setState({loading:true})
      var passedControllerValidation, passedValidation
      if (true){
        passedValidation = await this.validateDataFormats("newSignUp")
        passedControllerValidation=true
      }
      else if (this.state.accountType==="personalAccount"){
        passedValidation = await this.validateDataFormats()
        passedControllerValidation = true
      }
      else{
        passedValidation = await this.validateDataFormats()
        passedControllerValidation = await this.controllerSignUp()
      }
      if (!passedValidation || !passedControllerValidation){
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
        this.setState({loading:false})
        return
      }
      const {email, firstName, lastName, dateOfBirth, address1, city, state, postalCode, businessName, ein, ssn, password, businessType, controller, phoneNumber, dba} = this.state
      const user = await Auth.signUp({
        username: this.state.email,
        password:this.state.password,
        attributes: {
          email: this.state.email,
          given_name: this.state.firstName,
          family_name:this.state.lastName,
          phone_number:"+1"+this.state.phoneNumber,
          "custom:businessName":"UNSPECIFIED",
          "custom:ssn":"UNSPECIFIED"
        }
      })
      this.setState({awaitingEmailVerification:true,loading:false,showAlert:false})
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.main.scrollTop = 0;
      console.log("Finished Initial Sign up Phase")
    }
    catch (err){
      console.log(err)
    }
  }
  async confirm(){
    try{
      console.log("Confirming Account Creation")
      this.setState({loading:true,awaitingEmailVerification:true})
      const response = await Auth.confirmSignUp(this.state.email,this.state.verificationCode)
      await Auth.signIn(this.state.email,this.state.password)
      window.location.reload()
      console.log("Finished confirming, reloading page")
    }
    catch (err){
      if (err.code=="CodeMismatchException"){
        alert(err.message)
        this.setState({loading:false,awaitingEmailVerification:true})
      }
      else{
        console.log(err)
      }
    }
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 shape-default">
            </div>
            {this.state.showAlert&&<Alert color="danger">
              <strong>Warning</strong> {this.state.alertMessage}
            </Alert>}
            <Container className="pt-lg-md" style={{marginBottom:800}}>
              <Row className="justify-content-center">
                <Col lg="8">
                  <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                    {/*(!this.state.buttonDisabled||!this.state.accountType=="")&&!this.state.awaitingEmailVerification&&<div onClick={()=>this.reinitializeState()} style={{cursor:"pointer",marginBottom:30}}>
                      <i className="fa fa-arrow-left"/>
                    </div>*/}
                    {this.state.buttonDisabled&&!this.state.awaitingEmailVerification&&
                      <div className="text-center text-muted mb-4">
                        <small>Sign Up</small>
                      </div>
                    }
                      <div role="form">
                      {this.renderMerchantPrompts()}
                        {/*<div className="text-muted font-italic">
                          <small>
                            password strength:{" "}
                            <span className="text-success font-weight-700">
                              strong
                            </span>
                          </small>
                        </div>*/}
                        {!this.state.awaitingEmailVerification&&(this.state.merchantTypeSelected||this.state.accountType==="personalAccount")&&<Row className="my-4">
                          <Col xs="12">
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <input
                                className="custom-control-input"
                                id="customCheckRegister"
                                type="checkbox"
                                onChange={()=>this.setState({agrees:!this.state.agrees})}
                                disabled={this.state.buttonDisabled}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customCheckRegister"
                              >
                                <span>
                                  I agree with the Kikt{" "}
                                  <a
                                    href="/privacy-policy"
                                  >
                                    Privacy Policy
                                  </a>
                                  {" "}and{" "}
                                  <a
                                    href="/terms-of-service"
                                  >
                                    Terms of Service{" "}
                                  </a>
                                  as well as our payment partner Dwolla's {" "}
                                  <a href="https://www.dwolla.com/legal/tos/" >
                                    Terms of Service{" "}
                                  </a>
                                  and{" "}
                                  <a href="https://www.dwolla.com/legal/privacy/" >
                                    Privacy Policy
                                  </a>
                                </span>
                              </label>
                            </div>
                          </Col>
                        </Row>}
                        {!this.state.buttonDisabled!=""&&<div className="text-center">
                          {this.state.loading ?
                            <div style={{alignItems:"center",justifyContent:"center",display:"flex"}}>
                              <ClipLoader loading={this.state.loading} />
                            </div>
                          :
                            !this.state.awaitingEmailVerification ?
                            <Button
                            className="mt-4"
                            color="primary"
                            type="button"
                            disabled={this.state.buttonDisabled||!this.state.agrees}
                            onClick={()=>this.registerAccount()}
                            >
                              Agree & Continue
                            </Button>
                            :
                              <Button
                                className="mt-4"
                                color="primary"
                                type="button"
                                disabled={this.state.verificationCode===''||this.state.loading}
                                onClick={()=>this.confirm()}
                              >
                                Verify
                              </Button>
                        }
                        </div>}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Register;
