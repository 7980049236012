/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Label
} from "reactstrap";

// core components
import DemoNavbar from "../../components/Navbars/DemoNavbar.jsx";
import SimpleFooter from "../../components/Footers/SimpleFooter.jsx";
import { ClipLoader } from 'react-spinners';

import {Auth, Storage} from 'aws-amplify'
import {updateCustomer} from '../../../../graphql/mutations';
import API, { graphqlOperation } from '@aws-amplify/api';
import {PhotoPicker} from 'aws-amplify-react';

let email_reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/
let name_reg = /^[a-zA-Z]{2,30}$/
let password_reg = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/
let dob_reg = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/
let city_reg = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/
let postalCode_reg = /\d{5}([ \-]\d{4})?/
let state_reg = /\b([A-Z]{2})\b/
let ssn_reg = /^(\d{3}-?\d{2}-?\d{4})$/
let ein_reg = /^[1-9]\d?-\d{7}$/
let country_reg =/^(AF|AX|AL|DZ|AS|AD|AO|AI|AQ|AG|AR|AM|AW|AU|AT|AZ|BS|BH|BD|BB|BY|BE|BZ|BJ|BM|BT|BO|BQ|BA|BW|BV|BR|IO|BN|BG|BF|BI|KH|CM|CA|CV|KY|CF|TD|CL|CN|CX|CC|CO|KM|CG|CD|CK|CR|CI|HR|CU|CW|CY|CZ|DK|DJ|DM|DO|EC|EG|SV|GQ|ER|EE|ET|FK|FO|FJ|FI|FR|GF|PF|TF|GA|GM|GE|DE|GH|GI|GR|GL|GD|GP|GU|GT|GG|GN|GW|GY|HT|HM|VA|HN|HK|HU|IS|IN|ID|IR|IQ|IE|IM|IL|IT|JM|JP|JE|JO|KZ|KE|KI|KP|KR|KW|KG|LA|LV|LB|LS|LR|LY|LI|LT|LU|MO|MK|MG|MW|MY|MV|ML|MT|MH|MQ|MR|MU|YT|MX|FM|MD|MC|MN|ME|MS|MA|MZ|MM|NA|NR|NP|NL|NC|NZ|NI|NE|NG|NU|NF|MP|NO|OM|PK|PW|PS|PA|PG|PY|PE|PH|PN|PL|PT|PR|QA|RE|RO|RU|RW|BL|SH|KN|LC|MF|PM|VC|WS|SM|ST|SA|SN|RS|SC|SL|SG|SX|SK|SI|SB|SO|ZA|GS|SS|ES|LK|SD|SR|SJ|SZ|SE|CH|SY|TW|TJ|TZ|TH|TL|TG|TK|TO|TT|TN|TR|TM|TC|TV|UG|UA|AE|GB|US|UM|UY|UZ|VU|VE|VN|VG|VI|WF|EH|YE|ZM|ZW|AFG|ALB|DZA|ASM|AND|AGO|AIA|ATA|ATG|ARG|ARM|ABW|AUS|AUT|AZE|BHS|BHR|BGD|BRB|BLR|BEL|BLZ|BEN|BMU|BTN|BOL|BIH|BWA|BVT|BRA|IOT|VGB|BRN|BGR|BFA|BDI|KHM|CMR|CAN|CPV|CYM|CAF|TCD|CHL|CHN|CXR|CCK|COL|COM|COD|COG|COK|CRI|CIV|CUB|CYP|CZE|DNK|DJI|DMA|DOM|ECU|EGY|SLV|GNQ|ERI|EST|ETH|FRO|FLK|FJI|FIN|FRA|GUF|PYF|ATF|GAB|GMB|GEO|DEU|GHA|GIB|GRC|GRL|GRD|GLP|GUM|GTM|GIN|GNB|GUY|HTI|HMD|VAT|HND|HKG|HRV|HUN|ISL|IND|IDN|IRN|IRQ|IRL|ISR|ITA|JAM|JPN|JOR|KAZ|KEN|KIR|PRK|KOR|KWT|KGZ|LAO|LVA|LBN|LSO|LBR|LBY|LIE|LTU|LUX|MAC|MKD|MDG|MWI|MYS|MDV|MLI|MLT|MHL|MTQ|MRT|MUS|MYT|MEX|FSM|MDA|MCO|MNG|MSR|MAR|MOZ|MMR|NAM|NRU|NPL|ANT|NLD|NCL|NZL|NIC|NER|NGA|NIU|NFK|MNP|NOR|OMN|PAK|PLW|PSE|PAN|PNG|PRY|PER|PHL|PCN|POL|PRT|PRI|QAT|REU|ROU|RUS|RWA|SHN|KNA|LCA|SPM|VCT|WSM|SMR|STP|SAU|SEN|SCG|SYC|SLE|SGP|SVK|SVN|SLB|SOM|ZAF|SGS|ESP|LKA|SDN|SUR|SJM|SWZ|SWE|CHE|SYR|TWN|TJK|TZA|THA|TLS|TGO|TKL|TON|TTO|TUN|TUR|TKM|TCA|TUV|VIR|UGA|UKR|ARE|GBR|UMI|USA|URY|UZB|VUT|VEN|VNM|WLF|ESH|YEM|ZMB|ZWE)$/

var BASE64_MARKER = ';base64,';

function convertDataURIToBinary(dataURI) {
  var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  var base64 = dataURI.substring(base64Index);
  var raw = window.atob(base64);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));

  for(var i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}

class Verification extends React.Component {
  state={
    loading:true,
    controllerImageType:"passport",
    controllerImageLocation:null,
    businessImageLocation:null,
    businessVerificationPhoto:null,
    controllerVerificationPhoto:null,
    beneficialConfirmationStep:false,
    beneficialOwners:[]
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    if (this.props.user.controllerJson!=null){
      const controller = JSON.parse(this.props.user.controllerJson);
      console.log("CONTROLLER",controller)
      if (this.props.user.verificationStatus!="addBeneficialOwner"){
        this.setState({
          ssn:"UNSPECIFIED",
          controllerSsn:"",
          controllerCity:controller.address.city,
          controllerTitle:controller.title,
          controllerFirstName:controller.firstName,
          controllerStateProvinceRegion:controller.address.stateProvinceRegion,
          controllerDateOfBirth:controller.dateOfBirth,
          controllerLastName:controller.lastName,
          controllerAddress1:controller.address.address1,
          controllerPostalCode:controller.address.postalCode,
          controllerCountry:controller.address.country,
          beneficialOwnerPhoto:null,
          loading:false
          })
      }
      else{
        this.setState({loading:false,ssn:'',beneficialOwners:[]})
      }
    }
    else{
      this.setState({
        merchantTypeSelected:false,
        agrees:false,
        buttonDisabled:true,
        firstName:this.props.user.firstName,
        lastName:this.props.user.lastName,
        verificationCode:'',
        dateOfBirth:this.props.user.dateOfBirth,
        address1:this.props.user.address1,
        city:this.props.user.city,
        state:this.props.user.state,
        postalCode:this.props.user.postalCode,
        businessName:this.props.user.businessName,
        ein:this.props.user.ein,
        ssn:"",
        controllerFirstName:"",
        controllerLastName:"",
        controllerTitle:"",
        controllerDateOfBirth:"",
        controllerAddress1:"UNSPECIFIED",
        controllerCity:"UNSPECIFIED",
        controllerStateProvinceRegion:"UNSPECIFIED",
        controllerPostalCode:"UNSPECIFIED",
        controllerSsn:"UNSPECIFIED",
        controller:"UNSPECIFIED",
        loading:false,
        awaitingEmailVerification:false
      })
    }
    }
  controllerSignUp(){
    try{
      if (this.state.businessType=="soleProprietorship"){return true}
      console.log("Evaluating Controller")
      if (name_reg.test(this.state.controllerFirstName)==false){
        alert("Please Enter a Valid First Name")
        return false
      }
      else if (name_reg.test(this.state.controllerLastName)==false){
        alert("Please Enter a Valid Last Name")
        return false
      }
      else if (this.state.controllerAddress1=="UNSPECIFIED"){
        alert("Please Enter a Street Address")
        return false
      }
      else if (this.state.controllerPostalCode==false){
        alert("Please Enter a Postal Code")
        return false
      }
      else if (dob_reg.test(this.state.controllerDateOfBirth)==false){
        alert("Please Enter a Valid Date of Birth YYYY-MM-DD")
        return false
      }
      else if (ssn_reg.test(this.state.controllerSsn)==false){
        alert("Please enter your full ssn, XXX-XX-XXXX")
        return false
      }
      else if (country_reg.test(this.state.controllerCountry)==false){
        if (this.state.controllerCountry!="US" && this.state.controllerpassportNumber=="UNSPECIFIED"){
          alert("If your identification is not from the US, please insert your passport number")
          return false
        }
        else if ((this.state.controllerpassportNumber=="UNSPECIFIED")||(this.state.controllerpassportNumber=="")){
          alert("Please insert your passport number")
          return false
        }
      }
      else{
        var jsonified_controller={}
        // If all the checks pass then we register information in JSON format and
        // continue with the Sign Up Process
        if (this.state.controllerCountry=="US"){
          jsonified_controller = {
            firstName:this.state.controllerFirstName,
            lastName:this.state.controllerLastName,
            title:this.state.controllerTitle,
            dateOfBirth: this.state.dateOfBirth,
            ssn: this.state.controllerSsn,
            address: {
              address1: this.state.controllerAddress1,
              city: this.state.controllerCity,
              stateProvinceRegion: this.state.controllerStateProvinceRegion,
              postalCode: this.state.controllerPostalCode,
              country: this.state.controllerCountry
            }
          }
        }
        else {
          jsonified_controller = {
            firstName:this.state.controllerFirstName,
            lastName:this.state.controllerLastName,
            title:this.state.controllerTitle,
            dateOfBirth: this.state.dateOfBirth,
            ssn: this.state.controllerSsn,
            address: {
              address1: this.state.controllerAddress1,
              city: this.state.controllerCity,
              stateProvinceRegion: this.state.controllerStateProvinceRegion,
              postalCode: this.state.controllerPostalCode,
              country: this.state.controllerCountry
            }
          }
        }
        this.setState({controllerJson:JSON.stringify(jsonified_controller)});
      }
      console.log("Finished Evaluating Controller")
      return true
    }
    catch (err){
      console.log(err)
    }
  }
  processBeneficialOwner(choice){
    if (choice==true){
        this.setState({beneficialOwnerNeeded:choice,firstName:'',lastName:'',dateOfBirth:'',address1:'',city:'',stateProvinceRegion:'',country:'',postalCode:'',ssn:''})
    }
    else{
      this.setState({beneficialConfirmationStep:true})
      // this.uploadBeneficialOwners()
    }
  }
  uploadBeneficialOwners(){
    const jsonified_beneficial_owners = JSON.stringify(this.state.beneficialOwners)
    const input = {id:this.props.user.id,beneficialOwnersJson:jsonified_beneficial_owners,verificationStatus:"uploadBeneficialOwners"}
    API.graphql(graphqlOperation(updateCustomer,{input})).then(res=>console.log(res)).catch(err=>console.log(err))
  }
  renderMerchantPrompts(){
    if (this.state.loading){
      return(
        <FormGroup>
            <div style={{alignItems:"center",justifyContent:"center",display:"flex"}}>
              <ClipLoader loading={this.state.loading} />
            </div>
        </FormGroup>
      )
    }
    else{
      if (this.props.user.verificationStatus==="retry"){
        if (this.props.user.businessType==="soleProprietorship"){
          return(
            [
              {field:"First Name",icon:"",type:"text",dwollaFormat:"firstName"},
              {field:"Last Name",icon:"",type:"text",dwollaFormat:"lastName"},
              {field:"Title i.e. CEO",type:"text",dwollaFormat:"title"},
              {field:"Date of Birth YYYY-MM-DD",icon:"",type:"text",dwollaFormat:"dateOfBirth"},
              {field:"Street Address",icon:"",type:"text",dwollaFormat:"address1"},
              {field:"City",icon:"",type:"text",dwollaFormat:"city"},
              {field:"State Abbreviation (i.e. NY)",icon:"",type:"text",dwollaFormat:"state"},
              {field:"Postal Code",icon:"",type:"text",dwollaFormat:"postalCode"},
              {field:"Business Name",icon:"",type:"text",dwollaFormat:"businessName"},
              {field:"Full SSN (XXX-XX-XXXX)",icon:"",type:"text",dwollaFormat:"ssn"},
            ].map((item,i)=>{
              return(
                <FormGroup key={i}>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className={item.icon} />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder={item.field} type={item.type} onChange={(e)=>this.setState({[item.dwollaFormat]:e.target.value})} value={this.state[item.dwollaFormat]}/>
                  </InputGroup>
                </FormGroup>
              )
            })
          )
        }
        else {
          return(
            <div>
            {[
              {field:"First Name",icon:"",type:"text",dwollaFormat:"firstName"},
              {field:"Last Name",icon:"",type:"text",dwollaFormat:"lastName"},
              {field:"Date of Birth YYYY-MM-DD",icon:"",type:"text",dwollaFormat:"dateOfBirth"},
              {field:"Street Address",icon:"",type:"text",dwollaFormat:"address1"},
              {field:"City",icon:"",type:"text",dwollaFormat:"city"},
              {field:"State Abbreviation (i.e. NY)",icon:"",type:"text",dwollaFormat:"state"},
              {field:"Postal Code",icon:"",type:"text",dwollaFormat:"postalCode"},
              {field:"Business Name",icon:"",type:"text",dwollaFormat:"businessName"},
              {field:"EIN (XX-XXXXXXX)",icon:"",type:"text",dwollaFormat:"ein"},
              {field:"Controller's First Name",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"controllerFirstName"},
              {field:"Controller's Last Name",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"controllerLastName"},
              {field:"Controller's Title i.e. CEO",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"controllerTitle"},
              {field:"Controller's Date of Birth YYYY-MM-DD",secureTextEntry:false,autoCapitalize:"none",dwollaFormat:"controllerDateOfBirth"},
              {field:"Controller's Street Address",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"controllerAddress1"},
              {field:"Controller's City",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"controllerCity"},
              {field:"Controller's State (abbreviation i.e. NY)",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"controllerStateProvinceRegion"},
              {field:"Controller's Postal Code",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"controllerPostalCode"},
              {field:"Controller's County (2-letter ISO code, i.e. US)",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"controllerCountry"},
              {field:"Controller's Full SSN (XXX-XX-XXXX)",secureTextEntry:true,autoCapitalize:"none",dwollaFormat:"controllerSsn"},
            ].map((item,i)=>{
              return(
                <FormGroup key={i}>
                <label
                  className="form-control-label"
                  htmlFor="input-username"
                >
                  {item.field}
                </label>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className={item.icon} />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder={item.field} type={item.type} onChange={(e)=>this.setState({[item.dwollaFormat]:e.target.value})} value={this.state[item.dwollaFormat]}/>
                  </InputGroup>
                </FormGroup>
              )
            })}
            <div style={{alignItems:"center",justifyContent:"center",display:"flex"}}>
            {(this.state.loading)?
                <ClipLoader loading={this.state.loading} /> : <Button  color="primary" type="button" onClick={()=>this.sendRetry()}>Submit</Button>}
            </div>
            </div>
          )
        }
      }
      else if (this.props.user.verificationStatus==="addBeneficialOwner"){
        if (this.state.beneficialConfirmationStep){
          return(
              <div style={{display:"flex",flexDirection:"column",alignItems:'center',justifyContent:'center'}}>
              <p style={{textAlign:"center"}}>
                I, {this.props.user.firstName} {this.props.user.lastName}, hereby certify, to the best of my knowledge, that the information provided is complete and correct.
              </p>
              {[
                {field:"Yes",type:true},
                {field:"No",type:false},
              ].map((item,i)=>{
                return(
                  (this.state.loading)?
                      <ClipLoader loading={this.state.loading} />
                        :
                      <Button className="mt-4"
                        color="secondary"
                        type="button"
                        key={"option"+i.toString()}
                        style={{width:150,alignItems:"center",display:"flex",justifyContent:"center"}}
                        onClick={()=>{(item.type)?this.uploadBeneficialOwners():alert("Please reload this page to reinsert beneficial owner information")}}
                      >
                        {item.field}
                      </Button>
                )
              })}</div>
          )
        }
        else if (this.state.additionalBeneficialOwnerPrompt){
          return(
            <div style={{display:"flex",flexDirection:"column",alignItems:'center',justifyContent:'center'}}>
            <p style={{textAlign:"center"}}>
              Does another individual own 25% or more of this business?
            </p>
            {[
              {field:"Yes",type:true},
              {field:"No",type:false},
            ].map((item,i)=>{
              return(
                <Button className="mt-4"
                  color="secondary"
                  type="button"
                  key={"option"+i.toString()}
                  style={{width:150,alignItems:"center",display:"flex",justifyContent:"center"}}
                  onClick={()=>{this.processBeneficialOwner(item.type);}}
                >
                  {item.field}
                </Button>
              )
            })}</div>
          )
        }
        else if (!this.state.beneficialOwnerNeeded){
          if (!this.state.insertBeneficialOwner){
            return(
              <div style={{display:"flex",flexDirection:"column",alignItems:'center',justifyContent:'center'}}>
              <p style={{textAlign:"center"}}>
                Does an individual own 25% or more of this business?
              </p>
              {[
                {field:"Yes",type:true},
                {field:"No",type:false},
              ].map((item,i)=>{
                return(
                  <Button className="mt-4"
                    color="secondary"
                    type="button"
                    key={"option"+i.toString()}
                    style={{width:150,alignItems:"center",display:"flex",justifyContent:"center"}}
                    onClick={()=>{this.processBeneficialOwner(item.type)}}
                  >
                    {item.field}
                  </Button>
                )
              })}</div>
          )
        }
      }
        else{
          return(
            <div><Form role="form">{[
              {field:"First Name",icon:"",type:"text",dwollaFormat:"firstName"},
              {field:"Last Name",icon:"",type:"text",dwollaFormat:"lastName"},
              {field:"Date of Birth YYYY-MM-DD",type:"text",dwollaFormat:"dateOfBirth"},
              {field:"Street Address",type:"text",dwollaFormat:"address1"},
              {field:"City",type:"text",dwollaFormat:"city"},
              {field:"State, Province, or Region",type:"text",dwollaFormat:"stateProvinceRegion"},
              {field:"Controller's County (2-letter ISO code, i.e. US)",type:"text",dwollaFormat:"country"},
              {field:"Postal Code",type:"text",dwollaFormat:"postalCode"},
              {field:"9 Digit SSN (XXX-XX-XXXX)",type:"text",dwollaFormat:"ssn"},
            ].map((item,i)=>{
              return(
                <FormGroup key={i}>
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    {item.field}
                  </label>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className={item.icon} />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder={item.field} type={item.type} onChange={(e)=>this.setState({[item.dwollaFormat]:e.target.value})} value={this.state[item.dwollaFormat]}/>
                  </InputGroup>
                </FormGroup>
              )
            })}
            <PhotoPicker
              preview
              headerText="Beneficial Owner Verification Photo"
              headerHint="Add a photo by clicking below"
              onPick={data =>this.setState({beneficialOwnerPhotoType:data.type})}
              onLoad={dataUrl => this.setState({beneficialOwnerPhoto:dataUrl})}
              />
            </Form><div style={{alignItems:"center",justifyContent:"center",display:"flex"}}><Button style={{width:150,alignItems:"center",display:"flex",justifyContent:"center"}} onClick={()=>this.addBeneficialOwner()} disabled={(this.state.beneficialOwnerPhoto===null)}>Submit</Button></div></div>
          )
        }
      }
    }
  }
  validateDataFormats(){
    if (name_reg.test(this.state.firstName)==false){
      alert("Please enter a valid first name")
      return false
    }
    else if (name_reg.test(this.state.lastName)==false){
      alert("Please enter a valid last name")
      return false
    }
    else if ((dob_reg.test(this.state.dateOfBirth)==false) || (this.state.dateOfBirth == "UNSPECIFIED")){
        alert("Please enter your date of birth in a valid format, YYYY-MM-DD")
        return false
      }
    else if (this.state.address1 == "UNSPECIFIED"){
      alert("Please enter a valid street address")
      return false
    }
    else if ((city_reg.test(this.state.city)==false) || (this.state.city == "UNSPECIFIED")){
      alert("Please enter a valid city name")
      return false
    }
    else if ((state_reg.test(this.state.state)==false) || (this.state.state == "UNSPECIFIED")){
      alert("Please enter a valid state abbreviation")
      return false
    }
    else if ((postalCode_reg.test(this.state.postalCode)==false) || (this.state.postalCode == "UNSPECIFIED")){
      alert("Please enter a valid postal code")
      return false
    }
    else if (this.props.user.businessType=="soleProprietorship"){
      if (ssn_reg.test(this.state.ssn)==false){
        alert("Please enter your full ssn, XXX-XX-XXXX")
        return false
      }
    }
    return true
  }
  async sendRetry(){
    try{
        this.setState({loading:true})
        console.log("Updating user")
        // console.log(this.state)
        const passedValidation = await this.validateDataFormats()
        const controllerValidation = await this.controllerSignUp()
        if (!passedValidation || !controllerValidation){
          this.setState({loading:false})
          return
        }
        const {firstName, lastName, dateOfBirth, address1, city, state, postalCode, businessName, ein, ssn} = this.state;
        let input = {
          id:this.props.user.id,
          firstName:firstName,
          lastName:lastName,
          dateOfBirth:dateOfBirth,
          address1:address1,
          city:city,
          state:state,
          postalCode:postalCode,
          businessName:businessName,
          ein:ein,
          ssn:ssn,
          verificationStatus:"sendRetry"
        };
        if (this.props.user.businessType=="soleProprietorship"){
          input.controllerJson="UNSPECIFIED"
        }
        else{
          input.controllerJson=this.state.controllerJson
        }
          const user = await API.graphql(graphqlOperation(updateCustomer,{input})).then(res=>console.log(res))
          this.setState({loading:false})
          document.documentElement.scrollTop = 0;
          document.scrollingElement.scrollTop = 0;
          this.refs.main.scrollTop = 0;
      }
    catch (err){
      console.log(err)
      this.setState({loading:false})
    }
  }
  async addBeneficialOwner(){
    console.log(this.state)
    console.log("Adding Beneficial Owner")
    const {firstName, lastName, dateOfBirth, address1, city, country, stateProvinceRegion, postalCode, ssn} = this.state;
    const beneficial_owner = {
      firstName: firstName,
      lastName: lastName,
      ssn:ssn,
      dateOfBirth:dateOfBirth,
      address:{
        address1:address1,
        city:city,
        stateProvinceRegion:stateProvinceRegion,
        country:country,
        postalCode:postalCode
      }
    }
    this.setState({beneficialOwners:[...this.state.beneficialOwners,beneficial_owner],additionalBeneficialOwnerPrompt:true})
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    await Storage.put(this.props.user.id+"_beneficial_owner"+this.state.beneficialOwners.length,convertDataURIToBinary(this.state.beneficialOwnerPhoto),{
      contentType:this.state.beneficialOwnerPhotoType
    }).then(res=>console.log(res)).catch(err=>console.log(err))
    console.log("Finished Adding Beneficial Owner")
  }
  async confirm(){
    try{
      this.setState({loading:true})
      await Auth.confirmSignUp(this.state.email,this.state.verificationCode).then(res=>Auth.signIn(this.state.email,this.state.password)).catch(err=>{alert(err.message);this.setState({loading:false})})
    }
    catch (err){
      if (err.code=="CodeMismatchException"){
        alert(err.message)
        this.setState({loading:false})
      }
      else{
        console.log(err)
        this.setState({loading:false})
      }
    }
  }
  async uploadImages(){
    this.setState({loading:true})
    console.log("Uploading Images")
    await Storage.put(this.props.user.id+"_businessVerificationImage",convertDataURIToBinary(this.state.businessVerificationPhoto),{
      contentType:this.state.businessVerificationPhotoType
    }).then(res=>console.log(res)).catch(err=>console.log(err))
    await Storage.put(this.props.user.id+"_controllerVerificationImage",convertDataURIToBinary(this.state.controllerVerificationPhoto),{
      contentType:this.state.controllerVerificationPhotoType
    }
  ).then(res=>console.log(res)).catch(err=>console.log(err))
    console.log("Finished Uploading Images")
    console.log("Updating User Status to Beneficial Owners")
    var input = {}
    if (this.props.user.businessType=="soleProprietorship"){
      input = {id:this.props.user.id,verificationStatus:"unverified"}
    }
    else{
      input = {id:this.props.user.id,verificationStatus:"uploadControllerDocument"}
    }
    await API.graphql(graphqlOperation(updateCustomer,{input}))
    console.log("Finished updating User Status")
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    this.setState({loading:false})
  }
  renderCardBlock(){
    if (["addBeneficialOwner","retry"].includes(this.props.user.verificationStatus)){
      return(
        <div style={{}}>
          {this.props.user.verificationStatus==="retry"&&<h4>We need you to resubmit your information. Double check to make sure everything is correct.</h4>}
          {this.renderMerchantPrompts()}
        </div>
      )
    }
    else if (this.props.user.verificationStatus==="document"){
      return(
        <div style={{}}>
          <h3>Additional information is required to verify your account</h3>
          <small style={{marginTop:20,textAlign:'center'}}>
            Please select an image to verify the controller's identity. If you are verifying a sole proprietorship, then upload your own verification document.
          </small>
          <FormGroup>
            <Input type="select" name="select" id="exampleSelect" onChange={(data)=>this.setState({controllerImageType:data.target.value})}>
              <option value="passport">Passport</option>
              <option value="license">State Issued Driver's License</option>
              <option value="idCard">Other Government-Issued Photo ID Card</option>
            </Input>
          </FormGroup>
          <PhotoPicker
            preview
            headerText="Controller Verification Photo"
            headerHint="Add a photo by clicking below"
            onPick={data =>this.setState({controllerVerificationPhotoType:data.type})}
            onLoad={dataUrl => this.setState({controllerVerificationPhoto:dataUrl})}
            />
          <small style={{textAlign:'center'}}>
            <br/> Partnerships, corporations, and LLC's are required to upload an EIN Letter.
            <br/>Sole proprietorships are required to upload a valid business document. These include but are not limited to: EIN Documentation | Fictitious Business Name Statement | Business License | Certificate of Assumed Name | Sales/Use Tax License | Registration of Trade Name.
          </small>
          <PhotoPicker
            preview
            headerText="Business Verification Photo"
            headerHint="Add a photo by clicking below"
            onPick={data =>this.setState({businessVerificationPhotoType:data.type})}
            onLoad={dataUrl => this.setState({businessVerificationPhoto:dataUrl})}
            />
            <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
            {this.state.loading?<ClipLoader loading={this.state.loading} />:<Button
                className="mt-4"
                color="primary"
                type="button"
                disabled={this.state.controllerVerificationPhoto==null||this.state.businessVerificationPhoto==null}
                onClick={()=>this.uploadImages()}
              >
                Submit
            </Button>}
            </div>
        </div>
      )
    }
    else if (this.props.user.verificationStatus!="retry"){
      return(
        <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:'column'}}>
          <h3 style={{textAlign:"center"}}>
            Your account is being verified
          </h3>
          <small style={{marginTop:20,textAlign:'center'}}>
          For more information please send a message to <a href="mailto:support@kikt.io">support@kikt.io</a>.
          </small>
        </div>
      )
    }
    else{
      return(
        <div>
        {this.state.buttonDisabled&&!this.state.awaitingEmailVerification&&
          <div className="text-center text-muted mb-4">
            <small>There was an error verifying your account please ensure that your information is correct.</small>
          </div>
        }
          <Form role="form">
          {this.renderMerchantPrompts()}
            {/*<div className="text-muted font-italic">
              <small>
                password strength:{" "}
                <span className="text-success font-weight-700">
                  strong
                </span>
              </small>
            </div>*/}
            <div className="text-center">
            <Button
                className="mt-4"
                color="primary"
                type="button"
                disabled={this.state.ssn==""&&this.state.controllerSsn==""}
                onClick={()=>this.updateAccount()}
              >
                Update account
              </Button>
            </div>
          </Form>
        </div>
      )
    }
  }
  render() {
    return (
      <>
        <DemoNavbar status="verification"/>
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 shape-default">
            </div>
            <Container className="pt-lg-md" style={{marginBottom:800}}>
              <Row className="justify-content-center">
                <Col lg="5">
                  <Card className="bg-secondary shadow border-0" style={{width:550}}>
                    <CardBody className="px-lg-5 py-lg-5">
                    {this.renderCardBlock()}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Verification;
