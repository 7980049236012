import React from "react";
// reactstrap components
import { Button, FormGroup, Input, Modal } from "reactstrap";
import {Analytics} from 'aws-amplify';

function registerEmail(email){
  console.log(`Registering email ${email}`);
  if (email!=""){
    Analytics.record({name:"emailRegistration", attributes: {email: email} }).then(res=>console.log(res)).catch(err=>console.log(err));
  }
}

function Example(props) {
  const [loginModal, setLoginModal] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const {color, visible, setState} = props;
  return(
    <>
      <Modal
        isOpen={visible}
        toggle={() => setState({visible:false})}
        modalClassName="modal-register"
      >
        <div style={{height:50,display:"flex",width:"100%",justifyContent:"center",alignItems:"center"}}>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setState({visible:false})}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <h3 className="modal-title text-center">Demo</h3>
        </div>
        <div className="modal-body">
        <p className="description mt-3">
          Thank you for your interest! We are working hard on launching real time payments for our app.
          Enter your email below to get notified when the latest version of our app is complete.
        </p>
          <FormGroup>
            <label>Email</label>
            <Input placeholder="Email" type="email" onChange={(e)=>setEmail(e.target.value)}/>
          </FormGroup>

          <Button block className="btn-round" color="default" type="submit" onClick={()=>{registerEmail(email);setSubmitted(true)}} disabled={submitted}>
            Register
          </Button>
        </div>
        <div className="modal-footer no-border-footer" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
          <span className="text-muted text-center">
            Contact us {" "}
            <a href="mailto:contact@kikt.io">
              here
            </a>

          </span>
        </div>
      </Modal>
    </>
  );
}

export default Example;
