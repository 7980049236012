import React from "react";
import VaxContainer from "./VaxContainer";
import VaxHeader from "./VaxHeader";
import errorImage from "../../assets/img/vaxpass/errorImage.png";
import { BlueButton } from "./Buttons";

class ErrorVerificationScreen extends React.Component {
  render() {
    return (
      <VaxContainer>
        <VaxHeader canGoBack={false} />
        <div className="vax-error-screen">
          <div className="d-flex">
            <img
              src={errorImage}
              alt=""
              className="mb-3 mx-auto intro-screen-img"
            ></img>
          </div>
          <div className="my-4">
            <h6 className="px-5">
              There was an error verifying your <b>COVID-19</b> record.
            </h6>
            <div className="my-5">
              <BlueButton
                onClick={() => this.props.switchScreen("ImageVerification")}
              >
                <p className="mb-0">TRY IMAGE VERIFICATION</p>
              </BlueButton>
            </div>
          </div>
        </div>
      </VaxContainer>
    );
  }
}

export default ErrorVerificationScreen;
