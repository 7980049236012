import PropTypes from "prop-types";
import "./stylesheets/Inputs.scss";
import React from "react";
import MaskedInput from 'react-maskedinput'

function CustomInput(props) {
    return <MaskedInput
      mask="11/11/1111"
      size="11"
      {...props}
      formatCharacters={{
        'W': {
          validate(char) { return /\w/.test(char ) },
          transform(char) { return char.toUpperCase() }
        }
      }
    }/>
}

export function DobInput(props) {
  const { onChange, placeholder = "", icon = "", required, type ="text", value } = props;
  return (
    <div className="inputwrapper">
      <div className="text-input-wrapper">
        <div className="text-icon-left">
          <i className={icon} style={{ color: "#7fbde0" }} />
        </div>
        <CustomInput
          placeholder={placeholder}
          className="text-input"
          onChange={onChange}
          value={value}
          required={required}
          type={type}
        />
      </div>
    </div>
  );
}

export function TextInput(props) {
  const { onChange, placeholder = "", icon = "", required, type ="text", value } = props;
  return (
    <div className="inputwrapper">
      <div className="text-input-wrapper">
        <div className="text-icon-left">
          <i className={icon} style={{ color: "#7fbde0" }} />
        </div>
        <input
          placeholder={placeholder}
          className="text-input"
          onChange={onChange}
          value={value}
          required={required}
          type={type}
        />
      </div>
    </div>
  );
}

TextInput.propTypes = {
  icon: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};
