/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

export default class AppleAppSiteAssociation extends React.Component {
  render() {
    const data = {
      "applinks": {
        "apps": [],
        "details": [
          {
            "appID": "2M4WQMUC74.com.kikt.kiktapp",
            "paths": [
              "*"
            ]
          }
        ]
      },
      "webCredentials": {
        "apps": [
          "2M4WQMUC74.com.kikt.kiktapp"
        ]
      }
    }
    return (
        <div>
          <pre>
            {
              JSON.stringify(data, null, 2)
            }
          </pre>
        </div>
    );
  }
}
