/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// Email library for form submission
import emailjs from 'emailjs-com';

// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DemoNavbar from "../../components/Navbars/DemoNavbar.jsx";
import SimpleFooter from "../../components/Footers/SimpleFooter.jsx";
import EmailModal from "../../components/Modals/EmailModal.js";
import {Analytics} from 'aws-amplify';

// index page sections
import Download from "../IndexSections/Download.jsx";
let email_reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/
Analytics.autoTrack('event', {
    // REQUIRED, turn on/off the auto tracking
    enable: true,
    // OPTIONAL, events you want to track, by default is 'click'
    events: ['click'],
    // OPTIONAL, the prefix of the selectors, by default is 'data-amplify-analytics-'
    // in order to avoid collision with the user agent, according to https://www.w3schools.com/tags/att_global_data.asp
    // always put 'data' as the first prefix
    selectorPrefix: 'data-amplify-analytics-',
    // OPTIONAL, the service provider, by default is the Amazon Pinpoint
    provider: 'AWSPinpoint',
    // OPTIONAL, the default attributes of the event, you can either pass an object or a function
    // which allows you to define dynamic attributes
    attributes: {
        attr: 'attr'
    }
    // when using function
    // attributes: () => {
    //    const attr = somewhere();
    //    return {
    //        myAttr: attr
    //    }
    // }
});
class Landing extends React.Component {
  state = {
    emailAddress:"",
    visible: false,
    notes:""
  };
  componentDidMount() {
    window.addEventListener("click", this.handleMouseClick.bind(this));
  }
  handleMouseClick(event) {
     if (!this.state.visible){
       event.preventDefault();
       this.setState({visible:true})
     }
  }
  sendEmail(){
    this.setState({emailAddress:"",notes:""})
    emailjs.init("user_WmlZXJCgqV6wqD9A6UzyZ")
    emailjs.send('default_service','template_sby1CvH5',{email:this.state.emailAddress,notes:this.state.notes}).then(res=>console.log(res)).catch(err=>console.log(err))
    alert("Email successfully sent")
  }
  render() {
    return (
      <>
        <DemoNavbar landing={true}/>
        <a name="home"></a>
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1 shape-default">
              </div>
              <Container className="py-lg-md d-flex" style={{marginTop:30}}>
                <div className="col px-0">
                  <Row>
                    <Col lg="6">
                      <h1 className="display-3 text-white">
                        Just "Kick It" to Me
                        <EmailModal color="info" visible={this.state.visible} setState={(props)=>this.setState(props)}/>
                        <span>The Best Way to Pay</span>
                      </h1>
                      <p className="lead text-white">
                        Kikt (pronounced "Kick It") is the best way to pay. Send and receive real time payments. No fees attached. Up to $100,000.00 per transaction. <a href="/register" style={{color:"white",fontWeight:"bold"}} data-amplify-analytics-on='click' data-amplify-analytics-name='click' data-amplify-analytics-attrs='button:signup1'>Click here to sign up.</a>
                      </p>
                      <div className="btn-wrapper">
                        {/*<Button
                          className="btn-icon mb-3 mb-sm-0"
                          color="info"
                          href="https://demos.creative-tim.com/argon-design-system-react/#/documentation/alerts?ref=adsr-landing-page"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fa fa-user-plus" />
                          </span>
                          <span className="btn-inner--text">Register</span>
                        </Button>
                        <Button
                          className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                          color="default"
                          href="https://www.creative-tim.com/product/argon-design-system-react?ref=adsr-landing-page"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fa fa-sign-in" />
                          </span>
                          <span className="btn-inner--text">
                            Sign In
                          </span>
                        </Button>*/}
                      </div>
                    </Col>
                    <Col lg="6">
                      <Button
                      data-amplify-analytics-on='click' data-amplify-analytics-name='click' data-amplify-analytics-attrs='button:playstore'
                       href="https://play.google.com/store/apps/details?id=com.kikt.kiktapp"
                       style={{background:"transparent",borderWidth:0,margin:10}}>
                        <img
                          alt="..."
                          className="img-fluid floating"
                          style={{width:258,height:100}}
                          src={require("../../assets/img/icons/common/google-play-badge.svg")}
                        />
                      </Button>
                      <Button
                        href="https://apps.apple.com/us/app/id1490925873"
                        data-amplify-analytics-on='click' data-amplify-analytics-name='click' data-amplify-analytics-attrs='button:appstore'
                        style={{background:"transparent",borderWidth:0,margin:10}}>
                        <img
                          alt="..."
                          className="img-fluid floating"
                          style={{width:258,height:100}}
                          src={require("../../assets/img/icons/common/app-store-badge.svg")}
                        />
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="fa fa-th" />
                          </div>
                          <h6 className="text-primary text-uppercase">
                            Create An Account
                          </h6>
                          <p className="description mt-3">
                            Verify your identity to start sending and receiving real time payments.
                          </p>
                          <div>
                            <Badge color="primary" pill className="mr-1">
                              fast
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              easy
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              secure
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="primary"
                            href="https://public-kikt-files.s3.amazonaws.com/HOW-TO-PERSONAL-ACCOUNT.pdf"
                            data-amplify-analytics-on='click' data-amplify-analytics-name='click' data-amplify-analytics-attrs='button:personal'
                          >
                            Personal
                          </Button>
                          <Button
                            className="mt-4"
                            color="primary"
                            href="https://public-kikt-files.s3.amazonaws.com/HOW-TO-BUSINESS-ACCOUNT.pdf"
                            data-amplify-analytics-on='click' data-amplify-analytics-name='click' data-amplify-analytics-attrs='button:business'
                          >
                            Business
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                            <i className="fa fa-trophy" />
                          </div>
                          <h6 className="text-success text-uppercase">
                            Accumulate Points
                          </h6>
                          <p className="description mt-3">
                            Accumulate points and redeem rewards
                          </p>
                          <div>
                            <Badge color="success" pill className="mr-1">
                              contactless
                            </Badge>
                            <Badge color="success" pill className="mr-1">
                              loyalty
                            </Badge>
                            <Badge color="success" pill className="mr-1">
                              success
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="success"
                            href="https://public-kikt-files.s3.amazonaws.com/HOW-TO-MENUS.pdf"
                            data-amplify-analytics-on='click' data-amplify-analytics-name='click' data-amplify-analytics-attrs='button:rewards'
                          >
                            Redeeming Rewards
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                            <i className="fa fa-desktop" />
                          </div>
                          <h6 className="text-warning text-uppercase">
                            Point of Sale (POS)
                          </h6>
                          <p className="description mt-3">
                            Use our ground-breaking POS (businesses) with NO fees attached.
                          </p>
                          <div>
                            <Badge color="warning" pill className="mr-1">
                              print
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              scan
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              share
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="warning"
                            href="https://drive.google.com/drive/folders/1wR0OWqaw0vM5M9jkyjakovoLDOlZ5A2I?usp=sharing"
                            data-amplify-analytics-on='click' data-amplify-analytics-name='click' data-amplify-analytics-attrs='button:pos'
                          >
                            Business Features guide
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <a name="about"></a>
          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("../../assets/img/theme/undraw_winners_ao2o.svg")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="fa fa-trophy" />
                    </div>
                    <h3>Rewards</h3>
                    <p>
                      Kikt gives customers access to an innovative rewards system, developed by individual businesses or organizations. Businesses can create customized rewards for their consumers based on loyalty and/or amount spent.
                    </p>
                    {/*<ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="fa fa-fast" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Fast transactions
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="fa fa-university" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">Works with all major banks</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="fa fa-phone" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Great support
                            </h6>
                          </div>
                        </div>
                      </li>
                    </ul>*/}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section bg-secondary">
            <Container>
              <Row className="row-grid align-items-center">
              <Col md="6">
                <img
                  alt="..."
                  className="img-fluid floating"
                  src={require("../../assets/img/theme/undraw_discount_d4bd.svg")}
                />
              </Col>
                <Col md="6">
                  <div className="pl-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-5">
                      <i className="fa fa-arrow-down" />
                    </div>
                    <h3>Pricing</h3>
                    <p className="lead">
                      No processing fee. No setup fee. No chargeback fee. No disputes. No contracts. Kikt makes all the headaches and processing fees a thing of the past.
                    </p>
                    {/*<a
                      className="font-weight-bold text-warning mt-5"
                      href="https://fiscal.treasury.gov/ach/"
                    >
                      Learn more about ACH here
                    </a>*/}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          {/*<section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("../../assets/img/theme/undraw_fast_loading_0lbh.svg")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="fa fa-university" />
                    </div>
                    <h3>Pricing</h3>
                    <p>
                      No contracts or setup fees. As a merchant, your only cost is our small processing fee of 1.5%+15 cents per transaction.                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="fa fa-fast-forward" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Free menu setup + promotional content
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="fa fa-university" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">Works with all major banks</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="fa fa-phone" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Great support
                            </h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>*/}
          <section className="section pb-0 bg-gradient-warning">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-lg-2 ml-lg-auto" md="6">
                  <div className="position-relative pl-md-5">
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require("../../assets/img/ill/ill-2.svg")}
                    />
                  </div>
                </Col>
                <Col className="order-lg-1" lg="6">
                  <div className="d-flex px-3">
                    <div>
                      <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                        <i className="fa fa-cutlery" />
                      </div>
                    </div>
                    <div className="pl-4">
                      <h4 className="display-3 text-white">Virtual Menus</h4>
                      <p className="text-white">
                        Kikt is assisting businesses with their reopening efforts with virtual menus. Businesses upload a pdf of their menu to the Kikt app and customers are redirected to view the menu upon scanning the merchant's respective QR code. The same QR code can be scanned by customers to make payments or contactless orders.
                      </p>
                    </div>
                  </div>
                  <Card className="shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="fa fa-stethoscope" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-success">
                            Monitor your Business
                          </h5>
                          <p>
                            Monitor transactions and the amount of customers in real time
                            through our mobile application or online dashboard.
                          </p>
                          <a
                            className="text-success"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >

                          </a>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                            <i className="fa fa-qrcode" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-warning">
                            Fast QR Code Payments
                          </h5>
                          <p>
                            Enjoy quick and efficient contactless payments. Scan a QR code or have your QR code scanned to start a transaction. Pay merchants and friends.
                          </p>
                          <a
                            className="text-warning"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                          </a>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg">

          </section>
          <section className="section section-lg pt-0">
            <Container>
              <Card className="bg-gradient-warning shadow-lg border-0">
                <div className="p-5">
                  <Row className="align-items-center">
                    <Col lg="8">
                      <h3 className="text-white">
                        Accepting payments has never been easier
                      </h3>
                      <p className="lead text-white mt-3">
                        Kikt has created an intuitive and innovative mobile payments infrastructure, and is now helping brick-and-mortar businesses save on processing costs through an intuitive and easy to use Point of Sale system.
                      </p>
                    </Col>
                    <Col className="ml-lg-auto" lg="3">
                      <Button
                        block
                        className="btn-white"
                        color="default"
                        href="https://public-kikt-files.s3.amazonaws.com/HOW-TOs.pdf"
                        size="lg"
                        data-amplify-analytics-on='click' data-amplify-analytics-name='click' data-amplify-analytics-attrs='button:getstarted'
                      >
                        Get started
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Container>
          </section>
          <section className="section section-lg bg-gradient-default">
            <Container className="pt-lg pb-300">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-white">Stop Paying Transaction Fees</h2>
                  <p className="lead text-white">
                    The current payment industry is antiquated, relying on technology
                    that was created nearly seven decades ago. Kikt brings the payment
                    industry into the future by taking advantage of recent innovations like the Real-Time-Payments network.
                  </p>
                </Col>
              </Row>
              <Row className="row-grid mt-5">
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="fa fa-cloud" />
                  </div>
                  <h5 className="text-white mt-3">Cloud Technologies</h5>
                  <p className="text-white mt-3">
                    Through our integration with cloud technologies we are able to
                    process payments within seconds.
                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-ruler-pencil text-primary" />
                  </div>
                  <h5 className="text-white mt-3">
                    Rapid Improvement
                  </h5>
                  <p className="text-white mt-3">
                    Kikt will continue to innovate, making our payment system a continously improving
                    product.
                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="fa fa-phone" />
                  </div>
                  <h5 className="text-white mt-3">
                    Constant Availability
                  </h5>
                  <p className="text-white mt-3">
                    Have suggestions or concerns? The team at Kikt always responds.
                  </p>
                </Col>
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <a name="contact"></a>
          <section className="section section-lg pt-lg-0 section-contact-us">
            <Container>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Want to get in touch?</h4>
                      <p className="mt-0">
                        Fill out the form below and send us a message.
                      </p>

                      <FormGroup
                        className={classnames({
                          focused: this.state.emailFocused
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email address"
                            type="email"
                            name="email"
                            value={this.state.email}
                            onFocus={e => this.setState({ emailFocused: true })}
                            onBlur={e => this.setState({ emailFocused: false })}
                            onChange={e=>this.setState({emailAddress:e.target.value})}
                            data-amplify-analytics-on='click' data-amplify-analytics-name='click' data-amplify-analytics-attrs='input:emailSendMessageEnd'
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-4">
                        <Input
                          className="form-control-alternative"
                          cols="80"
                          name="name"
                          placeholder="Type a message..."
                          rows="4"
                          type="textarea"
                          onChange={e=>this.setState({notes:e.target.value})}
                        />
                      </FormGroup>
                      <div>
                        <Button
                          block
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="button"
                          onClick={()=>this.sendEmail()}
                          disabled={(this.state.emailAddress=="")||(this.state.notes=="")||(email_reg.test(this.state.email)===true)}
                        >
                          Send Message
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Landing;
