import React from "react";
import "./stylesheets/PDFPass.scss";
import { useBarcode } from 'react-barcodes';
import QRCode from "qrcode.react";

const url = "https://ksc8uhwyq2.execute-api.us-east-1.amazonaws.com/prod";

async function getRecord(id) {
  var response = await fetch(url + "/get-record", {
    method: "POST",
    cache: "no-cache",
    mode: "cors",
    body: JSON.stringify({"id": id}),
  });
  response = await response.json();
  return response
}

function Name(props) {
  const { name, place, className } = props;

  return (
    <div className={`group-30 ${className || ""}`}>
      <div className="overlap-group-2">
        <div className="name valign-text-middle avenir-roman-normal-black-12px">{name}</div>
        <div className="place valign-text-middle avenir-roman-normal-black-6px">{place}</div>
      </div>
    </div>
  );
}
function DOB(props) {
  const { name, place, className } = props;

  return (
    <div className={`group-303 ${className || ""}`}>
      <div className="overlap-group-2">
        <div className="name valign-text-middle avenir-roman-normal-black-12px">{name}</div>
        <div className="place valign-text-middle avenir-roman-normal-black-6px">{place}</div>
      </div>
    </div>
  );
}

function Barcode(props){
  const {value} = props;
  const { inputRef } = useBarcode({
      value: value,
      options: {
      }
    });
    return <img ref={inputRef} className="barcode-image"/>;
}

const nameData = {
    name: "Joe Biden",
    title: "NAME",
};


const dobData = {
    date: "11.20.1942",
    title: "DATE OF BIRTH",
};

const A42Data = {
    vaxPass: "https://anima-uploads.s3.amazonaws.com/projects/60455b4058f8ed30e1111808/releases/60457a42d34c876548db849c/img/vax-pass@2x.svg",
    overlapGroup2: "https://anima-uploads.s3.amazonaws.com/projects/60455b4058f8ed30e1111808/releases/60457981d34c876548db849b/img/ellipse-1@2x.svg",
    vector: "https://anima-uploads.s3.amazonaws.com/projects/60455b4058f8ed30e1111808/releases/60457a42d34c876548db849c/img/vector@2x.svg",
    text1: "Thanks for choosing us.",
    text2: "Here's your Vax Pass for entry into Vax Pass zones. Your pass has been approved and confirmed. For any questions please send a message to contact@vax-pass.org",
    vector10: "https://anima-uploads.s3.amazonaws.com/projects/60455b4058f8ed30e1111808/releases/60457981d34c876548db849b/img/vector-10@2x.svg",
    rectangle54: "https://anima-uploads.s3.amazonaws.com/projects/60455b4058f8ed30e1111808/releases/60457981d34c876548db849b/img/rectangle-54@2x.svg",
    image7: "https://anima-uploads.s3.amazonaws.com/projects/60455b4058f8ed30e1111808/releases/60457981d34c876548db849b/img/image-7@2x.svg",
    phone: "02.21.2021",
    validityExpression: "DATE OF TEST",
    vaxPass2: "https://anima-uploads.s3.amazonaws.com/projects/60455b4058f8ed30e1111808/releases/60457a42d34c876548db849c/img/vax-pass-1@2x.svg",
    ellipse1: "https://anima-uploads.s3.amazonaws.com/projects/60455b4058f8ed30e1111808/releases/60457981d34c876548db849b/img/ellipse-1-1@2x.svg",
    vector2: "https://anima-uploads.s3.amazonaws.com/projects/60455b4058f8ed30e1111808/releases/60457a42d34c876548db849c/img/vector-1@2x.svg",
    vector11: "https://anima-uploads.s3.amazonaws.com/projects/60455b4058f8ed30e1111808/releases/60457981d34c876548db849b/img/vector-11-1@2x.svg",
    vector12: "https://anima-uploads.s3.amazonaws.com/projects/60455b4058f8ed30e1111808/releases/60457981d34c876548db849b/img/vector-11-1@2x.svg",
    vector112: "https://anima-uploads.s3.amazonaws.com/projects/60455b4058f8ed30e1111808/releases/60457981d34c876548db849b/img/vector-10@2x.svg",
    text3: "Visit www.vax-pass.org to see how Vax Pass works.",
    text4: "To add this pass to a wallet, open the email we sent you on an Apple or Android device.",
    nameProps: nameData,
    dobProps: dobData,
};

var {
  vaxPass,
  overlapGroup2,
  vector,
  text1,
  text2,
  vector10,
  rectangle54,
  image7,
  phone,
  validityExpression,
  vaxPass2,
  ellipse1,
  vector2,
  vector11,
  vector12,
  vector112,
  text3,
  text4,
  nameProps,
  dobProps,
} = A42Data;

var recordId, inputRef

export default class A42 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      dob: "",
      selectedImage:null,
      loading:true
    };
  }

  async componentDidMount(){
    var response = await getRecord(this.props.match.params.id)
    console.log(response)
    if (!response.verified){
      console.log("Invalid ID")
    }
    else{
      nameProps.name = response.name
      dobProps.date = response.dob
      recordId = response.id
      validityExpression = response.valid_date_expression
      this.setState({loading:false})
    }
  }

  render(){
    if (this.state.loading){
      return(
        <div />
      )
    }
    return (
      <div style={{alignItems:"center",justifyContent:"center",display:"flex"}}>
      <div className="a4-2">
        <div className="group-6">
          <div className="overlap-group-1">
            <img className="vax-pass" src={vaxPass} />
            <div className="group-5">
              <div className="overlap-group2" style={{ backgroundImage: `url(${overlapGroup2})` }}>
                <img className="vector" src={vector} />
              </div>
            </div>
          </div>
        </div>
        <div className="overlap-group">
          <h1 className="text-1 valign-text-middle montserrat-bold-gravel-26px">{text1}</h1>
          <p className="text-2 montserrat-normal-gravel-14px">{text2}</p>
        </div>
        <img className="vector-10" src={vector10} />
        <div className="group-31">
          <div className="overlap-group3">
            <div className="rectangle-53"></div>
            <img className="rectangle-54" src={rectangle54} />
            <QRCode className="image-7" value={recordId} includeMargin/>
            <div className="phone valign-text-middle avenir-roman-normal-white-12px">{validityExpression.slice(1,)}</div>
            <Name name={nameProps.name} place={nameProps.title} />
            <DOB name={dobProps.date} place={dobProps.title}/>
            <div className="date-of-test valign-text-middle avenir-roman-normal-white-6px">{(validityExpression[0]==">")?"VALID AFTER":"VALID UNTIL"}</div>
            <div className="group-27">
              <div className="overlap-group-1">
                <img className="vax-pass-1" src={vaxPass2} />
                <div className="group-5-1">
                  <div className="overlap-group1">
                    <img className="ellipse-1" src={ellipse1} />
                    <img className="vector-1" src={vector2} />
                  </div>
                </div>
              </div>
            </div>
            <img className="vector-11" src={vector11} />
            <img className="vector-12" src={vector12} />
          </div>
        </div>
        <img className="vector-11-1" src={vector112} />
        <Barcode value={recordId} />
        <p className="text-3 montserrat-normal-gravel-12px">{text3}</p>
        <p className="text-4 montserrat-normal-gravel-12px">{text4}</p>
      </div>
      </div>
    );
  }
}
