/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
  ) {
    createCustomer(input: $input) {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        items {
          id
          mask
          name
          subtype
          type
          institution
          public_token
          stripeAccountId
          balance
          createdAt
        }
        nextToken
      }
      targetAccounts {
        items {
          id
          mask
          name
          subtype
          type
          institution
          public_token
          stripeAccountId
          balance
          description
          createdAt
        }
        nextToken
      }
      merchantTransactions {
        items {
          id
          subscriptionId
          customerSubscriptionId
          createdAt
          merchantName
          customerName
          tipAmount
          taxAmount
          amount
          rewardInformation
          paymentStatus
        }
        nextToken
      }
      customerTransactions {
        items {
          id
          subscriptionId
          customerSubscriptionId
          createdAt
          merchantName
          customerName
          tipAmount
          taxAmount
          amount
          rewardInformation
          paymentStatus
        }
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        items {
          id
          points
          updatedAt
        }
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        customerLol {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        merchantLol {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
  ) {
    updateCustomer(input: $input) {
      id
      covidInfo
      weeklyCustomerAmount
      weeklyEarnings
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        items {
          id
          mask
          name
          subtype
          type
          institution
          public_token
          stripeAccountId
          balance
          createdAt
        }
        nextToken
      }
      targetAccounts {
        items {
          id
          mask
          name
          subtype
          type
          institution
          public_token
          stripeAccountId
          balance
          description
          createdAt
        }
        nextToken
      }
      merchantTransactions {
        items {
          id
          subscriptionId
          customerSubscriptionId
          createdAt
          merchantName
          customerName
          tipAmount
          taxAmount
          amount
          rewardInformation
          paymentStatus
        }
        nextToken
      }
      customerTransactions {
        items {
          id
          subscriptionId
          customerSubscriptionId
          createdAt
          merchantName
          customerName
          tipAmount
          taxAmount
          amount
          rewardInformation
          paymentStatus
        }
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        items {
          id
          points
          updatedAt
        }
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        customerLol {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        merchantLol {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
  ) {
    deleteCustomer(input: $input) {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        items {
          id
          mask
          name
          subtype
          type
          institution
          public_token
          stripeAccountId
          balance
          createdAt
        }
        nextToken
      }
      targetAccounts {
        items {
          id
          mask
          name
          subtype
          type
          institution
          public_token
          stripeAccountId
          balance
          description
          createdAt
        }
        nextToken
      }
      merchantTransactions {
        items {
          id
          subscriptionId
          customerSubscriptionId
          createdAt
          merchantName
          customerName
          tipAmount
          taxAmount
          amount
          rewardInformation
          paymentStatus
        }
        nextToken
      }
      customerTransactions {
        items {
          id
          subscriptionId
          customerSubscriptionId
          createdAt
          merchantName
          customerName
          tipAmount
          taxAmount
          amount
          rewardInformation
          paymentStatus
        }
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        items {
          id
          points
          updatedAt
        }
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        customerLol {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        merchantLol {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
    }
  }
`;
export const createSourceAccount = /* GraphQL */ `
  mutation CreateSourceAccount(
    $input: CreateSourceAccountInput!
  ) {
    createSourceAccount(input: $input) {
      id
      mask
      name
      subtype
      type
      institution
      public_token
      stripeAccountId
      balance
      customer {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
      }
      receivedTransactions {
        items {
          id
          subscriptionId
          customerSubscriptionId
          createdAt
          merchantName
          customerName
          tipAmount
          taxAmount
          amount
          rewardInformation
          paymentStatus
        }
        nextToken
      }
      transactions {
        items {
          id
          subscriptionId
          customerSubscriptionId
          createdAt
          merchantName
          customerName
          tipAmount
          taxAmount
          amount
          rewardInformation
          paymentStatus
        }
        nextToken
      }
      createdAt
    }
  }
`;
export const updateSourceAccount = /* GraphQL */ `
  mutation UpdateSourceAccount(
    $input: UpdateSourceAccountInput!
  ) {
    updateSourceAccount(input: $input) {
      id
      mask
      name
      subtype
      type
      institution
      public_token
      stripeAccountId
      balance
      customer {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
      }
      receivedTransactions {
        items {
          id
          subscriptionId
          customerSubscriptionId
          createdAt
          merchantName
          customerName
          tipAmount
          taxAmount
          amount
          rewardInformation
          paymentStatus
        }
        nextToken
      }
      transactions {
        items {
          id
          subscriptionId
          customerSubscriptionId
          createdAt
          merchantName
          customerName
          tipAmount
          taxAmount
          amount
          rewardInformation
          paymentStatus
        }
        nextToken
      }
      createdAt
    }
  }
`;
export const deleteSourceAccount = /* GraphQL */ `
  mutation DeleteSourceAccount(
    $input: DeleteSourceAccountInput!
  ) {
    deleteSourceAccount(input: $input) {
      id
      mask
      name
      subtype
      type
      institution
      public_token
      stripeAccountId
      balance
      customer {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
      }
      receivedTransactions {
        items {
          id
          subscriptionId
          customerSubscriptionId
          createdAt
          merchantName
          customerName
          tipAmount
          taxAmount
          amount
          rewardInformation
          paymentStatus
        }
        nextToken
      }
      transactions {
        items {
          id
          subscriptionId
          customerSubscriptionId
          createdAt
          merchantName
          customerName
          tipAmount
          taxAmount
          amount
          rewardInformation
          paymentStatus
        }
        nextToken
      }
      createdAt
    }
  }
`;
export const createTargetAccount = /* GraphQL */ `
  mutation CreateTargetAccount(
    $input: CreateTargetAccountInput!
  ) {
    createTargetAccount(input: $input) {
      id
      mask
      name
      subtype
      type
      institution
      public_token
      stripeAccountId
      balance
      customer {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
      }
      transactions {
        items {
          id
          subscriptionId
          customerSubscriptionId
          createdAt
          merchantName
          customerName
          tipAmount
          taxAmount
          amount
          rewardInformation
          paymentStatus
        }
        nextToken
      }
      description
      createdAt
    }
  }
`;
export const updateTargetAccount = /* GraphQL */ `
  mutation UpdateTargetAccount(
    $input: UpdateTargetAccountInput!
  ) {
    updateTargetAccount(input: $input) {
      id
      mask
      name
      subtype
      type
      institution
      public_token
      stripeAccountId
      balance
      customer {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
      }
      transactions {
        items {
          id
          subscriptionId
          customerSubscriptionId
          createdAt
          merchantName
          customerName
          tipAmount
          taxAmount
          amount
          rewardInformation
          paymentStatus
        }
        nextToken
      }
      description
      createdAt
    }
  }
`;
export const deleteTargetAccount = /* GraphQL */ `
  mutation DeleteTargetAccount(
    $input: DeleteTargetAccountInput!
  ) {
    deleteTargetAccount(input: $input) {
      id
      mask
      name
      subtype
      type
      institution
      public_token
      stripeAccountId
      balance
      customer {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
      }
      transactions {
        items {
          id
          subscriptionId
          customerSubscriptionId
          createdAt
          merchantName
          customerName
          tipAmount
          taxAmount
          amount
          rewardInformation
          paymentStatus
        }
        nextToken
      }
      description
      createdAt
    }
  }
`;
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
  ) {
    createTransaction(input: $input) {
      id
      targetAccount {
        id
        mask
        name
        subtype
        type
        institution
        public_token
        stripeAccountId
        balance
        customer {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        receivedTransactions {
          nextToken
        }
        transactions {
          nextToken
        }
        createdAt
      }
      sourceAccount {
        id
        mask
        name
        subtype
        type
        institution
        public_token
        stripeAccountId
        balance
        customer {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        receivedTransactions {
          nextToken
        }
        transactions {
          nextToken
        }
        createdAt
      }
      merchantInformation {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
      }
      customerInformation {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
      }
      subscriptionId
      customerSubscriptionId
      createdAt
      merchantName
      customerName
      tipAmount
      taxAmount
      amount
      rewardInformation
      paymentStatus
      disputes {
        items {
          id
          reason
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
  ) {
    updateTransaction(input: $input) {
      id
      targetAccount {
        id
        mask
        name
        subtype
        type
        institution
        public_token
        stripeAccountId
        balance
        customer {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        receivedTransactions {
          nextToken
        }
        transactions {
          nextToken
        }
        createdAt
      }
      sourceAccount {
        id
        mask
        name
        subtype
        type
        institution
        public_token
        stripeAccountId
        balance
        customer {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        receivedTransactions {
          nextToken
        }
        transactions {
          nextToken
        }
        createdAt
      }
      merchantInformation {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
      }
      customerInformation {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
      }
      subscriptionId
      customerSubscriptionId
      createdAt
      merchantName
      customerName
      tipAmount
      taxAmount
      amount
      rewardInformation
      paymentStatus
      disputes {
        items {
          id
          reason
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
  ) {
    deleteTransaction(input: $input) {
      id
      targetAccount {
        id
        mask
        name
        subtype
        type
        institution
        public_token
        stripeAccountId
        balance
        customer {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        receivedTransactions {
          nextToken
        }
        transactions {
          nextToken
        }
        createdAt
      }
      sourceAccount {
        id
        mask
        name
        subtype
        type
        institution
        public_token
        stripeAccountId
        balance
        customer {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        receivedTransactions {
          nextToken
        }
        transactions {
          nextToken
        }
        createdAt
      }
      merchantInformation {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
      }
      customerInformation {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
      }
      subscriptionId
      customerSubscriptionId
      createdAt
      merchantName
      customerName
      tipAmount
      taxAmount
      amount
      rewardInformation
      paymentStatus
      disputes {
        items {
          id
          reason
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const createRewardLol = /* GraphQL */ `
  mutation CreateRewardLol(
    $input: CreateRewardLolInput!
  ) {
    createRewardLol(input: $input) {
      id
      points
      customerLol {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
      }
      merchantLol {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
      }
      updatedAt
    }
  }
`;
export const updateRewardLol = /* GraphQL */ `
  mutation UpdateRewardLol(
    $input: UpdateRewardLolInput!
  ) {
    updateRewardLol(input: $input) {
      id
      points
      customerLol {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
      }
      merchantLol {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
      }
      updatedAt
    }
  }
`;
export const deleteRewardLol = /* GraphQL */ `
  mutation DeleteRewardLol(
    $input: DeleteRewardLolInput!
  ) {
    deleteRewardLol(input: $input) {
      id
      points
      customerLol {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
      }
      merchantLol {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
      }
      updatedAt
    }
  }
`;
export const createDispute = /* GraphQL */ `
  mutation CreateDispute(
    $input: CreateDisputeInput!
  ) {
    createDispute(input: $input) {
      id
      parentTransaction {
        id
        targetAccount {
          id
          mask
          name
          subtype
          type
          institution
          public_token
          stripeAccountId
          balance
          createdAt
        }
        sourceAccount {
          id
          mask
          name
          subtype
          type
          institution
          public_token
          stripeAccountId
          balance
          createdAt
        }
        merchantInformation {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        customerInformation {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        subscriptionId
        customerSubscriptionId
        createdAt
        merchantName
        customerName
        tipAmount
        taxAmount
        amount
        rewardInformation
        paymentStatus
        disputes {
          nextToken
        }
      }
      reason
      createdAt
    }
  }
`;
export const updateDispute = /* GraphQL */ `
  mutation UpdateDispute(
    $input: UpdateDisputeInput!
  ) {
    updateDispute(input: $input) {
      id
      parentTransaction {
        id
        targetAccount {
          id
          mask
          name
          subtype
          type
          institution
          public_token
          stripeAccountId
          balance
          createdAt
        }
        sourceAccount {
          id
          mask
          name
          subtype
          type
          institution
          public_token
          stripeAccountId
          balance
          createdAt
        }
        merchantInformation {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        customerInformation {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        subscriptionId
        customerSubscriptionId
        createdAt
        merchantName
        customerName
        tipAmount
        taxAmount
        amount
        rewardInformation
        paymentStatus
        disputes {
          nextToken
        }
      }
      reason
      createdAt
    }
  }
`;
export const deleteDispute = /* GraphQL */ `
  mutation DeleteDispute(
    $input: DeleteDisputeInput!
  ) {
    deleteDispute(input: $input) {
      id
      parentTransaction {
        id
        targetAccount {
          id
          mask
          name
          subtype
          type
          institution
          public_token
          stripeAccountId
          balance
          createdAt
        }
        sourceAccount {
          id
          mask
          name
          subtype
          type
          institution
          public_token
          stripeAccountId
          balance
          createdAt
        }
        merchantInformation {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        customerInformation {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        subscriptionId
        customerSubscriptionId
        createdAt
        merchantName
        customerName
        tipAmount
        taxAmount
        amount
        rewardInformation
        paymentStatus
        disputes {
          nextToken
        }
      }
      reason
      createdAt
    }
  }
`;
export const createLocationObject = /* GraphQL */ `
  mutation CreateLocationObject(
    $input: CreateLocationObjectInput!
  ) {
    createLocationObject(input: $input) {
      id
      businessCovidInfo {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
        weeklyCustomerAmount
        weeklyEarnings
        covidInfo
        recentVisitorObjects {
          nextToken
        }
        recentlyVisitedLocations {
          nextToken
        }
      }
      customerCovidInfo {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
        weeklyCustomerAmount
        weeklyEarnings
        covidInfo
        recentVisitorObjects {
          nextToken
        }
        recentlyVisitedLocations {
          nextToken
        }
      }
      entryTime
      exitTime
      updatedAt
      createdAt
    }
  }
`;
export const updateLocationObject = /* GraphQL */ `
  mutation UpdateLocationObject(
    $input: UpdateLocationObjectInput!
  ) {
    updateLocationObject(input: $input) {
      id
      businessCovidInfo {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
        weeklyCustomerAmount
        weeklyEarnings
        covidInfo
        recentVisitorObjects {
          nextToken
        }
        recentlyVisitedLocations {
          nextToken
        }
      }
      customerCovidInfo {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
        weeklyCustomerAmount
        weeklyEarnings
        covidInfo
        recentVisitorObjects {
          nextToken
        }
        recentlyVisitedLocations {
          nextToken
        }
      }
      entryTime
      exitTime
      updatedAt
      createdAt
    }
  }
`;
export const deleteLocationObject = /* GraphQL */ `
  mutation DeleteLocationObject(
    $input: DeleteLocationObjectInput!
  ) {
    deleteLocationObject(input: $input) {
      id
      businessCovidInfo {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
        weeklyCustomerAmount
        weeklyEarnings
        covidInfo
        recentVisitorObjects {
          nextToken
        }
        recentlyVisitedLocations {
          nextToken
        }
      }
      customerCovidInfo {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
        weeklyCustomerAmount
        weeklyEarnings
        covidInfo
        recentVisitorObjects {
          nextToken
        }
        recentlyVisitedLocations {
          nextToken
        }
      }
      entryTime
      exitTime
      updatedAt
      createdAt
    }
  }
`;
export const createMenu = `mutation CreateMenu($input: CreateMenuInput!) {
  createMenu(input: $input) {
    id
    name
    updatedAt
    createdAt
    menuItems {
      items {
        id
        name
        updatedAt
        createdAt
        price
        weeklyOrders
        amountRemaining
        recentCustomers
      }
      nextToken
    }
    merchant {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
  }
}
`;
export const updateMenu = `mutation UpdateMenu($input: UpdateMenuInput!) {
  updateMenu(input: $input) {
    id
    name
    updatedAt
    createdAt
    menuItems {
      items {
        id
        name
        price
        updatedAt
        createdAt
        weeklyOrders
        amountRemaining
        recentCustomers
      }
      nextToken
    }
    merchant {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
  }
}
`;
export const deleteMenu = `mutation DeleteMenu($input: DeleteMenuInput!) {
  deleteMenu(input: $input) {
    id
    name
    updatedAt
    createdAt
    menuItems {
      items {
        id
        name
        updatedAt
        createdAt
        weeklyOrders
        amountRemaining
        recentCustomers
      }
      nextToken
    }
    merchant {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
  }
}
`;
export const createMenuItem = `mutation CreateMenuItem($input: CreateMenuItemInput!) {
  createMenuItem(input: $input) {
    id
    name
    price
    updatedAt
    createdAt
    description
    options
    parent {
      id
      name
      updatedAt
      createdAt
      menuItems {
        nextToken
      }
      merchant {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
        weeklyCustomerAmount
        weeklyEarnings
        covidInfo
      }
    }
    weeklyOrders
    amountRemaining
    recentCustomers
  }
}
`;
export const updateMenuItem = `mutation UpdateMenuItem($input: UpdateMenuItemInput!) {
  updateMenuItem(input: $input) {
    id
    name
    updatedAt
    createdAt
    price
    description
    options
    parent {
      id
      name
      updatedAt
      createdAt
      menuItems {
        nextToken
      }
      merchant {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
        weeklyCustomerAmount
        weeklyEarnings
        covidInfo
      }
    }
    weeklyOrders
    amountRemaining
    recentCustomers
  }
}
`;
export const deleteMenuItem = `mutation DeleteMenuItem($input: DeleteMenuItemInput!) {
  deleteMenuItem(input: $input) {
    id
    name
    updatedAt
    createdAt
    parent {
      id
      name
      updatedAt
      createdAt
      menuItems {
        nextToken
      }
      merchant {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
        weeklyCustomerAmount
        weeklyEarnings
        covidInfo
      }
    }
    weeklyOrders
    amountRemaining
    recentCustomers
  }
}
`;
export const createReceipt = `mutation CreateReceipt($input: CreateReceiptInput!) {
  createReceipt(input: $input) {
    id
    createdAt
    customer {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    merchant {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    served
    menuItems
  }
}
`;
export const updateReceipt = `mutation UpdateReceipt($input: UpdateReceiptInput!) {
  updateReceipt(input: $input) {
    id
    createdAt
    customer {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    merchant {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    served
    menuItems
  }
}
`;
export const deleteReceipt = `mutation DeleteReceipt($input: DeleteReceiptInput!) {
  deleteReceipt(input: $input) {
    id
    createdAt
    customer {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    merchant {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    served
    menuItems
  }
}
`;
