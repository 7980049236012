/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  ModalHeader,
  Modal,
  ModalFooter,
  ModalBody,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label
} from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import UserHeader from "components/Headers/UserHeader.jsx";
import { ClipLoader } from 'react-spinners';
import API, { graphqlOperation } from '@aws-amplify/api';
import {getMoreMerchantTransactions} from "../../graphql/queries";
import {createTargetAccount,updateCustomer,updateTransaction,createMenu,updateMenu,deleteMenu,createMenuItem,updateMenuItem,deleteMenuItem} from '../../graphql/mutations';
import {onUpdateTransactionMerchant,onUpdateCustomer} from '../../graphql/subscriptions';

let options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute:'numeric' };
// let sampleMenu = [{name:"Hamburger",price:"$12.25",popularity:0.8,users:["Johnny Bravo"],remaining:0.1}]

class Menu extends React.Component {
  state = {
    validAccounts:[],
    transactions:this.props.user.merchantTransactions,
    nextToken:this.props.user.merchantTransactions.nextToken,
    selectedDate:new Date().toLocaleString('en-US',{ year: 'numeric', month: 'numeric'}),
    loading:false,
    thisMonth:new Date().toLocaleString('en-US',{year:'numeric',month: 'numeric'}),
    currentDate:new Date(),
    index:0,
    storedStatements:{},
    menuItems:{items:[{name:"Hamburger",updatedAt:"2020-08-01",weeklyOrders:{"8/2020":50},amountRemaining:30,recentCustomers:{Kenton:"Blacky"},price:1000,id:"808000"}]},
    merchantMenus:this.props.user.merchantMenus,
    editingMenuTitle:{},
    addingMenuItem:{},
    editingMenuItem:{},
    editedMenuItemName:"",
    editedMenuItemPrice:"",
    editedMenuItemAmountRemaining:"",
    newMenuItemSubOptionName:"",
    addingNewMenuItemSubOption:{},
    editingMenuItemOption:{}
  };
  componentDidMount(){
    // let thisMonth = new Date().toLocaleString('en-US',{year:'numeric',month: 'numeric'})
    // this.getMonthlyStatement(thisMonth)
  }
  newMenuOption(){
    console.log("Adding new menu option")
    console.log("Finished adding new menu option")
  }
  addNewMenuSubOptionToState(subOption,newMenuItemSubOptionIndex){
    console.log("Adding sub option")
    var newOption = {}
    if (!isNaN(this.state.newMenuItemSubOptionPrice)){
      if ((parseFloat(this.state.newMenuItemSubOptionPrice)*100)>0){
        newOption = {name:this.state.newMenuItemSubOptionName,price:parseFloat(this.state.newMenuItemSubOptionPrice)*100}
      }
      else{
        newOption = {name:this.state.newMenuItemSubOptionName}
      }
    }
    else{
      newOption = {name:this.state.newMenuItemSubOptionName}
    }
    subOption.options = [...subOption.options,newOption]
    this.setState({newMenuItemOptions:this.state.newMenuItemOptions,newMenuItemSubOptionName:"",addingNewMenuItemSubOption:false,newMenuItemSubOptionPrice:""})
    console.log("Finished adding suboption")
  }
  async menuItem(request){
    console.log("Menu item processing")
    switch(request){
      case "editMenuItem":
        console.log("Edit menu item")
      case "updateMenuTitle":
        console.log("Updating Menu Title")
      case "removeMenuItem":
        console.log("Removing menu item")
        this.setState({modalStage:"loading"})
        var input = {id:this.state.selectedMenuItemId}
        await API.graphql(graphqlOperation(deleteMenuItem,{input}))
        this.state.merchantMenus.items[this.state.selectedMenuIndex].menuItems.items.splice(this.state.selectedMenuItemIndex,1)
        this.setState({merchantMenus:this.state.merchantMenus})
        this.setState({modalVisible:false})
      // case "parentItem":
      //   console.log("Edit menu title")
      // case "editMenuTitle":
      //   console.log("Edit menu title")
      //   this.setState({editingMenuTitle:true})
    }
    console.log("Finished menu item processing")
  }
  menuPrompt(request,id,index,selectedMenuItemIndex){
    console.log("Menu item processing")
    switch(request){
      case "edit":
        this.setState({modalVisible:true,modalStage:"editMenuItem",menuItemId:id});
        break
      case "removeMenuItem":
        this.setState({modalVisible:true,modalStage:"removeMenuItem",selectedMenuItemId:id,selectedMenuItemIndex:selectedMenuItemIndex,selectedMenuIndex:index});
        break
      case "editMenuTitle":
        console.log("Edit menu title")
        this.setState({editingMenuTitle:true})
        break
      case "deleteMenu":
        // id is in this case an object of type Menu
        this.setState({modalVisible:true,modalStage:"deleteMenu",selectedMenu:id,selectedMenuIndex:index});
        break
      default:
        console.log("Nothing to do")
        break
      }
    }
  async updateMenu(command,item,index){
    try{
      switch(command){
        case "updateMenuTitle":
          console.log("Updating menu title")
          var input = {name:this.state.selectedMenuTitle[item.id],id:item.id}
          var response = await API.graphql(graphqlOperation(updateMenu,{input}))
          this.state.merchantMenus.items[index]=response.data.updateMenu
          this.setState({merchantMenus:this.state.merchantMenus,editingMenuTitle:{[item.id]:false}})
          console.log("Finished updating menu title")
          break
        case "createMenu":
          console.log("Creating menu")
          var input = {name:this.state.newMenuName,menuMerchantId:this.props.user.id}
          await API.graphql(graphqlOperation(createMenu,{input})).then(res=>this.setState({merchantMenus:{items:[res.data.createMenu,...this.state.merchantMenus.items]},newMenu:undefined}))
          console.log("Finished creating menu")
          break
        case "deleteMenu":
          console.log("Removing menu")
          this.setState({modalStage:"loading"})
          var input = {id:this.state.selectedMenu.id}
          await API.graphql(graphqlOperation(deleteMenu,{input})).then(res=>console.log(res))
          this.state.merchantMenus.items.splice(this.state.selectedMenuIndex,1)
          this.setState({merchantMenus:this.state.merchantMenus})
          console.log("Finished removing menu")
          this.setState({modalVisible:false,modalStage:null})
          break
        }
    }
    catch(err){
      alert("There was an error processing your request, please try again")
      console.log(err)
    }
  }
  async addNewMenuItem(parentItem,index){
    try{
      this.setState({creatingMenuItem:true})
      console.log("Adding new menu item")
      let parentId = parentItem.id
      let input = {name:this.state.newMenuItemName[parentId],menuItemParentId:parentId,price:parseInt(parseFloat(this.state.newMenuItemPrice[parentId])*100),amountRemaining:parseInt(this.state.newMenuRemainingAmount[parentId]),weeklyOrders:JSON.stringify({}),recentCustomers:JSON.stringify({}),description:this.state.newMenuItemDescription[parentId]}
      var response = await API.graphql(graphqlOperation(createMenuItem,{input}))
      this.state.merchantMenus.items[index].menuItems.items = [response.data.createMenuItem,...this.state.merchantMenus.items[index].menuItems.items]
      this.setState({merchantMenus:this.state.merchantMenus,addingMenuItem:{parentId:false},creatingMenuItem:false})
      console.log("Finished adding new menu item")
    }
    catch (err){
      console.log(err)
      this.setState({creatingMenuItem:false})
      alert("There was an error processing your request, please review your data and try again.")
    }
  }
  async updateMenuItem(parentItem,item,index,selectedMenuItemIndex){
    try{
      this.setState({updatingMenuItem:true})
      console.log("Updating menu item")
      let parentId = parentItem.id
      let input = {name:this.state.editedMenuItemName,price:parseInt(parseFloat(this.state.editedMenuItemPrice)*100),amountRemaining:parseInt(this.state.editedMenuItemRemainingAmount),id:item.id,description:this.state.editedMenuItemDescription}
      var response = await API.graphql(graphqlOperation(updateMenuItem,{input}))
      console.log(`Index ${index}, selectedMenuIndex ${selectedMenuItemIndex}, merchantMenus ${this.state.merchantMenus}`)
      this.state.merchantMenus.items[index].menuItems.items[selectedMenuItemIndex] = response.data.updateMenuItem
      this.setState({merchantMenus:this.state.merchantMenus,editingMenuItem:{[parentId+selectedMenuItemIndex]:false},updatingMenuItem:false})
      console.log("Finished adding new menu item")
    }
    catch (err){
      console.log(err)
      this.setState({updatingMenuItem:false})
      alert("There was an error processing your request, please review your data and try again.")
    }
  }
  removeOption(subOption,newMenuItemSubOptionIndex){
    this.state.newMenuItemOptions.splice(newMenuItemSubOptionIndex,1)
    if (this.state.newMenuItemOptions.length===0){
      this.setState({newMenuItemOptions:null,addingOneMoreOption:false})
    }
    else{
      this.setState({newMenuItemOptions:this.state.newMenuItemOptions})
    }
  }
  removeSubOption(newMenuItemSubOptionIndex,storedSubOptionIndex){
    this.state.newMenuItemOptions[newMenuItemSubOptionIndex].options.splice(storedSubOptionIndex,1)
    this.setState({newMenuItemOptions:this.state.newMenuItemOptions})
  }
  async addCustomizedOptions(){
    try{
      this.setState({addingCustomizedOptions:true})
      console.log("Adding customized options")
      let input = {id:this.state.editedMenuItem.id,options:JSON.stringify(this.state.newMenuItemOptions)}
      await API.graphql(graphqlOperation(updateMenuItem,{input})).then(res=>console.log(res))
      console.log("Finished adding customized options")
      this.state.merchantMenus.items[this.state.parentItemIndex].menuItems.items[this.state.selectedMenuItemIndex].options=JSON.stringify(this.state.newMenuItemOptions)
      this.setState({addingCustomizedOptions:false,modalVisible:false,newMenuItemOptions:[],merchantMenus:this.state.merchantMenus})
    }
    catch (err){
      console.log(err)
      this.setState({addingCustomizedOptions:false})
      alert("There was an error processing your request")
    }
  }
  renderModalBody(){
    switch(this.state.modalStage){
      case "loading":
        return(
          <div>
            <ModalBody>
              <div style={{alignItems:'center',justifyContent:"center",width:"100%",display:"flex",flexDirection:"column"}}>
                <ClipLoader loading/>
              </div>
            </ModalBody>
          </div>
        )
      case "addingOptions":
        return(
          <div>
            <ModalHeader toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}>Please add the customized options for {this.state.editedMenuItem.name}</ModalHeader>
            <ModalBody>
              {(!this.state.newMenuItemOptions)?<FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-pen" style={{color:"#6074DD" }}/>
                    </InputGroupText>
                  </InputGroupAddon>
                <Input placeholder={"Name"}
                  type="text"
                  onChange={(e)=>this.setState({newMenuItemOptionName:e.target.value})}
                  />
                  <i className="fas fa-check" style={{alignSelf:"center",cursor:"pointer",marginRight:15}} onClick={()=>this.setState({newMenuItemOptions:[{name:this.state.newMenuItemOptionName,options:[]}]})}/>
                </InputGroup>
              </FormGroup>:
              <div>
                {this.state.newMenuItemOptions.map((subOption,newMenuItemSubOptionIndex)=><div>
                  {(!this.state.editingMenuItemOption[newMenuItemSubOptionIndex.toString()])?
                  <div style={{display:"flex",flexDirection:"row"}}>
                    <h2>{subOption.name}</h2>
                    <div style={{backgroundColor:"#7634E9",borderRadius:20,width:40,height:40, alignItems:'center',justifyContent:'center',display:"flex",marginLeft:10,cursor:"pointer",marginBottom:10}} onClick={()=>this.setState({addingNewMenuItemSubOption:{[newMenuItemSubOptionIndex]:true}})}>
                      <i className="fa fa-plus" style={{color:"white",cursor:"pointer"}}/>
                    </div>
                    <div style={{backgroundColor:"#7634E9",borderRadius:20,width:40,height:40, alignItems:'center',justifyContent:'center',display:"flex",marginLeft:10,cursor:"pointer",marginBottom:10}} onClick={()=>this.removeOption(subOption,newMenuItemSubOptionIndex)}>
                      <i className="fas fa-minus" style={{color:"white",cursor:"pointer"}}/>
                    </div>
                    <div style={{backgroundColor:"#7634E9",borderRadius:20,width:40,height:40, alignItems:'center',justifyContent:'center',display:"flex",marginLeft:10,cursor:"pointer",marginBottom:10}} onClick={()=>this.setState({editingMenuItemOption:{[newMenuItemSubOptionIndex.toString()]:true}})}>
                      <i className="fas fa-pen" style={{color:"white",cursor:"pointer"}}/>
                    </div>
                  </div>
                  :
                  <div style={{display:"flex",flexDirection:"row"}}>
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-pen" style={{color:"#6074DD"}}/>
                        </InputGroupText>
                      </InputGroupAddon>
                    <Input placeholder={"Name"}
                      type="text"
                      onChange={(e)=>this.setState({newMenuItemOptionName:e.target.value})}
                      />
                      <Label check style={{zIndex:100}}>
                        <Input type="checkbox" onClick={()=>this.setState({newMenuItemOptionAllowMultipleSelections:!this.state.newMenuItemOptionAllowMultipleSelections})}/>{' '}
                        Allow Multiple
                      </Label>
                      <i className="fas fa-check" style={{alignSelf:"center",cursor:"pointer",marginRight:15}} onClick={()=>{
                          this.state.newMenuItemOptions[newMenuItemSubOptionIndex].name = this.state.newMenuItemOptionName;
                          this.state.newMenuItemOptions[newMenuItemSubOptionIndex].allowMultipleSelections = this.state.newMenuItemOptionAllowMultipleSelections;
                          this.setState({newMenuItemOptions:this.state.newMenuItemOptions,editingMenuItemOption:false});
                        }
                        }

                        />
                    </InputGroup>
                </FormGroup>
                  </div>
                }
                  {subOption.options.map((lostOption,storedSubOptionIndex)=>
                    <div style={{display:"flex",flexDirection:"row",marginLeft:20}}>
                      <p>{lostOption.name} {(lostOption.price)?"| +$"+(parseInt(lostOption.price)/100).toFixed(2):""}</p>
                      <div style={{backgroundColor:"#7634E9",borderRadius:10,width:20,height:20, alignItems:'center',justifyContent:'center',display:"flex",marginLeft:10,cursor:"pointer",marginTop:2}} onClick={()=>this.removeSubOption(newMenuItemSubOptionIndex,storedSubOptionIndex)}>
                        <i className="fas fa-minus" style={{color:"white",cursor:"pointer",fontSize:10}}/>
                      </div>
                    </div>
                  )}
                  {(this.state.addingNewMenuItemSubOption[newMenuItemSubOptionIndex])&&<FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-pen" style={{color:"#6074DD" }}/>
                        </InputGroupText>
                      </InputGroupAddon>
                    <Input placeholder={"Name"}
                      type="text"
                      onChange={(e)=>this.setState({newMenuItemSubOptionName:e.target.value})}
                      value={this.state.newMenuItemSubOptionName}
                      />
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-dollar-sign" style={{color:"#6074DD" }}/>
                        </InputGroupText>
                      </InputGroupAddon>
                    <Input placeholder={"Price"}
                      type="number"
                      step="0.01"
                      onChange={(e)=>this.setState({newMenuItemSubOptionPrice:e.target.value})}
                      value={this.state.newMenuItemSubOptionPrice}
                      />
                      <i className="fas fa-check" style={{alignSelf:"center",cursor:"pointer",marginRight:15}} onClick={()=>this.addNewMenuSubOptionToState(subOption,newMenuItemSubOptionIndex)}/>
                    </InputGroup>
                  </FormGroup>
                }
                </div>)}
                {(this.state.addingOneMoreOption)?<FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-pen" style={{color:"#6074DD" }}/>
                      </InputGroupText>
                    </InputGroupAddon>
                  <Input placeholder={"Name"}
                    type="text"
                    onChange={(e)=>this.setState({newMenuItemOptionName:e.target.value})}
                    />
                    <Label check>
                      <Input type="checkbox" onClick={()=>this.setState({newMenuItemOptionAllowMultipleSelections:!this.state.newMenuItemOptionAllowMultipleSelections})}/>{' '}
                      Allow Multiple
                    </Label>
                    <i className="fas fa-check" style={{alignSelf:"center",cursor:"pointer",marginRight:15}} onClick={()=>this.setState({newMenuItemOptions:[...this.state.newMenuItemOptions,{name:this.state.newMenuItemOptionName,options:[],allowMultipleSelections:this.state.newMenuItemOptionAllowMultipleSelections}],addingOneMoreOption:false})}/>
                  </InputGroup>
              </FormGroup>:
              <Button color="primary" onClick={()=>this.setState({addingOneMoreOption:true,newMenuItemOptionAllowMultipleSelections:false})}>Add Another Option</Button>
            }
              </div>
            }
            </ModalBody>
            <ModalFooter style={{flexDirection:"column",display:"flex"}}>
              {(this.state.addingCustomizedOptions)?
                  <ClipLoader loading/>
                :
                  <Button color="primary" onClick={()=>this.addCustomizedOptions()}>Save</Button>
            }
            </ModalFooter>
          </div>
        )
      case "deleteMenu":
        return(
          <div>
            <ModalHeader toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}>Please confirm the removal of this menu</ModalHeader>
            <ModalBody>
              <div>
                <p>Are you sure you want to remove this menu?</p>
              </div>
            </ModalBody>
            <ModalFooter style={{flexDirection:"column",display:"flex"}}>
              <Button color="primary" onClick={()=>this.updateMenu("deleteMenu",this.state.selectedMenu)}>Yes, Remove</Button>
            </ModalFooter>
          </div>
        )
      case "removeMenuItem":
        return(
          <div>
            <ModalHeader toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}>Please confirm the removal of this menu item</ModalHeader>
            <ModalBody>
              <div>
                <p>Are you sure you want to remove this menu item?</p>
              </div>
            </ModalBody>
            <ModalFooter style={{flexDirection:"column",display:"flex"}}>
              <Button color="primary" onClick={()=>this.menuItem("removeMenuItem")}>Yes, Remove</Button>
            </ModalFooter>
          </div>
        )
      case "transactionCancellation":
        return(
          <div>
            <ModalHeader toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}>Please confirm the cancellation of this transaction</ModalHeader>
            <ModalBody>
              <div>
                <p>Are you sure you want to cancel the transfer of ${parseFloat(this.state.selectedTransaction.amount/100).toFixed(2)} from {this.state.selectedTransaction.customerName}?</p>
              </div>
            </ModalBody>
            <ModalFooter style={{flexDirection:"column",display:"flex"}}>
              <Button color="primary" onClick={()=>this.cancelTransaction(this.state.selectedTransaction)}>Yes, Cancel Transaction</Button>
            </ModalFooter>
          </div>
        )
      case "transactionRefund":
        return(
          <div>
            <ModalHeader toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}>Please confirm the cancellation of this transaction</ModalHeader>
            <ModalBody>
              <div>
                <p>Are you sure you want to refund the transfer of ${parseFloat(this.state.selectedTransaction.amount/100).toFixed(2)} from {this.state.selectedTransaction.customerName}?</p>
              </div>
            </ModalBody>
            <ModalFooter style={{flexDirection:"column",display:"flex"}}>
              <Button color="primary" onClick={()=>this.refundTransaction(this.state.selectedTransaction)}>Yes, Refund Transaction</Button>
            </ModalFooter>
          </div>
        )
    }
  }
  render() {
    if (this.state.loading){
      return(
        <div>

        </div>
      )
    }
    return (
      <>
        <UserHeader user={this.props.user}/>
        {/* Page content */}
        <Modal
          isOpen={this.state.modalVisible}
          toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}
          centered={true}
        >
          {this.renderModalBody()}
        </Modal>
        <Container className="mt--7" fluid>
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0" style={{display:"flex",flexDirection:"row"}}>
                <h3 className="text-white mb-0">Menu</h3>
                <div style={{backgroundColor:"#7634E9",borderRadius:20,width:40,height:40, alignItems:'center',justifyContent:'center',display:"flex",marginLeft:10,cursor:"pointer"}} onClick={()=>this.addNewMenu()} onClick={()=>this.setState({newMenu:{}})}>
                  <i className="fa fa-plus" style={{color:"white"}}/>
                </div>
              </CardHeader>
              <Table
                className="align-items-center table-dark table-flush"
                responsive
              >
                {this.state.newMenu&&<div>
                  <CardHeader className="bg-transparent border-0" style={{display:"flex",flexDirection:"row",marginLeft:20}}>
                      <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
                        <input type="text" className="thead-dark" placeholder={"Name"} onChange={(e)=>this.setState({newMenuName:e.target.value})}/>
                        <i className="fas fa-check" style={{marginLeft:10,cursor:"pointer"}} onClick={()=>this.updateMenu("createMenu",null)}/>
                        <i className="fas fa-times" style={{marginLeft:10,cursor:"pointer"}} onClick={()=>this.setState({newMenu:false})}/>
                      </div>
                  </CardHeader>
                  </div>}
                {this.state.merchantMenus.items.map((parentItem,index)=><div>
                  <CardHeader className="bg-transparent border-0" style={{display:"flex",flexDirection:"row",marginLeft:20}}>
                    {(this.state.editingMenuTitle[parentItem.id])?
                      // Editing Menu Title
                      <div style={{display:"flex",flexDirection:"row"}}>
                        <input type="text" className="thead-dark" placeholder={parentItem.name} onChange={(e)=>this.setState({selectedMenuTitle:{[parentItem.id]:e.target.value}})}/>
                        <i className="fas fa-check" style={{marginLeft:10,cursor:"pointer"}} onClick={()=>this.updateMenu("updateMenuTitle",parentItem,index)}/>
                        <i className="fas fa-times" style={{marginLeft:10,cursor:"pointer"}} onClick={()=>this.setState({editingMenuTitle:{[parentItem.id]:false}})}/>
                      </div>
                      :
                      // Not editing Menu Title
                      <div style={{display:"flex",flexDirection:"row"}}>
                        <h3 className="text-white mb-0">{parentItem.name}</h3>
                        <i className="fas fa-pen" style={{marginLeft:10,cursor:"pointer"}} onClick={()=>this.setState({editingMenuTitle:{[parentItem.id]:true}})}/>
                        {/*<i className="fas fa-times" style={{marginLeft:10,cursor:"pointer"}} onClick={()=>this.menuPrompt("deleteMenu")}/>*/}
                        <div style={{backgroundColor:"#7634E9",borderRadius:20,width:40,height:40, alignItems:'center',justifyContent:'center',display:"flex",marginLeft:10,cursor:"pointer"}} onClick={()=>this.setState({addingMenuItem:{[parentItem.id]:true}})}>
                          <i className="fa fa-plus" style={{color:"white"}}/>
                        </div>
                        <div style={{backgroundColor:"#7634E9",borderRadius:20,width:40,height:40, alignItems:'center',justifyContent:'center',display:"flex",marginLeft:10,cursor:"pointer"}} onClick={()=>this.menuPrompt("deleteMenu",parentItem,index)}>
                          <i className="fa fa-minus" style={{color:"white"}}/>
                        </div>
                      </div>
                    }
                  </CardHeader>
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Item</th>
                      <th scope="col">Price</th>
                      <th scope="col">Description</th>
                      <th scope="col">Remaining</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                  {(this.state.addingMenuItem[parentItem.id])&&
                    <tr>
                      <th scope="row" key={index}>
                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-pen" style={{color:"#6074DD" }}/>
                              </InputGroupText>
                            </InputGroupAddon>
                          <Input placeholder={"Name"}
                            type="text"
                            onChange={(e)=>this.setState({newMenuItemName:{[parentItem.id]:e.target.value}})}
                            />
                            </InputGroup>
                          </FormGroup>
                      </th>
                      <td>
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-dollar-sign" style={{color:"#6074DD" }}/>
                            </InputGroupText>
                          </InputGroupAddon>
                        <Input placeholder={"Price"}
                          type="number"
                          step="0.01"
                          onChange={(e)=>this.setState({newMenuItemPrice:{[parentItem.id]:e.target.value}})}
                          />
                          </InputGroup>
                        </FormGroup>
                      </td>
                      <td>
                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-pen" style={{color:"#6074DD" }}/>
                              </InputGroupText>
                            </InputGroupAddon>
                          <Input
                              placeholder={"Description"}
                              type="text"
                              onChange={(e)=>this.setState({newMenuItemDescription:{[parentItem.id]:e.target.value}})}
                          />
                          </InputGroup>
                        </FormGroup>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <FormGroup>
                            <InputGroup className="input-group-alternative mb-3">

                            <Input
                              placeholder={"0"}
                              step="1"
                              type="number"
                              onChange={(e)=>this.setState({newMenuRemainingAmount:{[parentItem.id]:e.target.value}})}
                              />
                              </InputGroup>
                            </FormGroup>
                        </div>
                      </td>
                      <td className="text-right" style={{display:"flex",flexDirection:"row"}}>
                        {!this.state.creatingMenuItem?
                        <div style={{display:"flex",flexDirection:"row"}}>
                          <div style={{backgroundColor:"#7634E9",borderRadius:20,width:40,height:40, alignItems:'center',justifyContent:'center',display:"flex",marginLeft:10,cursor:"pointer"}} onClick={()=>this.setState({addingMenuItem:{[parentItem.id]:false}})}>
                            <i className="fa fa-times" style={{color:"white"}}/>
                          </div>
                          <div style={{backgroundColor:"#7634E9",borderRadius:20,width:40,height:40, alignItems:'center',justifyContent:'center',display:"flex",marginLeft:10,cursor:"pointer"}} onClick={()=>this.addNewMenuItem(parentItem,index)}>
                            <i className="fa fa-check" style={{color:"white"}}/>
                          </div>
                        </div>
                          :
                        <div style={{backgroundColor:"#7634E9",borderRadius:20,width:40,height:40, alignItems:'center',justifyContent:'center',display:"flex",marginLeft:10}}>
                          <ClipLoader loading/>
                        </div>
                      }
                      </td>
                    </tr>
                  }
                    {parentItem.menuItems.items.map((item,selectedMenuItemIndex)=>
                      (!this.state.editingMenuItem[parentItem.id+selectedMenuItemIndex])?
                        <tr>
                        <th scope="row" key={selectedMenuItemIndex}>
                              <span className="mb-0 text-sm">
                                {item.name}
                              </span>
                        </th>
                        <td>${(parseInt(item.price)/100).toFixed(2)} USD</td>
                        <td>
                          <span>
                            {item.description}
                          </span>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="mr-2">{item.amountRemaining}</span>
                          </div>
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={e => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                onClick={e => {(item.options===null)?this.setState({modalVisible:true,modalStage:"addingOptions",editedMenuItem:item,selectedMenuItemIndex:selectedMenuItemIndex,parentItemIndex:index}):this.setState({modalVisible:true,modalStage:"addingOptions",editedMenuItem:item,newMenuItemOptions:JSON.parse(item.options),selectedMenuItemIndex:selectedMenuItemIndex,parentItemIndex:index})}}
                                style={{cursor:"pointer"}}
                              >
                                {(item.options===null)?"Add":"Edit"} customized options
                              </DropdownItem>
                              <DropdownItem
                                onClick={e => this.setState({editingMenuItem:{[parentItem.id+selectedMenuItemIndex]:true},editedMenuItemName:item.name,editedMenuItemPrice:((parseInt(item.price)/100).toFixed(2)),editedMenuItemRemainingAmount:item.amountRemaining})}
                                style={{cursor:"pointer"}}
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem
                                onClick={e => this.menuPrompt("removeMenuItem",item.id,index,selectedMenuItemIndex)}
                                style={{cursor:"pointer"}}
                              >
                                Remove
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                        </tr>
                      :
                        <tr>
                          <th scope="row" key={index}>
                            <FormGroup>
                              <InputGroup className="input-group-alternative mb-3">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="fas fa-pen" style={{color:"#6074DD" }}/>
                                  </InputGroupText>
                                </InputGroupAddon>
                              <Input
                                placeholder={this.state.editedMenuItemName}
                                value={this.state.editedMenuItemName}
                                type="text"
                                onChange={(e)=>this.setState({editedMenuItemName:e.target.value})}
                                />
                                </InputGroup>
                              </FormGroup>
                          </th>
                          <td>
                          <FormGroup>
                            <InputGroup className="input-group-alternative mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-dollar-sign" style={{color:"#6074DD" }}/>
                                </InputGroupText>
                              </InputGroupAddon>
                            <Input
                              placeholder={(parseInt(item.price)/100).toFixed(2)}
                              type="number"
                              step="0.01"
                              value={this.state.editedMenuItemPrice}
                              onChange={(e)=>this.setState({editedMenuItemPrice:e.target.value})}
                              />
                              </InputGroup>
                            </FormGroup>
                            </td>
                          <td>
                            <FormGroup>
                              <InputGroup className="input-group-alternative mb-3">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="fas fa-pen" style={{color:"#6074DD" }}/>
                                  </InputGroupText>
                                </InputGroupAddon>
                              <Input
                                  placeholder={"Description"}
                                  type="text"
                                  value={this.state.editedMenuItemDescription}
                                  onChange={(e)=>this.setState({editedMenuItemDescription:e.target.value})}
                              />
                              </InputGroup>
                            </FormGroup>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <FormGroup>
                                <InputGroup className="input-group-alternative mb-3">
                                <Input
                                  placeholder={item.amountRemaining}
                                  value={this.state.editedMenuItemRemainingAmount}
                                  step="1"
                                  type="number"
                                  onChange={(e)=>this.setState({editedMenuItemRemainingAmount:e.target.value})}
                                  />
                                  </InputGroup>
                                </FormGroup>
                            </div>
                          </td>
                          <td className="text-right" style={{display:"flex",flexDirection:"row"}}>
                            {!this.state.updatingMenuItem?
                            <div style={{display:"flex",flexDirection:"row"}}>
                              <div style={{backgroundColor:"#7634E9",borderRadius:20,width:40,height:40, alignItems:'center',justifyContent:'center',display:"flex",marginLeft:10,cursor:"pointer"}} onClick={()=>this.setState({editingMenuItem:{[parentItem.id+selectedMenuItemIndex]:false}})}>
                                <i className="fa fa-times" style={{color:"white"}}/>
                              </div>
                              <div style={{backgroundColor:"#7634E9",borderRadius:20,width:40,height:40, alignItems:'center',justifyContent:'center',display:"flex",marginLeft:10,cursor:"pointer"}} onClick={()=>this.updateMenuItem(parentItem,item,index,selectedMenuItemIndex)}>
                                <i className="fa fa-check" style={{color:"white"}}/>
                              </div>
                            </div>
                              :
                            <div style={{backgroundColor:"#7634E9",borderRadius:20,width:40,height:40, alignItems:'center',justifyContent:'center',display:"flex",marginLeft:10}}>
                              <ClipLoader loading/>
                            </div>
                          }
                          </td>
                        </tr>
                    )
                    }
                  </tbody></div>)}
              </Table>
            </Card>
          </div>
        </Row>
        </Container>
      </>
    );
  }
}

export default Menu;
