/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert
} from "reactstrap";
import { Redirect } from "react-router-dom";

// core components
import { Auth } from 'aws-amplify';
import DemoNavbar from "../../components/Navbars/DemoNavbar.jsx";
import SimpleFooter from "../../components/Footers/SimpleFooter.jsx";

let email_reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/
let password_reg = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{12,}/

class ForgotPassword extends React.Component {
  state = {
    inputPassword:false,
    inputVerification:false,
    verificationCode:'',
    newPassword:'',
    showAlert:false,
    email:""
  }
  constructor(props){
    super(props)
  }
  _handleKeyDown = (e,command) =>{
    if (e.key==="Enter"){
      switch(command){
        case "processForgotPassword":
          (this.state.email!="")&&this.processForgotPassword();
        case "processToken":
          (this.state.verificationCode.length>3)&&this.processToken();
        case "processReset":
          (this.state.newPassword!="")&&this.processReset();
      }
    }
    return
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  async processForgotPassword(){
    try{
      if (email_reg.test(this.state.email)==false){
        alert("Invalid email inserted")
        return
      }
      const user = await Auth.forgotPassword(this.state.email);
      this.setState({inputVerification:true,showAlert:false})
    }
    catch(err){
      this.setState({showAlert:true})
      console.log(err)
    }
  }
  async processToken(){
    try{
      this.setState({inputPassword:true,inputVerification:false})
    }
    catch(err){
      this.setState({showAlert:true})
      console.log(err)
    }
  }
  async processReset(){
    try{
      const user = await Auth.forgotPasswordSubmit(this.state.email,this.state.verificationCode,this.state.newPassword);
      await Auth.signIn(this.state.email,this.state.newPassword)
      window.location.reload()
    }
    catch(err){
      this.setState({showAlert:true})
      console.log(err)
    }
  }
  renderCardBody(){
    if (this.state.inputVerification===true){
      return(
        <CardBody className="px-lg-5 py-lg-5">
          <div className="text-center text-muted mb-4">
            <small>Verification Code</small>
          </div>
          <div role="form">
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-check" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Verification Code" onChange={e=>this.setState({verificationCode:e.target.value})} value={this.state.verificationCode} onKeyDown={(e)=>this._handleKeyDown(e,"processToken")}/>
              </InputGroup>
            </FormGroup>
            {/*<FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Password"
                  type="password"
                  autoComplete="off"
                  onChange={e=>this.setState({password:e.target.value})}
                />
              </InputGroup>
            </FormGroup>*/}
            <div className="custom-control custom-control-alternative custom-checkbox">
              <input
                className="custom-control-input"
                id=" customCheckLogin"
                type="checkbox"
              />
              {/*<label
                className="custom-control-label"
                htmlFor=" customCheckLogin"
              >
                <span>Remember me</span>
              </label>*/}
            </div>
            <div className="text-center">
              <Button
                className="my-4"
                color="primary"
                type="button"
                onClick={()=>this.processToken()}>
                Continue
              </Button>
            </div>
          </div>
        </CardBody>
      )
    }
    else if (this.state.inputPassword===true){
      return(
        <CardBody className="px-lg-5 py-lg-5">
          <div className="text-center text-muted mb-4">
            <small>Input New Password</small>
          </div>
          <div role="form">
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="New Password" type="password" autoComplete="off" onChange={e=>this.setState({newPassword:e.target.value})} value={this.state.newPassword} onKeyDown={(e)=>this._handleKeyDown(e,"processReset")}/>
              </InputGroup>
            </FormGroup>
            {/*<FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Password"
                  type="password"
                  autoComplete="off"
                  onChange={e=>this.setState({password:e.target.value})}
                />
              </InputGroup>
            </FormGroup>*/}
            <div className="custom-control custom-control-alternative custom-checkbox">
              <input
                className="custom-control-input"
                id=" customCheckLogin"
                type="checkbox"
              />
              {/*<label
                className="custom-control-label"
                htmlFor=" customCheckLogin"
              >
                <span>Remember me</span>
              </label>*/}
            </div>
            <div className="text-center">
              <Button
                className="my-4"
                color="primary"
                type="button"
                onClick={()=>this.processReset()}>
                Reset
              </Button>
            </div>
          </div>
        </CardBody>
      )
    }
    else{
      return(
        <CardBody className="px-lg-5 py-lg-5">
          <div className="text-center text-muted mb-4">
            <small>Forgot Password</small>
          </div>
          <div role="form">
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Email" type="email" onChange={email=>this.setState({email:email.target.value})} onKeyDown={(e)=>this._handleKeyDown(e,"processForgotPassword")}/>
              </InputGroup>
            </FormGroup>
            {/*<FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Password"
                  type="password"
                  autoComplete="off"
                  onChange={e=>this.setState({password:e.target.value})}
                />
              </InputGroup>
            </FormGroup>*/}
            <div className="custom-control custom-control-alternative custom-checkbox">
              <input
                className="custom-control-input"
                id=" customCheckLogin"
                type="checkbox"
              />
              {/*<label
                className="custom-control-label"
                htmlFor=" customCheckLogin"
              >
                <span>Remember me</span>
              </label>*/}
            </div>
            <div className="text-center">
              <Button
                className="my-4"
                color="primary"
                type="button"
                onClick={()=>this.processForgotPassword()}>
                Continue
              </Button>
            </div>
          </div>
        </CardBody>
      )
    }
  }

  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 shape-default">
            </div>
            {this.state.showAlert&&<Alert color="danger">
              <strong>Warning</strong> An error occured while attempting to reset your password
            </Alert>}
            <Container className="pt-lg-md">
              <Row className="justify-content-center">
                <Col lg="5">
                  <Card className="bg-secondary shadow border-0" style={{marginTop:90}}>
                    {this.renderCardBody()}

                  </Card>
                  <Row className="mt-3">
                    <Col xs="6">
                      <a
                        className="text-light"
                        href="/login"
                      >
                        <small>Sign in</small>
                      </a>
                    </Col>
                    <Col className="text-right" xs="6">
                      <a
                        className="text-light"
                        href="/register"
                      >
                        <small>Create new account</small>
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default ForgotPassword;
