/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.jsx";
import { ClipLoader } from 'react-spinners';
import API, { graphqlOperation } from '@aws-amplify/api';
import {getMoreTransactions,getMoreMerchantTransactions,getMoreCustomerTransactions} from "../../graphql/queries";
import {createTargetAccount,updateCustomer,updateTransaction} from '../../graphql/mutations';
import {onUpdateTransactionMerchant,onUpdateCustomer} from '../../graphql/subscriptions';

let options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute:'numeric' };

class Profile extends React.Component {
  state = {
    nextToken: this.props.user.merchantTransactions.nextToken,
    modalStage:"cancelTransaction",
  }
  componentDidMount(){
    this.props.user.targetAccounts.items.map((item,_i)=>{
      this.setState({["isFlipped"+_i]:false})
    })
    // var sentTransactionsShown
    // if (this.props.user.merchantEnabled){
    //   sentTransactionsShown = false
    // }
    // else{
    //   sentTransactionsShown = true
    // }
    // this.setState({loading:false,sentTransactionsShown:sentTransactionsShown})
    // let input = {"subscriptionId":this.props.user.id}
    // const transaction_subscription = API.graphql(graphqlOperation(onUpdateTransactionMerchant,input))
    // transaction_subscription.subscribe({next:(data)=>{
    //   console.log("Got Subscription Event All Transactions",data.value.data.onUpdateTransactionMerchant);
    //   this.setState({transactions:[data.value.data.onUpdateTransactionMerchant,...this.state.transactions]});
    //   }
    // })
  }
  state = {
    validAccounts:[],
    sentTransactionsShown:!(this.props.user.merchantEnabled),
    transactions:(this.props.user.merchantEnabled)?this.props.user.merchantTransactions.items:this.props.user.customerTransactions.items,
    nextToken:(this.props.user.merchantEnabled)?this.props.user.merchantTransactions.nextToken:this.props.user.customerTransactions.nextToken
  };
  async getMoreTransactions(){
    try{
      console.log("Pulling more transactions");
      if (this.state.sentTransactionsShown){
        var moreTransactions= await API.graphql(graphqlOperation(getMoreCustomerTransactions,{nextToken:this.state.nextToken,id:this.props.user.id})).catch(e=>console.log(e));
        this.setState({transactions:moreTransactions.data.getCustomer.customerTransactions.items,nextToken:moreTransactions.data.getCustomer.customerTransactions.nextToken})
      }
      else{
        var moreTransactions= await API.graphql(graphqlOperation(getMoreMerchantTransactions,{nextToken:this.state.nextToken,id:this.props.user.id})).catch(e=>console.log(e));
        console.log("HERE MERCHANT",moreTransactions.data.getCustomer.merchantTransactions.items)
        this.setState({transactions:moreTransactions.data.getCustomer.merchantTransactions.items,nextToken:moreTransactions.data.getCustomer.merchantTransactions.nextToken})
      }
      console.log("Finished pulling more transactions");
    }
    catch(e){
      console.log(e)
    }
  }
  oneTransaction(item,i){
    if (this.props.user.merchantEnabled&&!this.state.sentTransactionsShown){
      return(
        <tr key={"transaction"+i.toString()}>
        {/*<th scope="row">{item.customerName}</th>*/}
        <th> {(this.props.user.id===item.customerSubscriptionId)?item.merchantName:item.customerName}</th>
        <td>${(parseInt(item.amount)/100).toFixed(2)}</td>
        <td>{this.renderPaymentStatus(item.paymentStatus,(this.props.user.id===item.customerSubscriptionId))} {(item.customerSubscriptionId!==this.props.user.id)&&<i className="fa fa-times" style={{color:"black",marginLeft:3,cursor:"pointer"}} onClick={()=>this.editTransaction(item)}/>}</td>
        <td>${(item.tipAmount!=null)?((parseInt(item.tipAmount)/100).toFixed(2)):(0).toFixed(2)}</td>
        <td>${( (parseInt(item.amount) - (parseInt(item.amount)/1.0875) ) /100 ).toFixed(2)}</td>
        <td>${((parseInt(item.amount)*0.00005)).toFixed(2)}</td>
        <td>{new Date(item.createdAt).toLocaleString('en-US',options)}</td>
        </tr>
      )
    }
    else{
      return(
        <tr key={"transaction"+i.toString()}>
        {/*<th scope="row">{item.customerName}</th>*/}
        <th> {(this.props.user.id===item.customerSubscriptionId)?item.merchantName:item.customerName}</th>
        <td>${(parseInt(item.amount)/100).toFixed(2)}</td>
        <td>{this.renderPaymentStatus(item.paymentStatus,(this.props.user.id===item.customerSubscriptionId))} {(item.customerSubscriptionId!==this.props.user.id)&&<i className="fa fa-times" style={{color:"black",marginLeft:3,cursor:"pointer"}} onClick={()=>this.editTransaction(item)}/>}</td>
        <td>{new Date(item.createdAt).toLocaleString('en-US',options)}</td>
        </tr>
      )
    }
  }
  renderModalBody(){
    switch(this.state.modalStage){
      case "loading":
        return(
          <div>
            <ModalHeader toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}>Please Pick an Account</ModalHeader>
            <ModalBody>
              <div style={{alignItems:'center',justifyContent:"center",width:"100%",display:"flex",flexDirection:"column"}}>
                <ClipLoader loading={this.state.loading}/>
              </div>
            </ModalBody>
          </div>
        )
      case "transactionCancellation":
        return(
          <div>
            <ModalHeader toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}>Please confirm the cancellation of this transaction</ModalHeader>
            <ModalBody>
              <div>
                <p>Are you sure you want to cancel the transfer of ${parseFloat(this.state.selectedTransaction.amount/100).toFixed(2)} from {this.state.selectedTransaction.customerName}?</p>
              </div>
            </ModalBody>
            <ModalFooter style={{flexDirection:"column",display:"flex"}}>
              <Button color="primary" onClick={()=>this.cancelTransaction(this.state.selectedTransaction)}>Yes, Cancel Transaction</Button>
            </ModalFooter>
          </div>
        )
      case "transactionRefund":
        return(
          <div>
            <ModalHeader toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}>Please confirm the cancellation of this transaction</ModalHeader>
            <ModalBody>
              <div>
                <p>Are you sure you want to refund the transfer of ${parseFloat(this.state.selectedTransaction.amount/100).toFixed(2)} from {this.state.selectedTransaction.customerName}?</p>
              </div>
            </ModalBody>
            <ModalFooter style={{flexDirection:"column",display:"flex"}}>
              <Button color="primary" onClick={()=>this.refundTransaction(this.state.selectedTransaction)}>Yes, Refund Transaction</Button>
            </ModalFooter>
          </div>
        )
    }
  }
  async cancelTransaction(transaction){
    console.log("Cancelling Transaction")
    let input = {id:transaction.id,paymentStatus:"cancel"}
    await API.graphql(graphqlOperation(updateTransaction,{input})).then(res=>console.log(res)).catch(err=>console.log(err))
    alert("Transaction cancelled, please refresh to see this update")
    this.setState({modalVisible:false})
    console.log("Finished Cancelling Transaction")
  }
  async refundTransaction(transaction){
    console.log("Refunding Transaction")
    let input = {id:transaction.id,paymentStatus:"refund"}
    await API.graphql(graphqlOperation(updateTransaction,{input})).then(res=>console.log(res)).catch(err=>console.log(err))
    alert("Transaction refunded, please refresh to see this update")
    this.setState({modalVisible:false})
    console.log("Finished Refunding Transaction")
  }
  renderPaymentStatus(paymentStatus,customerPerspective){
  switch(paymentStatus){
      case "pendingApproval":
        return "Pending Approval";
      case "failed":
        return "Failed"
      case "pendingDeposit":
        return (customerPerspective)?"Sent and pending deposit":"Pending deposit into your account";
      case "cancelled":
        return "Cancelled";
      case "refunded":
        return "Refunded";
      case "disputed":
        return "Disputed";
      case "reward":
        return (customerPerspective)?"Redeemed a reward":"Gave a reward";
      case "deposited":
        return (customerPerspective)?"Sent and deposited":"Received and deposited"
      default:
        return "Unavailable"
    }
}
  editTransaction(item){
    switch(item.paymentStatus){
        case "pendingApproval":
          return;
        case "failed":
          return "Failed"
        case "pendingDeposit":
          var transferredDate = new Date(item.createdAt)
          let today = new Date()
          let transferredDay = transferredDate.getUTCDay()
          let transferredHour = transferredDate.getUTCHours()
          var elapsed_time = today-transferredDate
          var cutOffDate, cutOffDay, cutOffDayOfMonth
          var cutOffTime = 0
          var cutOffHour = 0
          if ([0,1,2,3].includes(transferredDay)){
            console.log("Transfer initialized in first 4 weekdays")
            if (transferredHour<22){
              console.log("Transfer initalized before 4PM")
              cutOffDayOfMonth = transferredDate.getUTCDate()
            }
            else {
              cutOffDayOfMonth = transferredDate.getUTCDate()+1
            }
          }
          else if (transferredDay==4){
            console.log("Transfer initialized on Friday")
            cutOffDayOfMonth = transferredDate.getUTCDate()+3
            console.log(cutOffDate)
          }
          else if (transferredDay==5){
            console.log("Transferred initialized on Saturday")
            cutOffDayOfMonth = transferredDate.getUTCDate()+2
          }
          else if (transferredDay==6){
            console.log("Transferred initialized on Sunday")
            cutOffDayOfMonth = transferredDate.getUTCDate()+1
          }
          cutOffDate = new Date(
            transferredDate.getUTCFullYear(),
            transferredDate.getUTCMonth(),
            cutOffDayOfMonth,
            22
          )
          if (today < cutOffDate){
            console.log("Currently before cutoff date, allowing transaction cancellation")
            this.setState({modalVisible:true,modalStage:"transactionCancellation",selectedTransaction:item})
          }
          else{
            console.log("Too late for cutoff, not allowing cancellation, but allowing refund")
            this.setState({modalVisible:true,modalStage:"transactionRefund",selectedTransaction:item})
          }
          return;
        default:
          alert("This transaction cannot be modified")
          return;
      }
  }
  switchShownTransactions(){
    if (this.state.sentTransactionsShown){
      this.setState({sentTransactionsShown:!this.state.sentTransactionsShown,transactions:this.props.user.merchantTransactions.items,nextToken:this.props.user.merchantTransactions.nextToken})
    }
    else{
      this.setState({sentTransactionsShown:!this.state.sentTransactionsShown,transactions:this.props.user.customerTransactions.items,nextToken:this.props.user.customerTransactions.nextToken})
    }
  }
  render() {
    return (
      <>
        <UserHeader user={this.props.user}/>
        {/* Page content */}
        <Modal
          isOpen={this.state.modalVisible}
          toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}
          centered={true}
        >
          {this.renderModalBody()}
        </Modal>
        <Container className="mt--7" fluid>
        <Row >
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow" style={{marginBottom:60}}>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">{(this.state.sentTransactionsShown)?"Sent Transactions":"Received Transactions"}</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={()=>this.switchShownTransactions()}
                    >
                      {(this.state.sentTransactionsShown)?"Received":"Sent"}
                    </Button>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={e => this.getMoreTransactions()}
                      size="sm"
                    >
                      More
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Customer Name</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Status</th>
                    {this.props.user.merchantEnabled&&!this.state.sentTransactionsShown&&<th scope="col">Tip</th>}
                    {this.props.user.merchantEnabled&&!this.state.sentTransactionsShown&&<th scope="col">Tax</th>}
                    {this.props.user.merchantEnabled&&!this.state.sentTransactionsShown&&<th scope="col">Fee</th>}
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                <tbody>
                {
                  this.state.transactions.map((item,i)=>{
                  return this.oneTransaction(item,i)}
                )
                }
                </tbody>
              </Table>
            </Card>
          </Col>

        </Row>
        </Container>
      </>
    );
  }
}

export default Profile;
