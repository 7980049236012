import React from "react";
// reactstrap components
import VaxContainer from "./VaxContainer";
import VaxHeader from "./VaxHeader";
import "./stylesheets/SelectProvider.scss";
import { TextInput } from "./Inputs";
import hospitalIcon from "../../assets/img/vaxpass/hospitalIcon.png";
import { myCharts } from "../../assets/data/myChart.js";

function ListItem(props) {
  const { onClick, title, subtitle, imgSrc } = props;
  return (
    <button className="list-item" onClick={onClick}>
      <div className="d-flex">
        <img src={imgSrc} alt="" className="hospital-icon"></img>
        <div>
          <h6 className="title">{title}</h6>
          <p className="subtitle">{subtitle}</p>
        </div>
      </div>
      <hr className="list-divider"></hr>
    </button>
  );
}

class SelectProviderScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      shownMyCharts: myCharts.slice(10, 20),
    };
  }
  searchFilterFunction(text) {
    this.setState({
      searchTerm: text,
    });
    if (text.length >= 2) {
      var newData = myCharts.filter((item) => {
        var itemData = `${item.name.toUpperCase()}`;
        return itemData.indexOf(text.toUpperCase()) > -1;
      });
      this.setState({
        shownMyCharts: newData,
      });
    } else {
      this.setState({
        shownMyCharts: myCharts.slice(10, 20),
      });
    }
  }
  render() {
    return (
      <VaxContainer selectedInnerContainer="select-provider-inner-container">
        <VaxHeader
          canGoBack
          goBack={() => this.props.switchScreen("SelectVerificationScreen")}
        />
        <div className="mb-5 select-provider-screen">
          <h5 className="mt-3 mb-5" style={{ maxWidth: "80%", margin: "auto" }}>
            {" "}
            Select Medical Provider{" "}
          </h5>
          <TextInput
            placeholder="Search Provider (i.e. Cone Health)"
            icon="fas fa-search"
            value={this.state.searchTerm}
            onChange={(e) => {
              this.searchFilterFunction(e.target.value);
            }}
          />
          <div className="list">
            {this.state.shownMyCharts.map((institution, index) => {
              return (
                <ListItem
                  title={institution.name}
                  subtitle="MyChart"
                  onClick={() =>
                    this.props.switchScreen(
                      "InstantVerification",
                      institution.loginUrl
                    )
                  }
                  imgSrc={institution.imageUrl}
                />
              );
            })}
          </div>
        </div>
      </VaxContainer>
    );
  }
}

export default SelectProviderScreen;
