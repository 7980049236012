import React from "react";
import SelectVerificationScreen from "./SelectVerificationScreen.js";
import VerifiedRecordScreen from "./VerifiedRecordScreen.js";
import InstantVerificationScreen from "./InstantVerificationScreen.js";
import ImageVerificationScreen from "./ImageVerificationScreen.js";
import LoadingScreen from "./LoadingScreen.js";
import SubmissionScreen from "./SubmissionScreen.js";
import ErrorVerificationScreen from "./ErrorVerificationScreen.js";
import IntroductionScreen from "./IntroScreen.js";
import Error from "./Error";
import SelectProviderScreen from "./SelectProviderScreen";

const url = "https://ksc8uhwyq2.execute-api.us-east-1.amazonaws.com/prod";

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

class LinkScreens extends React.Component {
  state = {
    selectedScreen: "IntroductionScreen",
    verifyingImage: false
  };

  switchScreen(screenName, loginUrl) {
    if (screenName==="InstantVerification"){
      this.setState({selectedScreen: screenName, loginUrl: loginUrl})
    }
    else if ((this.state.selectedScreen==="ImageVerification")&&(screenName==="LoadingScreen")){
      this.setState({selectedScreen: screenName, verifyingImage: true})
    }
    else{
      this.setState({ selectedScreen: screenName });
    }
  }

  async fetchData(path, data) {
    const response = await fetch(url + path, {
      method: "POST",
      cache: "no-cache",
      mode: "cors",
      body: JSON.stringify(data),
    });
    const res = await response.json();
    this.setState({ fetchedData: res });
    switch (path) {
      case "/instant-verification":
        const id = res["id"]
        console.log(res,`id: ${id}`);
        var processing = true
        while (processing){
          await timeout(5000);
          var updatedResponse = await fetch(url + "/get-record", {
            method: "POST",
            cache: "no-cache",
            mode: "cors",
            body: JSON.stringify({"id": id}),
          });
          updatedResponse = await updatedResponse.json();
          console.log(updatedResponse)
          if (!updatedResponse["processing"]){
            processing = false
            if (updatedResponse.verified) {
              var valid_date_expression = updatedResponse.valid_date_expression
              var valid_date_string = valid_date_expression.slice(1,)
              var valid_date = new Date(valid_date_string)
              var now = new Date()
              var alertMessage = ""
              if (valid_date_expression[0] === ">"){
                // Indicates 'now' needs to be greater than 'valid_date' for the pass to be valid, i.e. 2 weeks after vaccination
                if (now < valid_date){
                  // Once now becomes greater than valid_date the pass will become valid
                  alertMessage = `Note: your pass will not be valid until ${valid_date_string}`
                }
              }
              this.setState({recordData: updatedResponse})
              this.switchScreen("SubmissionScreen");
            }
            else {
              this.setState({errorMessage:"Your vaccine was not verified. Please try image verification instead."})
              this.switchScreen("ErrorVerification");
            }
          }
        }
        break;
      case "/image-verification":
        console.log(res)
        if (res.verified){
          var valid_date_expression = res.valid_date_expression
          var valid_date_string = valid_date_expression.slice(1,)
          var valid_date = new Date(valid_date_string)
          var now = new Date()
          var alertMessage = ""
          if (valid_date_expression[0] === ">"){
            // Indicates 'now' needs to be greater than 'valid_date' for the pass to be valid, i.e. 2 weeks after vaccination
            if (now < valid_date){
              // Once now becomes greater than valid_date the pass will become valid
              alertMessage = `Note: your pass will not be valid until ${valid_date_string}`
            }
          }
          else {
            // Indicates 'now' needs to less than 'valid_date', i.e. within the next 72 hours
            if (now <= valid_date){
              // Pass is still valid as now is less than the valid date
              alertMessage = `Note: your pass is only valid until the end of ${valid_date_string}`
            }
            else{
              // Pass is no longer valid  as the valid date has been passed
                var errorMessage = `Your negative PCR result was confirmed, however your pass is not valid.
                To get a valid pass please submit a negative result from the past 72 hours.`
                this.setState({errorMessage:errorMessage})
                this.switchScreen("GoHomeError")
                return
            }
          }
          this.setState({alertMessage:alertMessage, recordData: res})
          this.switchScreen("SubmissionScreen")
        }
        else{
          this.setState({errorMessage:"There was an error uploading your data. Please try again by reloading this page."})
          this.switchScreen("GoHomeError")
        }
        return
      default:
        return;
    }
  }

  async downStreamFunction(fn) {}

  render() {
    switch (this.state.selectedScreen) {
      case "IntroductionScreen":
        return (
          <IntroductionScreen
            switchScreen={(screenName) => this.switchScreen(screenName)}
          />
        );
      case "SelectVerificationScreen":
        return (
          <SelectVerificationScreen
            switchScreen={(screenName) => this.switchScreen(screenName)}
          />
        );
      case "InstantVerification":
        return (
          <InstantVerificationScreen
            switchScreen={(screenName) => this.switchScreen(screenName)}
            fetchData={(path, data) => this.fetchData(path, data)}
            loginUrl={this.state.loginUrl}
          />
        );
      case "ImageVerification":
        return (
          <ImageVerificationScreen
            switchScreen={(screenName) => this.switchScreen(screenName)}
            fetchData={(path, data) => this.fetchData(path,data)}
          />
        );
      case "VerifiedRecord":
        return <VerifiedRecordScreen
                links={this.state.fetchedData}
                recordData={this.state.recordData}
                fetchData={(path, data) => this.fetchData(path,data)}
                alertMessage={this.state.alertMessage}
              />;
      case "SubmissionScreen":
        return <SubmissionScreen
        switchScreen={(screenName) => this.switchScreen(screenName)}
        />;
      case "LoadingScreen":
        return (
          <LoadingScreen
            switchScreen={(screenName) => this.switchScreen(screenName)}
            verifyingImage={this.state.verifyingImage}
          />
        );
      case "ErrorVerification":
        return (
          <ErrorVerificationScreen
            switchScreen={(screenName) => this.switchScreen(screenName)}
          />
        );
      case "SelectProvider":
        return (
          <SelectProviderScreen
            switchScreen={(screenName, loginUrl) => this.switchScreen(screenName, loginUrl)}
          />
        );
      case "GoHomeError":
        return(
          <Error
            errorMessage={(this.state.errorMessage)?this.state.errorMessage:"Sorry, there was an error verifying your image"}
            switchScreen={(screenName) => this.switchScreen(screenName)}
          />
        )
      default:
        return (
          <Error
            errorMessage={(this.state.errorMessage)?this.state.errorMessage:"We could not find the page you are looking for"}
            switchScreen={(screenName) => this.switchScreen(screenName)}
          />
        );
    }
  }
}

export default LinkScreens;
