/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  ModalHeader,
  Modal,
  ModalFooter,
  ModalBody,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import UserHeader from "components/Headers/UserHeader.jsx";
import { ClipLoader } from 'react-spinners';
import MultiPlayer from './MultiPlayer'
import Sound from 'react-sound';
import API, { graphqlOperation } from '@aws-amplify/api';
import {getMoreMerchantTransactions} from "../../graphql/queries";
import {createTargetAccount,updateCustomer,updateTransaction,createMenu,updateMenu,deleteMenu,createMenuItem,updateMenuItem,deleteMenuItem,updateReceipt} from '../../graphql/mutations';
import {onUpdateTransactionMerchant,onUpdateCustomer} from '../../graphql/subscriptions';

let options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute:'numeric' };
// let sampleMenu = [{name:"Hamburger",price:"$12.25",popularity:0.8,users:["Johnny Bravo"],remaining:0.1}]

class Orders extends React.Component {
  state = {
    validAccounts:[],
    loading:false,
    menuItems:{items:[{name:"Hamburger",updatedAt:"2020-08-01",weeklyOrders:{"8/2020":50},amountRemaining:30,recentCustomers:{Kenton:"Blacky"},price:1000,id:"808000"}]},
    orders:this.props.user.merchantReceipts,
    editingMenuTitle:{},
    addingMenuItem:{},
    editingMenuItem:{},
    editedMenuItemName:"",
    editedMenuItemPrice:"",
    editedMenuItemAmountRemaining:"",
    servedMeals:{},
    playedSoundAlready:false
  };
  componentDidMount(){
    // let thisMonth = new Date().toLocaleString('en-US',{year:'numeric',month: 'numeric'})
    // this.getMonthlyStatement(thisMonth)
  }
  componentWillUnmount(){
    console.log("Unmounted here")
  }
  renderModalBody(){
    switch(this.state.modalStage){
      case "loading":
        return(
          <div>
            <ModalBody>
              <div style={{alignItems:'center',justifyContent:"center",width:"100%",display:"flex",flexDirection:"column"}}>
                <ClipLoader loading/>
              </div>
            </ModalBody>
          </div>
        )
      case "deleteMenu":
        return(
          <div>
            <ModalHeader toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}>Please confirm the removal of this menu</ModalHeader>
            <ModalBody>
              <div>
                <p>Are you sure you want to remove this menu?</p>
              </div>
            </ModalBody>
            <ModalFooter style={{flexDirection:"column",display:"flex"}}>
              <Button color="primary" onClick={()=>this.updateMenu("deleteMenu",this.state.selectedMenu)}>Yes, Remove</Button>
            </ModalFooter>
          </div>
        )
      case "removeMenuItem":
        return(
          <div>
            <ModalHeader toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}>Please confirm the removal of this menu item</ModalHeader>
            <ModalBody>
              <div>
                <p>Are you sure you want to remove this menu item?</p>
              </div>
            </ModalBody>
            <ModalFooter style={{flexDirection:"column",display:"flex"}}>
              <Button color="primary" onClick={()=>this.menuItem("removeMenuItem")}>Yes, Remove</Button>
            </ModalFooter>
          </div>
        )
      case "transactionCancellation":
        return(
          <div>
            <ModalHeader toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}>Please confirm the cancellation of this transaction</ModalHeader>
            <ModalBody>
              <div>
                <p>Are you sure you want to cancel the transfer of ${parseFloat(this.state.selectedTransaction.amount/100).toFixed(2)} from {this.state.selectedTransaction.customerName}?</p>
              </div>
            </ModalBody>
            <ModalFooter style={{flexDirection:"column",display:"flex"}}>
              <Button color="primary" onClick={()=>this.cancelTransaction(this.state.selectedTransaction)}>Yes, Cancel Transaction</Button>
            </ModalFooter>
          </div>
        )
      case "transactionRefund":
        return(
          <div>
            <ModalHeader toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}>Please confirm the cancellation of this transaction</ModalHeader>
            <ModalBody>
              <div>
                <p>Are you sure you want to refund the transfer of ${parseFloat(this.state.selectedTransaction.amount/100).toFixed(2)} from {this.state.selectedTransaction.customerName}?</p>
              </div>
            </ModalBody>
            <ModalFooter style={{flexDirection:"column",display:"flex"}}>
              <Button color="primary" onClick={()=>this.refundTransaction(this.state.selectedTransaction)}>Yes, Refund Transaction</Button>
            </ModalFooter>
          </div>
        )
    }
  }
  async menuItem(request){
    console.log("Menu item processing")
    switch(request){
      case "editMenuItem":
        console.log("Edit menu item")
      case "updateMenuTitle":
        console.log("Updating Menu Title")
      case "removeMenuItem":
        console.log("Removing menu item")
        this.setState({modalStage:"loading"})
        var input = {id:this.state.selectedMenuItemId}
        await API.graphql(graphqlOperation(deleteMenuItem,{input}))
        this.state.merchantMenus.items[this.state.selectedMenuIndex].menuItems.items.splice(this.state.selectedMenuItemIndex,1)
        this.setState({merchantMenus:this.state.merchantMenus})
        this.setState({modalVisible:false})
      // case "parentItem":
      //   console.log("Edit menu title")
      // case "editMenuTitle":
      //   console.log("Edit menu title")
      //   this.setState({editingMenuTitle:true})
    }
    console.log("Finished menu item processing")
  }
  menuPrompt(request,id,index,selectedMenuItemIndex){
    console.log("Menu item processing")
    switch(request){
      case "edit":
        this.setState({modalVisible:true,modalStage:"editMenuItem",menuItemId:id});
        break
      case "removeMenuItem":
        this.setState({modalVisible:true,modalStage:"removeMenuItem",selectedMenuItemId:id,selectedMenuItemIndex:selectedMenuItemIndex,selectedMenuIndex:index});
        break
      case "editMenuTitle":
        console.log("Edit menu title")
        this.setState({editingMenuTitle:true})
        break
      case "deleteMenu":
        // id is in this case an object of type Menu
        this.setState({modalVisible:true,modalStage:"deleteMenu",selectedMenu:id,selectedMenuIndex:index});
        break
      default:
        console.log("Nothing to do")
        break
      }
    }
  async updateMenu(command,item,index){
    try{
      switch(command){
        case "updateMenuTitle":
          console.log("Updating menu title")
          var input = {name:this.state.selectedMenuTitle[item.id],id:item.id}
          var response = await API.graphql(graphqlOperation(updateMenu,{input}))
          this.state.merchantMenus.items[index]=response.data.updateMenu
          this.setState({merchantMenus:this.state.merchantMenus,editingMenuTitle:{[item.id]:false}})
          console.log("Finished updating menu title")
          break
        case "createMenu":
          console.log("Creating menu")
          var input = {name:this.state.newMenuName,menuMerchantId:this.props.user.id}
          await API.graphql(graphqlOperation(createMenu,{input})).then(res=>this.setState({merchantMenus:{items:[res.data.createMenu,...this.state.merchantMenus.items]},newMenu:undefined}))
          console.log("Finished creating menu")
          break
        case "deleteMenu":
          console.log("Removing menu")
          this.setState({modalStage:"loading"})
          var input = {id:this.state.selectedMenu.id}
          await API.graphql(graphqlOperation(deleteMenu,{input})).then(res=>console.log(res))
          this.state.merchantMenus.items.splice(this.state.selectedMenuIndex,1)
          this.setState({merchantMenus:this.state.merchantMenus})
          console.log("Finished removing menu")
          this.setState({modalVisible:false,modalStage:null})
          break
        }
    }
    catch(err){
      alert("There was an error processing your request, please try again")
      console.log(err)
    }
  }
  // addMenuItem(){
  //
  // }
  async addNewMenuItem(parentItem,index){
    try{
      this.setState({creatingMenuItem:true})
      console.log("Adding new menu item")
      let parentId = parentItem.id
      let input = {name:this.state.newMenuItemName[parentId],menuItemParentId:parentId,price:parseInt(parseFloat(this.state.newMenuItemPrice[parentId])*100),amountRemaining:parseInt(this.state.newMenuRemainingAmount[parentId]),weeklyOrders:JSON.stringify({}),recentCustomers:JSON.stringify({})}
      var response = await API.graphql(graphqlOperation(createMenuItem,{input}))
      this.state.merchantMenus.items[index].menuItems.items = [response.data.createMenuItem,...this.state.merchantMenus.items[index].menuItems.items]
      this.setState({merchantMenus:this.state.merchantMenus,addingMenuItem:{parentId:false},creatingMenuItem:false})
      console.log("Finished adding new menu item")
    }
    catch (err){
      console.log(err)
      this.setState({creatingMenuItem:false})
      alert("There was an error processing your request, please review your data and try again.")
    }
  }
  async updateMenuItem(parentItem,item,index,selectedMenuItemIndex){
    try{
      this.setState({updatingMenuItem:true})
      console.log("Updating menu item")
      let parentId = parentItem.id
      let input = {name:this.state.editedMenuItemName,price:parseInt(parseFloat(this.state.editedMenuItemPrice)*100),amountRemaining:parseInt(this.state.editedMenuItemRemainingAmount),id:item.id}
      var response = await API.graphql(graphqlOperation(updateMenuItem,{input}))
      console.log(`Index ${index}, selectedMenuIndex ${selectedMenuItemIndex}, merchantMenus ${this.state.merchantMenus}`)
      this.state.merchantMenus.items[index].menuItems.items[selectedMenuItemIndex] = response.data.updateMenuItem
      this.setState({merchantMenus:this.state.merchantMenus,editingMenuItem:{[parentId+selectedMenuItemIndex]:false},updatingMenuItem:false})
      console.log("Finished adding new menu item")
    }
    catch (err){
      console.log(err)
      this.setState({updatingMenuItem:false})
      alert("There was an error processing your request, please review your data and try again.")
    }
  }
  async markAllAsServed(parentItem){
    console.log("Marking all as served")
    // var menuItems = JSON.parse(parentItem.menuItems)
    // var item
    // for (var i=0;i<menuItems.length;i++){
    //   this.state.servedMeals[parentItem.id+i]=true
    // }
    this.state.servedMeals[parentItem.id]=true
    this.setState({servedMeals:this.state.servedMeals})
    let input = {id:parentItem.id,served:true}
    API.graphql(graphqlOperation(updateReceipt,{input}))
    console.log("Finished marking all as served")
  }
  async sendNotification(){
    console.log("Sending Notification")
    console.log("Finished sending notification")
  }
  render() {
    if (this.state.loading){
      return(
        <div>

        </div>
      )
    }
    return (
      <>
        <UserHeader user={this.props.user}/>
        {/* Page content */}
        <Modal
          isOpen={this.state.modalVisible}
          toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}
          centered={true}
        >
          {this.renderModalBody()}
        </Modal>
        <Container className="mt--7" fluid>
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0" style={{display:"flex",flexDirection:"row"}}>
                <h3 className="text-white mb-0">Orders</h3>
              </CardHeader>
              <Table
                className="align-items-center table-dark table-flush"
                responsive
              >

                {this.props.user.merchantReceipts.items.map((parentItem,index)=><div>
                  <CardHeader className="bg-transparent border-0" style={{display:"flex",flexDirection:"row",marginLeft:20}}>
                      <div style={{display:"flex",flexDirection:"row"}}>
                        <h3 className="text-white mb-0" style={{marginRight:20}}>{parentItem.customer.firstName} {parentItem.customer.lastName} <p>{new Date(parentItem.createdAt).toLocaleString('en-US',options)}</p>{(parentItem.table)&&<p>Table {parentItem.table}</p>}</h3>
                        {/*<i className="fas fa-times" style={{marginLeft:10,cursor:"pointer"}} onClick={()=>this.menuPrompt("deleteMenu")}/>*/}
                      </div>
                  </CardHeader>
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Item</th>
                      <th scope="col">Price</th>
                      <th scope="col">
                        {
                          (parentItem.served||(parentItem.id in this.state.servedMeals))
                          ?
                            <span class="badge-lg badge badge-success" style={{backgroundColor:"#b0eed3",cursor:"pointer",width:180}} onClick={()=>this.sendNotification(parentItem)}>Sent Notification</span>
                          :
                            <span class="badge-lg badge badge-success" style={{backgroundColor:"red",color:"white",width:180,cursor:"pointer"}} onClick={()=>this.markAllAsServed(parentItem)}>Waiting</span>
                        }
                      </th>
                      <th scope="col" />
                      {/*<th className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="lg"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              onClick={e => this.markAllAsServed(parentItem)}
                              style={{cursor:"pointer"}}
                            >
                              Mark All as Served
                            </DropdownItem>
                            <DropdownItem
                              onClick={e => this.sendNotification(parentItem)}
                              style={{cursor:"pointer"}}
                            >
                              Send Notification
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </th>*/}
                    </tr>
                  </thead>
                  <tbody>
                  {JSON.parse(parentItem.menuItems).map&&JSON.parse(parentItem.menuItems).map((item,selectedMenuItemIndex)=>
                      (item.reward)?<tr key={parentItem.id+selectedMenuItemIndex}>
                      <td scope="row" key={selectedMenuItemIndex}>
                            <span className="mb-0 text-sm">
                              {item.name}
                            </span>
                            <br/>
                            {item.options&&<span className="mb-0 text-sm" style={{marginLeft:15}}>
                              {Object.entries(item.options).map((subOption)=>subOption[0]+": "+subOption[1].name+((subOption[1].price)?" | +$"+(subOption[1].price/100).toFixed(2):""))}
                            </span>}
                      </td>
                      <td> Reward Redeemed </td>
                      </tr>:
                      <tr key={parentItem.id+selectedMenuItemIndex}>
                      <td scope="row" key={selectedMenuItemIndex}>
                            <span className="mb-0 text-sm">
                              {item.orderedAmount} {(item.orderedAmount>1)?item.name+"s":item.name}
                            </span>
                            <br/>
                            {item.options&&<span className="mb-0 text-sm" style={{marginLeft:15}}>
                              {Object.entries(item.options).map((subOption)=>subOption[0]+": "+subOption[1].name+((subOption[1].price)?" | +$"+(subOption[1].price/100).toFixed(2):""))}
                            </span>}
                      </td>
                      <td>${(parseInt(item.summedAmount)/100).toFixed(2)} USD </td>
                      </tr>
                  )
                  }
                    {/*<tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <a
                            className="avatar rounded-circle mr-3"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={require("assets/img/theme/angular.jpg")}
                            />
                          </a>
                          <Media>
                            <span className="mb-0 text-sm">
                              Angular Now UI Kit PRO
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>$1,800 USD</td>
                      <td>
                        <Badge color="" className="badge-dot">
                          <i className="bg-success" />
                          completed
                        </Badge>
                      </td>
                      <td>
                        <div className="avatar-group">
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip188614932"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-1-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip188614932"
                          >
                            Ryan Tompson
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip66535734"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-2-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip66535734"
                          >
                            Romina Hadid
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip427561578"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-3-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip427561578"
                          >
                            Alexander Smith
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip904098289"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-4-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip904098289"
                          >
                            Jessica Doe
                          </UncontrolledTooltip>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">100%</span>
                          <div>
                            <Progress
                              max="100"
                              value="100"
                              barClassName="bg-success"
                            />
                          </div>
                        </div>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Another action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Something else here
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <a
                            className="avatar rounded-circle mr-3"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={require("assets/img/theme/sketch.jpg")}
                            />
                          </a>
                          <Media>
                            <span className="mb-0 text-sm">
                              Black Dashboard
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>$3,150 USD</td>
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-danger" />
                          delayed
                        </Badge>
                      </td>
                      <td>
                        <div className="avatar-group">
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip707904950"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-1-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip707904950"
                          >
                            Ryan Tompson
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip353988222"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-2-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip353988222"
                          >
                            Romina Hadid
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip467171202"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-3-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip467171202"
                          >
                            Alexander Smith
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip362118155"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-4-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip362118155"
                          >
                            Jessica Doe
                          </UncontrolledTooltip>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">72%</span>
                          <div>
                            <Progress
                              max="100"
                              value="72"
                              barClassName="bg-danger"
                            />
                          </div>
                        </div>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Another action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Something else here
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <a
                            className="avatar rounded-circle mr-3"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={require("assets/img/theme/react.jpg")}
                            />
                          </a>
                          <Media>
                            <span className="mb-0 text-sm">
                              React Material Dashboard
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>$4,400 USD</td>
                      <td>
                        <Badge color="" className="badge-dot">
                          <i className="bg-info" />
                          on schedule
                        </Badge>
                      </td>
                      <td>
                        <div className="avatar-group">
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip226319315"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-1-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip226319315"
                          >
                            Ryan Tompson
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip711961370"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-2-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip711961370"
                          >
                            Romina Hadid
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip216246707"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-3-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip216246707"
                          >
                            Alexander Smith
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip638048561"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-4-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip638048561"
                          >
                            Jessica Doe
                          </UncontrolledTooltip>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">90%</span>
                          <div>
                            <Progress
                              max="100"
                              value="90"
                              barClassName="bg-info"
                            />
                          </div>
                        </div>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Another action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Something else here
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <a
                            className="avatar rounded-circle mr-3"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              src={require("assets/img/theme/vue.jpg")}
                            />
                          </a>
                          <Media>
                            <span className="mb-0 text-sm">
                              Vue Paper UI Kit PRO
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>$2,200 USD</td>
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-success" />
                          completed
                        </Badge>
                      </td>
                      <td>
                        <div className="avatar-group">
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip781594051"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-1-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip781594051"
                          >
                            Ryan Tompson
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip840372212"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-2-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip840372212"
                          >
                            Romina Hadid
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip497647175"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-3-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip497647175"
                          >
                            Alexander Smith
                          </UncontrolledTooltip>
                          <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id="tooltip951447946"
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={require("assets/img/theme/team-4-800x800.jpg")}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip951447946"
                          >
                            Jessica Doe
                          </UncontrolledTooltip>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">100%</span>
                          <div>
                            <Progress
                              max="100"
                              value="100"
                              barClassName="bg-danger"
                            />
                          </div>
                        </div>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Another action
                            </DropdownItem>
                            <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Something else here
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>*/}
                  </tbody></div>)}
              </Table>
            </Card>
          </div>
        </Row>
        </Container>
      </>
    );
  }
}

export default Orders;
