/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import emailjs from 'emailjs-com';
import classnames from "classnames";


import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert
} from "reactstrap";

// core components
import { Auth } from 'aws-amplify';
import DemoNavbar from "../../components/Navbars/DemoNavbar.jsx";
import SimpleFooter from "../../components/Footers/SimpleFooter.jsx";
import { ClipLoader } from 'react-spinners';
import LinkScreens from "../VaccinePass/LinkScreens.js"

class VaccineVerificationLink extends React.Component {
  
  render() {
    return (
      <>
        <LinkScreens />
      </>
    );
  }
}

export default VaccineVerificationLink;
