import React from 'react';
import {QRCode} from 'react-qrcode-logo';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import {withGetScreen} from 'react-getscreen'
import {isAndroid, isIOS} from 'react-device-detect';

// Create Document Component
let height = 1.4137*width
let width = 800

class KiktId extends React.Component{
  state={
    src:"",
    link:"https://www.kikt.io"
  }
// <div>
//   <img src={this.props.file} style={{width:width,height:height}}/>
  // <QRCode
  //   style={{position:"absolute",alignSelf:"center",justifyContent:"center",top:440,left:200,display:"block"}}
  //   getRef={c => (this.svg = c)}
  //   id={"ExpoCustomerId"}
  //   value={JSON.stringify({customerId:this.props.match.params.id})}
  //   size={400}
  //   level={"H"}
  //   includeMargin={true}
  // />
//   </div>
  async componentDidMount(){
    var link
    // console.log("Checking if mobile phone to redirect to download page")
    link = "https://www.kikt.io"
      if (isAndroid){
        link = "https://play.google.com/store/apps/details?id=com.kikt.kiktapp"
        console.log("Redirecting to Play Store")
      }
      else if (isIOS){
        link = "https://apps.apple.com/us/app/id1490925873"
        console.log("Redirecting to App Store")
      }
      this.setState({link:link})
      console.log("Finished redirecting")
    }

    render(){
      const urlParams = new URLSearchParams(this.props.location.search)
      const key = urlParams.get('id')
      if (key==="625fe7e2-08ca-4492-bebd-cb016c437e72"||(key==="71140e36-94f9-420d-a5c8-35940ed6d665")){
        return(
          <div style={{backgroundColor:"#6656A5",justifyContent:"flex-start",alignItems:"center",display:"flex",flexDirection:"column",width:"100vw", height:"100vh"}}>
            <iframe src={`https://docs.google.com/gview?url=https://public-kikt-files.s3.amazonaws.com/${key}_menu.pdf&embedded=true`} style={{width:"100vw", height:"100vh"}} frameborder="0"></iframe>
            <a href={this.state.link} style={{position:"absolute",bottom:"3vh",right:"3vw"}} className="img-fluid floating">
              <h2 style={{textAlign:"center",fontWeight:"bold",color:"#6D3EE0",fontSize:"1.5em"}}>
                Order with
              </h2>
              <img
                alt="Kikt Logo"
                src={require("../../assets/img/icons/PurpleLogo.png")}
                style={{width:"8wh",height:"8vh",cursor:"pointer"}}
              />
            </a>
              {/*<h1 style={{textAlign:"center",fontWeight:"bold",color:"white",fontSize:35,maxWidth:"100vh",marginTop:(this.props.type==="customer")?30:0}} className="img-fluid floating">
                {this.props.type==="merchant"?
                  "Get access to menus, make payments, and receive rewards with Kikt!"
                  :
                  "Send me money through Kikt!"
                }
              </h1>
              <div style={{height:450,alignItems:"center",backgroundColor:"white",width:"85%",maxWidth:450,display:"flex",justifyContent:"center",flexDirection:"column",borderRadius:10,border:'10px solid #ECAE57',marginTop:10}} className="img-fluid floating">
                <h1 style={{textAlign:"center",fontWeight:"bold",color:"#6656A5",marginBottom:15,fontSize:45}}>
                  Scan me!
                </h1>
                <QRCode
                  id={"ExpoCustomerId"}
                  value={`https://www.kikt.io/kikt-${this.props.type}-id/?id=${key}`}
                  // logoImage={require("../../assets/img/icons/favicon.png")}
                  logoWidth={80}
                  logoHeight={80}
                  size={275}
                />
              </div>*/}
          </div>
        )
      }
      else{
        return(
          <div style={{backgroundColor:"#6656A5",justifyContent:"center",alignItems:"center",display:"flex",flexDirection:"column",width:"100vw", height:"100vh"}}>
          <div style={{flexDirection:"column",display:"flex",justifyContent:"center",height:"100vh",marginTop:20,maxWidth:"100vw"}}>
              <a href="https://www.kikt.io">
                <img
                  alt="Kikt Logo"
                  src={require("../../assets/img/icons/WhiteLogoNoBorder.png")}
                  className="img-fluid floating"
                  style={{width:120*2.58,height:120,cursor:"pointer"}}
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.kikt.kiktapp">
               <img
                 alt="..."
                 className="img-fluid floating"
                 style={{width:120*2.58,height:120,cursor:"pointer"}}
                 src={require("../../assets/img/icons/common/google-play-badge.svg")}
               />
             </a>
             {/*</Button>*/}
             {/*<Button style={{background:"transparent",borderWidth:0,margin:10}}>*/}
             <a href="https://apps.apple.com/us/app/id1490925873">
               <img
                 alt="..."
                 className="img-fluid floating"
                 style={{width:120*2.58,height:120,cursor:"pointer"}}
                 src={require("../../assets/img/icons/common/app-store-badge.svg")}
               />
             </a>
          </div>
          </div>
        )
      }
    }
}

const kiktMaterials = `
{this.props.isMobile()?
  <div style={{flexDirection:"column",display:"flex",height:250,justifyContent:"center",marginTop:10}}>
    {/*<h2 style={{textAlign:"center",fontWeight:"bold",color:"white"}}>
      www.kikt.io
    </h2>*/}
    {/*<Button
     // href="https://play.google.com/store/apps/details?id=com.kikt.kiktapp"
     style={{background:"transparent",borderWidth:0,margin:10}}>*/}
     <a href="https://www.kikt.io">
       <img
         alt="Kikt Logo"
         src={require("../../assets/img/icons/WhiteLogoNoBorder.png")}
         className="img-fluid floating"
         style={{width:120*2.58,height:120,cursor:"pointer"}}
       />
     </a>
     <a href="https://play.google.com/store/apps/details?id=com.kikt.kiktapp">
      <img
        alt="..."
        className="img-fluid floating"
        style={{width:120*2.58,height:120,cursor:"pointer"}}
        src={require("../../assets/img/icons/common/google-play-badge.svg")}
      />
    </a>
    {/*</Button>*/}
    {/*<Button style={{background:"transparent",borderWidth:0,margin:10}}>*/}
    <a href="https://apps.apple.com/us/app/id1490925873">
      <img
        alt="..."
        className="img-fluid floating"
        style={{width:120*2.58,height:120,cursor:"pointer"}}
        src={require("../../assets/img/icons/common/app-store-badge.svg")}
      />
    </a>
    {/*</Button>*/}
  </div>
  :
  <div style={{flexDirection:"row",width:"85%",display:"flex",justifyContent:"space-between",height:250,marginTop:20,maxWidth:"90vh"}}>
  <div style={{flexDirection:"column",marginLeft:40,display:"flex",height:250}}>
    {/*<h2 style={{textAlign:"center",fontWeight:"bold",color:"white"}}>
      www.kikt.io
    </h2>*/}
    {/*<Button
     // href="https://play.google.com/store/apps/details?id=com.kikt.kiktapp"
     style={{background:"transparent",borderWidth:0,margin:10}}>*/}
     <a href="https://play.google.com/store/apps/details?id=com.kikt.kiktapp">
      <img
        alt="..."
        className="img-fluid floating"
        style={{width:75*2.58,height:75,cursor:"pointer"}}
        src={require("../../assets/img/icons/common/google-play-badge.svg")}
      />
      </a>
    {/*</Button>*/}
    {/*<Button style={{background:"transparent",borderWidth:0,margin:10}}>*/}
    <a href="https://apps.apple.com/us/app/id1490925873">
      <img
        alt="..."
        className="img-fluid floating"
        style={{width:75*2.58,height:75,cursor:"pointer"}}
        src={require("../../assets/img/icons/common/app-store-badge.svg")}
      />
    </a>
    {/*</Button>*/}
  </div>
  <a href="https://www.kikt.io">
    <div style={{flexDirection:"column",alignItems:"center",display:"flex",marginRight:40,justifyContent:"center",cursor:"pointer"}} className="img-fluid floating">
      <h1 style={{textAlign:"center",fontWeight:"bold",color:"white",fotnSize:20}}>
        Powered by
      </h1>
      <img
        alt="Kikt Logo"
        src={require("../../assets/img/icons/WhiteLogoNoBorder.png")}
        width="220"
        style={{}}
      />
    </div>
  </a>
</div>}`
export default withGetScreen(KiktId)


// ReactPDF.render(<MyDocument />, `${__dirname}/output.pdf`);
