/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.jsx";
import { ClipLoader } from 'react-spinners';
import API, { graphqlOperation } from '@aws-amplify/api';
import {getMoreMerchantTransactions} from "../../graphql/queries";
import {createTargetAccount,updateCustomer,updateTransaction} from '../../graphql/mutations';
import {onUpdateTransactionMerchant,onUpdateCustomer} from '../../graphql/subscriptions';

let options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute:'numeric' };

class MonthlyStatements extends React.Component {
  state = {
    validAccounts:[],
    transactions:this.props.user.merchantTransactions,
    nextToken:this.props.user.merchantTransactions.nextToken,
    selectedDate:new Date().toLocaleString('en-US',{ year: 'numeric', month: 'numeric'}),
    loading:true,
    thisMonth:new Date().toLocaleString('en-US',{year:'numeric',month: 'numeric'}),
    currentDate:new Date(),
    monthlyStatementIndex:0,
    storedStatements:{},
    previousNextToken:null,
  };
  _isMounted=true
  componentDidMount(){
    let thisMonth = new Date().toLocaleString('en-US',{year:'numeric',month: 'numeric'})
    this.getMonthlyStatement(thisMonth)
  }
  getNextMonthlyStatement(){
    let now = new Date()
    let nonExistent = (new Date(now.getFullYear(),now.getMonth()+1)).toLocaleString('en-US',{year:'numeric',month:'numeric'})
    let temp_month = new Date(this.state.currentDate).getMonth()
    let temp_year = new Date(this.state.currentDate).getFullYear()
    let nextMonth = new Date(temp_year,temp_month+1).toLocaleString('en-US',{year:'numeric',month:'numeric'})
    if (nextMonth===nonExistent){
      alert("No more months")
      return
    }
    let currentDate = new Date(temp_year,temp_month+1)
    this.setState({currentDate:currentDate})
    this.getMonthlyStatement(nextMonth)
    // this.setState({shownStatements:this.state.storedStatements[nextMonth],thisMonth:nextMonth})
  }
  getPreviousMonthlyStatement(){
    this.setState({gettingMonthlyStatement:true})
    // this.state.monthlyStatementIndex+=1
    var lastTransactionMonth
    console.log("THIS STATE THIS MONTH",this.state.currentDate)
    // debugger
    let temp_month = new Date(this.state.currentDate).getMonth()
    let temp_year = new Date(this.state.currentDate).getFullYear()
    let previousMonth = new Date(temp_year,temp_month-1).toLocaleString('en-US',{year:'numeric',month:'numeric'})
    let currentDate = new Date(temp_year,temp_month-1)
    this.setState({currentDate:currentDate})
    console.log(`Temp Month: ${temp_month} | Temp Year: ${temp_year} | Previous Month: ${previousMonth}`)
    // lastTransactionMonth = nextMonth
    // this.setState({nextMonth:this.state.previousMonth})
    // else{
    //   var currentTransaction = this.state.transactions.items[this.state.monthlyStatementIndex]
    //   lastTransactionMonth= new Date(currentTransaction.createdAt).toLocaleString('en-US',{year:'numeric',month:'numeric'})
    //   var total = {received:0,fee:0,tip:0,tax:0,deposited:0,depositedDate:lastTransactionMonth}
    // }
    this.getMonthlyStatement(previousMonth)
  }
  componentWillUnmount(){
    console.log("Umounted")
    this._isMounted=false
  }
  async getMonthlyStatement(thisMonth){
    console.log(this.state.storedStatements)
    if (thisMonth in this.state.storedStatements){
      console.log("Already got this statement")
      if (Object.keys(this.state.storedStatements[thisMonth]).length===0){
        this.setState({loading:false,gettingMonthlyStatement:false})
        alert("No more data")
      }
      else{
        this.setState({loading:false,shownStatements:this.state.storedStatements[thisMonth],gettingMonthlyStatement:false,thisMonth:thisMonth})
      }
      return
    }
    console.log("Calculating monthly statement for this month")
    var currentTransaction = this.state.transactions.items[this.state.monthlyStatementIndex]
    var lastTransactionDate = new Date(currentTransaction.createdAt).toLocaleString('en-US',{year:'numeric',month:'numeric'})
    var lastTransactionDay = new Date(currentTransaction.createdAt).toLocaleString('en-US',{year:'numeric',month:'numeric',day:'numeric'})
    console.log(lastTransactionDate)
    var lastTransactionMonth = new Date(currentTransaction.createdAt).toLocaleString('en-US',{year:'numeric',month:'numeric'})
    var shownStatements = {}
    shownStatements={
        "Total":{received:0,fee:0,tip:0,tax:0,deposited:0,depositedDate:new Date().toLocaleString('en-US',{ year: 'numeric', month: 'numeric',day:'numeric'})}
    }
    var total = shownStatements.Total
    var looping = true
    var counter = 0
    while (looping){
      if (!this._isMounted){
        looping = false
        break
      }
        lastTransactionDate = new Date(this.state.transactions.items[this.state.monthlyStatementIndex].createdAt).toLocaleString('en-US',{year:'numeric',month:'numeric'})
        lastTransactionMonth = new Date(this.state.transactions.items[this.state.monthlyStatementIndex].createdAt).toLocaleString('en-US',{year:'numeric',month:'numeric'})
        lastTransactionDay = new Date(currentTransaction.createdAt).toLocaleString('en-US',{year:'numeric',month:'numeric',day:'numeric'})
        if (thisMonth!==lastTransactionMonth){
          looping=false
          break
        }
        else{
          var currentStatement = {}
          if (lastTransactionDay in shownStatements){
            currentTransaction=this.state.transactions.items[this.state.monthlyStatementIndex]
            currentStatement = shownStatements[lastTransactionDay]
          }
          else{
            shownStatements[lastTransactionDay] = {received:0,fee:0,tip:0,tax:0,deposited:0,depositedDate:lastTransactionDay}
            currentStatement = shownStatements[lastTransactionDay]
          }
          var fee = isNaN(currentTransaction.amount*0.005)?0:(currentTransaction.amount*0.005)
          var tip = isNaN(currentTransaction.tipAmount)?0:(currentTransaction.tipAmount)
          var tax = isNaN((currentTransaction.amount)-(currentTransaction.amount/1.0875))?0:(currentTransaction.amount)-(currentTransaction.amount/1.0875)
          var deposited = (currentTransaction.amount-fee)
          currentStatement.received+=currentTransaction.amount
          currentStatement.fee+=fee
          currentStatement.tip+=tip
          currentStatement.tax+=tax
          currentStatement.deposited+=deposited
          total.received+=currentTransaction.amount
          total.fee+=fee
          total.tip+=tip
          total.tax+=tax
          total.deposited+=deposited
          this.state.monthlyStatementIndex += 1
          // First statement will be first day, and so on, i.e. current monthlyStatementIndex matches distance from current date.
          // var newStatement =
          // shownStatements={items:[,...shownStatements.items]}
        }
      if (this.state.monthlyStatementIndex===this.state.transactions.items.length){
        counter+=1
        if (counter>300){
          this.state.storedStatements[thisMonth] = shownStatements
          this.setState({loading:false,shownStatements:shownStatements,gettingMonthlyStatement:false,thisMonth:thisMonth})
          return
        }
        await this.getMoreTransactions()
        this.state.monthlyStatementIndex=0
      }
    }
    console.log("SHOWN STATEMENTS",shownStatements)
    console.log("Finished calculating monthly statement for this month")
    this.state.storedStatements[thisMonth] = shownStatements
    this.setState({loading:false,shownStatements:shownStatements,gettingMonthlyStatement:false,thisMonth:thisMonth})
  }
  async getMoreTransactions(){
    try{
      console.log("Pulling more transactions");
      var previousNextToken = this.state.nextToken
      var moreTransactions= await API.graphql(graphqlOperation(getMoreMerchantTransactions,{nextToken:this.state.nextToken,id:this.props.user.id})).catch(e=>console.log(e));
      this.setState({transactions:moreTransactions.data.getCustomer.merchantTransactions,nextToken:moreTransactions.data.getCustomer.merchantTransactions.nextToken,previousNextToken:previousNextToken})
      console.log("Finished pulling more transactions");
    }
    catch(e){
      console.log(e)
    }
  }
  oneTransaction(date,item,i){
    return(
      <tr key={"transaction"+i.toString()}>
      {/*<th scope="row">{item.customerName}</th>*/}
      <th> {date}</th>
      <td>${(item.received/100).toFixed(2)}</td>
      <td>${(item.fee/100).toFixed(2)}</td>
      <td>${(item.tip/100).toFixed(2)}</td>
      <td>${(item.tax/100).toFixed(2)}</td>
      <td>${(item.deposited/100).toFixed(2)}</td>
      {/*<td>{item.depositedDate}</td>*/}
      </tr>
    )
  }
  renderModalBody(){
    switch(this.state.modalStage){
      case "loading":
        return(
          <div>
            <ModalHeader toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}>Please Pick an Account</ModalHeader>
            <ModalBody>
              <div style={{alignItems:'center',justifyContent:"center",width:"100%",display:"flex",flexDirection:"column"}}>
                <ClipLoader loading={this.state.loading}/>
              </div>
            </ModalBody>
          </div>
        )
      case "transactionCancellation":
        return(
          <div>
            <ModalHeader toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}>Please confirm the cancellation of this transaction</ModalHeader>
            <ModalBody>
              <div>
                <p>Are you sure you want to cancel the transfer of ${parseFloat(this.state.selectedTransaction.amount/100).toFixed(2)} from {this.state.selectedTransaction.customerName}?</p>
              </div>
            </ModalBody>
            <ModalFooter style={{flexDirection:"column",display:"flex"}}>
              <Button color="primary" onClick={()=>this.cancelTransaction(this.state.selectedTransaction)}>Yes, Cancel Transaction</Button>
            </ModalFooter>
          </div>
        )
      case "transactionRefund":
        return(
          <div>
            <ModalHeader toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}>Please confirm the cancellation of this transaction</ModalHeader>
            <ModalBody>
              <div>
                <p>Are you sure you want to refund the transfer of ${parseFloat(this.state.selectedTransaction.amount/100).toFixed(2)} from {this.state.selectedTransaction.customerName}?</p>
              </div>
            </ModalBody>
            <ModalFooter style={{flexDirection:"column",display:"flex"}}>
              <Button color="primary" onClick={()=>this.refundTransaction(this.state.selectedTransaction)}>Yes, Refund Transaction</Button>
            </ModalFooter>
          </div>
        )
    }
  }
  async cancelTransaction(transaction){
    console.log("Cancelling Transaction")
    let input = {id:transaction.id,paymentStatus:"cancel"}
    await API.graphql(graphqlOperation(updateTransaction,{input})).then(res=>console.log(res)).catch(err=>console.log(err))
    alert("Transaction cancelled, please refresh to see this update")
    this.setState({modalVisible:false})
    console.log("Finished Cancelling Transaction")
  }
  async refundTransaction(transaction){
    console.log("Refunding Transaction")
    let input = {id:transaction.id,paymentStatus:"refund"}
    await API.graphql(graphqlOperation(updateTransaction,{input})).then(res=>console.log(res)).catch(err=>console.log(err))
    alert("Transaction refunded, please refresh to see this update")
    this.setState({modalVisible:false})
    console.log("Finished Refunding Transaction")
  }
  renderPaymentStatus(paymentStatus,customerPerspective){
  switch(paymentStatus){
      case "pendingApproval":
        return "Pending Approval";
      case "failed":
        return "Failed"
      case "pendingDeposit":
        return (customerPerspective)?"Sent and pending deposit":"Pending deposit into your account";
      case "cancelled":
        return "Cancelled";
      case "refunded":
        return "Refunded";
      case "disputed":
        return "Disputed";
      case "reward":
        return (customerPerspective)?"Redeemed a reward":"Gave a reward";
      case "deposited":
        return (customerPerspective)?"Sent and deposited":"Received and deposited"
      default:
        return "Unavailable"
    }
}
  editTransaction(item){
    switch(item.paymentStatus){
        case "pendingApproval":
          return;
        case "failed":
          return "Failed"
        case "pendingDeposit":
          var transferredDate = new Date(item.createdAt)
          let today = new Date()
          let transferredDay = transferredDate.getUTCDay()
          let transferredHour = transferredDate.getUTCHours()
          var elapsed_time = today-transferredDate
          var cutOffDate, cutOffDay, cutOffDayOfMonth
          var cutOffTime = 0
          var cutOffHour = 0
          if ([0,1,2,3].includes(transferredDay)){
            console.log("Transfer initialized in first 4 weekdays")
            if (transferredHour<22){
              console.log("Transfer initalized before 4PM")
              cutOffDayOfMonth = transferredDate.getUTCDate()
            }
            else {
              cutOffDayOfMonth = transferredDate.getUTCDate()+1
            }
          }
          else if (transferredDay==4){
            console.log("Transfer initialized on Friday")
            cutOffDayOfMonth = transferredDate.getUTCDate()+3
            console.log(cutOffDate)
          }
          else if (transferredDay==5){
            console.log("Transferred initialized on Saturday")
            cutOffDayOfMonth = transferredDate.getUTCDate()+2
          }
          else if (transferredDay==6){
            console.log("Transferred initialized on Sunday")
            cutOffDayOfMonth = transferredDate.getUTCDate()+1
          }
          cutOffDate = new Date(
            transferredDate.getUTCFullYear(),
            transferredDate.getUTCMonth(),
            cutOffDayOfMonth,
            22
          )
          if (today < cutOffDate){
            console.log("Currently before cutoff date, allowing transaction cancellation")
            this.setState({modalVisible:true,modalStage:"transactionCancellation",selectedTransaction:item})
          }
          else{
            console.log("Too late for cutoff, not allowing cancellation, but allowing refund")
            this.setState({modalVisible:true,modalStage:"transactionRefund",selectedTransaction:item})
          }
          return;
        default:
          alert("This transaction cannot be modified")
          return;
      }
  }
  render() {

    return (
      <>
        <UserHeader user={this.props.user}/>
        {/* Page content */}
        <Modal
          isOpen={this.state.modalVisible}
          toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}
          centered={true}
        >
          {this.renderModalBody()}
        </Modal>
        <Container className="mt--7" fluid>
        <Row >
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow" style={{marginBottom:60}}>
              <CardHeader className="border-0">
                {(!this.state.loading)?<Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">{"Monthly Statement "+this.state.thisMonth}</h3>
                  </div>
                  <div className="col text-right">
                  <Button
                    color="primary"
                    size="sm"
                    onClick={()=>this.getNextMonthlyStatement()}
                  >
                    Next
                  </Button>
                  </div>
                  <div className="col text-right">
                  {this.state.gettingMonthlyStatement?
                    <ClipLoader/>
                    :<Button
                    color="primary"
                    href="#pablo"
                    onClick={e => this.getPreviousMonthlyStatement()}
                    size="sm"
                  >
                    Previous
                  </Button>}
                  </div>
                </Row>:
                <ClipLoader loading style={{alignSelf:"center",width:"100%",display:"flex"}}/>
              }
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Received</th>
                    <th scope="col">Fee</th>
                    <th scope="col">Tip</th>
                    <th scope="col">Tax</th>
                    <th scope="col">Deposited</th>
                    {/*<th scope="col">Deposited Date</th>*/}
                  </tr>
                </thead>
                <tbody>
                {(!this.state.loading)&&
                  Object.keys(this.state.shownStatements).map((key,i)=>{
                    return this.oneTransaction(key,this.state.shownStatements[key],i)
                  })
                }
                </tbody>
              </Table>
            </Card>
          </Col>

        </Row>
        </Container>
      </>
    );
  }
}

export default MonthlyStatements;
