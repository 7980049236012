/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.jsx";
import Profile from "views/examples/Profile.jsx";
import Login from "views/examples/Login.jsx";
import Menu from "views/examples/Menu.jsx";
import MonthlyStatements from "views/examples/MonthlyStatements.jsx";
import Orders from "views/examples/Orders.jsx";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2",
    component: Index,
    layout: "/admin"
  },
  {
    path: "/transactions",
    name: "Transactions",
    icon: "ni ni-calendar-grid-58",
    component: Profile,
    layout: "/admin"
  },
  {
    path:"/orders",
    name:"Orders",
    icon:"fa fa-cutlery",
    component:Orders,
    layout:"/admin"
  },
  {
    path: "/monthlyStatements",
    name: "Monthly Statements",
    icon: "ni ni-calendar-grid-58",
    component: MonthlyStatements,
    layout: "/admin"
  },
  {
    path: "/menu",
    name: "Menu",
    icon: "fa fa-sticky-note-o",
    component: Menu,
    layout: "/admin"
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "fa fa-sign-out",
    component: Login,
    layout: "/logout"
  }
];
export default routes;
