import React from 'react'
import { loadStripe } from '@stripe/stripe-js';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert,
  Badge
} from "reactstrap";
import { ClipLoader } from 'react-spinners';
import DemoNavbar from "../../components/Navbars/DemoNavbar.jsx";
import SimpleFooter from "../../components/Footers/SimpleFooter.jsx";

export default class Success extends React.Component {

  render(){
      return (
        <>
          <DemoNavbar />
          <main ref="main">
            <section className="section section-shaped section-lg">
              <div className="shape shape-style-1 shape-default">
              </div>
              <div class="d-flex justify-content-center" style={{marginTop:100}}>
                <img src="https://media2.giphy.com/media/26gsjCZpPolPr3sBy/giphy.gif?cid=ecf05e478fded6ebc8a91506744badb2e778cd71a458492b&rid=giphy.gif"/>
              </div>
            </section>
          </main>
          <SimpleFooter />
        </>
      );
  }
}
