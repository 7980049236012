/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert
} from "reactstrap";

// core components
import { Auth } from 'aws-amplify';
import DemoNavbar from "../../components/Navbars/DemoNavbar.jsx";
import SimpleFooter from "../../components/Footers/SimpleFooter.jsx";
import { ClipLoader } from 'react-spinners';

let verificationCode_reg = /^\d{6}$/

class Login extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  state={
    loading:false,
    showAlert:false,
    password:"",
    email:"",
    awaitingVerification:false,
    verificationCode:"",
    awaitingMfa:false
  }
  _handleKeyDown = (e) =>{
    if (e.key==="Enter"){
      e.preventDefault()
      if (this.state.awaitingMfa){
        this.confirmSignIn(this.state.verificationCode);
        return
      }
      else if (this.state.awaitingVerification){
        this.confirm()
        return
      }
      else{
        this.processlogin();
        return
      }
    }
  }
  async confirm(){
    try{
      this.setState({loading:true})
      await Auth.confirmSignUp(this.state.email,this.state.verificationCode).then(res=>Auth.signIn(this.state.email,this.state.password).then(()=>window.location.reload())).catch(err=>{alert(err.message);this.setState({loading:false})})
    }
    catch (err){
      if (err.code=="CodeMismatchException"){
        alert(err.message)
        this.setState({loading:false})
      }
      else{
        console.log(err)
        this.setState({loading:false})
      }
    }
  }
  async processlogin(){
    try{
      this.setState({loading:true})
      const user = await Auth.signIn(this.state.email,this.state.password);
      // if (user.attributes["custom:address1"]!="UNSPECIFIED"){
      if (user.challengeName==="SMS_MFA"){
        console.log("Setting up password challenge")
        this.setState({awaitingMfa:true,theUser:user,loading:false,awaitingVerification:false})
        return
      }
      else if (user.challengeName==="NOMFA"){
        window.location.reload()
      }
    }
    catch(err){
      if (err.code==='UserNotConfirmedException'){
        console.log("ERROR ALREADY",err)
        this.setState({awaitingVerification:true,loading:false})
      }
      else{
        this.setState({showAlert:true,loading:false})
        console.log(err)
      }
    }
  }
  async confirmSignIn(verificationCode){
    try{
      console.log("Confirming Sign In")
      this.setState({loading:true})
      if (verificationCode_reg.test(verificationCode)===false){
        alert("Invalid verification code")
        return
      }
      else{
        const newerUser = await Auth.confirmSignIn(
          this.state.theUser,
          verificationCode,
          "SMS_MFA"
        ).catch(err=>console.log(err))
        if (newerUser){
          console.log(newerUser)
          // window.location.reload()
        }
      }
      console.log("Finished Confirming Sign In")
    }
    catch(err){
      this.setState({loading:false})
      console.log(err)
    }
  }
  renderPrompts(){
    if (this.state.awaitingMfa){
      return(
        <Col lg="5">
          <Card className="bg-secondary shadow border-0" style={{marginTop:90}}>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Verify Text Message</small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-check" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Verification Code" id="verificationCode" type="verificationCode" onChange={e=>this.setState({verificationCode:e.target.value})} onKeyDown={(e)=>this._handleKeyDown(e)} value={this.state.verificationCode}/>
                  </InputGroup>
                </FormGroup>

                <div className="text-center">
                  {(!this.state.loading)?<Button
                    className="my-4"
                    color="primary"
                    type="button"
                    disabled={(this.state.verificationCode=="")}
                    onClick={()=>this.confirmSignIn(this.state.verificationCode)}>
                    Verify
                  </Button>:
                  <div>
                  <ClipLoader
                    loading={this.state.loading}
                  />
                  </div>
                }
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="/forgot-password"
              >
                <small>Forgot password?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="/register"
              >
                <small>Create new account</small>
              </a>
            </Col>
          </Row>
        </Col>
      )
    }
    if (this.state.awaitingVerification){
      return(
        <Col lg="5">
          <Card className="bg-secondary shadow border-0" style={{marginTop:90}}>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Verify Email</small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-check" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Verification Code" id="verificationCode" type="verificationCode" onChange={e=>this.setState({verificationCode:e.target.value})} onKeyDown={(e)=>this._handleKeyDown(e)} value={this.state.verificationCode}/>
                  </InputGroup>
                </FormGroup>

                <div className="text-center">
                  {(!this.state.loading)?<Button
                    className="my-4"
                    color="primary"
                    type="button"
                    disabled={(this.state.verificationCode=="")}
                    onClick={()=>this.confirm()}>
                    Verify
                  </Button>:
                  <div>
                  <ClipLoader
                    loading={this.state.loading}
                  />
                  </div>
                }
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="/forgot-password"
              >
                <small>Forgot password?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="/register"
              >
                <small>Create new account</small>
              </a>
            </Col>
          </Row>
        </Col>
      )
    }
    return(
      <Col lg="5">
        <Card className="bg-secondary shadow border-0" style={{marginTop:90}}>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Sign In</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Email" type="email" onChange={email=>this.setState({email:email.target.value})} onKeyDown={(e)=>this._handleKeyDown(e)}/>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="off"
                    onChange={e=>this.setState({password:e.target.value})}
                    onKeyDown={(e)=>this._handleKeyDown(e)}
                  />
                </InputGroup>
              </FormGroup>

              <div className="text-center">
                {(!this.state.loading)?<Button
                  className="my-4"
                  color="primary"
                  type="button"
                  disabled={(this.state.email==""||this.state.password=="")}
                  onClick={()=>this.processlogin()}>
                  Sign in
                </Button>:
                <div>
                <ClipLoader
                  loading={this.state.loading}
                />
                </div>
              }
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="/forgot-password"
            >
              <small>Forgot password?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <a
              className="text-light"
              href="/register"
            >
              <small>Create new account</small>
            </a>
          </Col>
        </Row>
      </Col>
    )
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 shape-default">

            </div>
            {this.state.showAlert&&<Alert color="danger">
              <strong>Warning</strong> There was an error with your username or password
            </Alert>}
            <Container className="pt-lg-md">
              <Row className="justify-content-center">
                {this.renderPrompts()}
              </Row>
              <div style={{marginBottom:100,height:75,width:100}}>
              </div>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Login;
