import React from "react";
import { Container, Form } from "reactstrap";
import VaxContainer from "./VaxContainer";
import VaxHeader from "./VaxHeader";
import { BlueButton } from "./Buttons";
import { TextInput, DobInput } from "./Inputs";
import { ImagePicker } from "react-file-picker";
import fileUploadIcon from "../../assets/img/vaxpass/uploadIcon.svg";
import "./stylesheets/ImageVerificationScreen.scss";

class ImageVerificationScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      dob: "",
      selectedImage:null
    };
  }
  showDatepicker() {}

  verifyButton() {}

  verify_record(e) {
    if ((this.state.firstName==="")||(this.state.lastName==="")||(this.state.email==="")||(this.state.dob==="")){
      return
    }
    if (this.state.selectedImage===null){
      alert("Please select an image to verify your record")
      e.preventDefault();
      return
    }
    e.preventDefault();
    let input = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      dob: this.state.dob,
      image: this.state.selectedImage
    }
    this.props.fetchData("/image-verification", input);
    this.props.switchScreen("LoadingScreen");
  }

  async uploadImage(file){
    try{
      this.setState({selectedImage: file})
    }
    catch(err){
      alert("Please upload a valid image")
      console.log(err)
    }
  }

  render() {
    return (
      <VaxContainer>
        <VaxHeader
          canGoBack={true}
          goBack={() => this.props.switchScreen("SelectVerificationScreen")}
        />
        <Container>
          <h5 className="my-4"> Image Verification Screen </h5>
        </Container>
        <Form>
        <TextInput
          required={true}
          placeholder="First Name"
          icon="ni ni-circle-08"
          value={this.state.firstName}
          onChange={(e) => {
            this.setState({ firstName: e.target.value });
          }}
        />
        <TextInput
          required={true}
          placeholder="Last Name"
          icon="ni ni-circle-08"
          value={this.state.lastName}
          onChange={(e) => {
            this.setState({ lastName: e.target.value });
          }}
        />
        <TextInput
          required={true}
          placeholder="Email Address"
          icon="ni ni-email-83"
          value={this.state.email}
          type="email"
          onChange={(e) => {
            this.setState({ email: e.target.value });
          }}
        />
        <DobInput
          required={true}
          placeholder="Date of Birth (MM/DD/YYYY)"
          icon="ni ni-calendar-grid-58"
          value={this.state.dob}
          onChange={(e) => {
            this.setState({ dob: e.target.value });
          }}
        />
        <ImagePicker
          onChange={base64 => this.uploadImage(base64)}
          onError={(errMsg) => {console.log(errMsg);alert("Please upload a valid image")}}
          dims={{minWidth: 50, maxWidth: 9999, minHeight: 50, maxHeight: 9999}}
          extensions={['jpg', 'jpeg', 'png']}
        >
          <div className="d-flex justify-content-center file-upload-box">
            <button className="file-upload-button" onClick={(e)=>e.preventDefault()}>
              <img src={fileUploadIcon} alt=""></img>
              <h6>{(this.state.selectedImage!==null)?"Image selected":"Select Image"}</h6>
            </button>
          </div>
        </ImagePicker>
        <BlueButton
          onClick={(e) => this.verify_record(e)}
          className="my-5"
          type="submit"
        >
          SUBMIT
        </BlueButton>
        </ Form>
      </VaxContainer>
    );
  }
}

export default ImageVerificationScreen;
