import React from "react";
import {Container} from "reactstrap"

export default class TermsOfService extends React.Component{
  render(){
    return(
      <Container>
      <div>
        <h1>
          Terms of Service
        </h1>
        <p>
          This user agreement will be effective on June 16th, 2020.
        </p>
        <h2>
          Welcome to Kikt!
        </h2>
        <p>
        This user agreement is a contract between you and Kikt LLC, governing your use of your Kikt account and the Kikt services. You must be in the United States and have a U.S. bank account to use the Kikt services. You agree to comply with all of the terms and conditions in this user agreement. The terms include an agreement to resolve disputes on an individual basis. You also agree to comply with the following policies and each of the other agreements posted on kikt.io/legal.
        </p>
        <p>
        This user agreement and any Kikt policy may be revised at any time, and the revised version will be effective as soon as it is posted on our website. If the new changes affect your responsibilities or rights, we will notify you at least 30 days in advance. However, we reserve the right to amend this user agreement at any time without notice under law. Agreeing to use our services after changes have been made effective makes you legally bound by those changes. If you do not agree with changes that have been made to this user agreement, you may close your account at any time.
        </p>
        <h2>
          Opening a Kikt Account
        </h2>
        <p>
        We offer one type of account: a personal account. Each user may only have one personal account. In order to create a personal account, you must be a United States resident, be at least 18 years old or the age of majority in your state of residence, and use a mobile phone that you own. Your Kikt account is for personal use only, unless you have confirmed with Kikt that you are enabling merchant features.
        </p>
        <h2>
          Personal Kikt accounts allow you to make purchases from authorized Kikt merchants.
        </h2>
        <p>
        Kikt verified personal accounts may be used to conduct any sort of business, commercial or merchant transactions with other Kikt personal accounts.
        </p>
        <p>
        You may find out more information about offering Kikt as a payment option as an authorized merchant at https://www.kikt.io/.
        </p>
        <p>
        Your Kikt credentials such as username and access pin must always be kept confidential. You must also keep your email address and other contact information current in your Kikt account profile.
        </p>
        <h2>
          Link or Unlink a Payment Method
        </h2>
        <p>
          You can link or unlink a U.S. bank account to your Kikt account as a payment method. Upon creating your account, you will have to select your bank from a selection of major U.S. banks. After you have selected your bank, Kikt will redirect you to your respective bank’s online portal in order to log into your account. This will then become your default bank account used to make transactions on Kikt.
        </p>
        <h2>
          Bank transfer reviews
        </h2>
        <p>
          We review account and transaction activity at various times. This review checks for, among other things, suspicious or illegal activity, and whether your account activity complies with this agreement. In connection with our review process, you may be required to provide us with additional information and/or documentation to verify your identity. We may limit your account features until verification is completed.
        </p>
        <p>
          Reviews may result in:
        </p>
        <ol>
          <li>
            delayed, blocked or cancelled transactions;
          </li>
          <li>
            money being applied to amounts you owe to us;
          </li>
          <li>
            account limitation, suspension or termination;
          </li>
          <li>
            money or payments being seized to comply with a court order or other legal process; and/or
          </li>
          <li>
            money or payments you previously received being reversed (i.e., sent back to the sender or to the bank account that was used to fund the payment).
          </li>
        </ol>
        <p>
          We may take the above actions if you knowingly or unknowingly took part in a payment that was made from a compromised bank account or compromised Kikt account.
        </p>
        <h2>
          Account Statements
        </h2>
        <p>
          You have the right to receive an account statement showing your Kikt account activity. You may view your Kikt account statement by logging into your Kikt account through the Kikt mobile application.
        </p>
        <h2>
          Fees and limits
        </h2>
        <p>
          We may, at our discretion, impose limits on the amount and/or the number of payments you can send and receive. Non-verified Kikt users will have a transaction limit of $5,000 every week. Verified users will have a limit of $5,000 per transaction. All Kikt Merchants must be verified by Kikt before they are allowed to accept transactions.
        </p>
        <p>
          Fees charged for processing merchant transactions may be found on <a href="https://www.kikt.io/">Kikt</a>.
        </p>
        <p>
          Kikt users do not pay fees to Kikt when they buy something from authorized Kikt merchants.
        </p>
        <p>
          Fees and limits may change over time.
        </p>
        <h2>
          Buying Something from Authorized Merchants
        </h2>
        <p>
          You must have a bank account linked to your Kikt account in order to use Kikt to purchase goods or services from authorized merchants.
        </p>
        <p>
          When you complete a payment, the transaction shall be completed within 3-5 business days. The transaction may appear as pending within your account during this period. In this case, your authorized payment shall remain valid until the merchant has completed the transaction.
        </p>
        <h2>
          Payment Review
        </h2>
        <p>
          Potentially high-risk payments made to authorized merchants are reviewed by us before they can be completed. In this case, a hold shall be placed on the transaction and the merchant shall be notified. If we do not clear the transaction, we will cancel it and return the money to you, unless we are bound legally to do otherwise.
        </p>
        <h2>
          Third-party Payment Provider | ACH Payments
        </h2>
        <p>
          In order to use the payment functionality of Kikt application, you must open a "Dwolla Platform" account provided by Dwolla, Inc. and you must accept the Dwolla <a href="https://www.dwolla.com/legal/tos/"> Terms of Service </a> and <a href="https://www.dwolla.com/legal/privacy/"> Privacy Policy </a> .  Any funds held in the Dwolla account are held by Dwolla's financial institution partners as set out in the Dwolla Terms of Service. You authorize Kikt to collect and share with Dwolla your personal information including full name, date of birth, social security number, physical address, email address and financial information, and you are responsible for the accuracy and completeness of that data. You understand that you will access and manage your Dwolla account through Kikt application, and Dwolla account notifications will be sent by Kikt, not Dwolla.  Kikt will provide customer support for your Dwolla account activity, and can be reached at <a href="https://www.kikt.io">www.kikt.io</a> and <a href="mailto:info@kikt.io">info@kikt.io</a>.
        </p>
        <h2>
          Refunds
        </h2>
        <p>
          In the case of a refunded transaction, the money will be sent back to the bank account linked to your Kikt account.
        </p>
        <h2>
          Bank account transfers
        </h2>
        <p>
          In order to make payments, your bank account must be linked to your Kikt account. Making transactions with Kikt allows us to initiate ACH transfers from your bank account in the amount you authorize.
        </p>
        <p>
          For each transfer we will charge a fee of 1.5%+15¢, only visible to the recipient account.
        </p>
        <p>
          You should confirm that your bank account contains sufficient funds to cover the payment you are making before initiating the transaction. This will help you avoid overdraft fees that your bank may charge.
        </p>
        <h2>
          Processing
        </h2>
        <p>
          Kikt will process your bank account funded transactions through the government sponsored clearing house ACH (Automated Clearing House).
        </p>
        <h2>
          Refunds, Reversals and Chargebacks | Invalidated Payments
        </h2>
        <p>
          Payments that are declined, unauthorized or unfunded may be invalidated by us.
        </p>
        <p>
          If you believe that a transaction that occurred with your Kikt account was not authorized, then you must notify us immediately in addition to disputing the transaction with your bank.
        </p>
        <h2>
          Restricted Activities
        </h2>
        <p>
          As a user of Kikt’s website or services, you must not:
        </p>
        <ol>
          <li>Breach this user agreement;</li>
          <li>Violate any law, statute, ordinance, or regulation;</li>
          <li>
             Infringe our copyright, patent, trademark, trade secret or any intellectual property rights, or rights of publicity or privacy;
          </li>
          <li>
            Create or control more than one personal account for yourself without our authorization, through the use of a name that is not yours, the use a temporary email address or phone number, or the use of any other falsified personal information;
          </li>
          <li>
            Act in a manner that is defamatory, threatening or harassing;
          </li>
          <li>
            Provide false or inaccurate information;
          </li>
          <li>
            Send or receive money that we could reasonably consider fraudulent;
          </li>
          <li>
            Refuse to cooperate in an ongoing investigation or provide confirmation of your identity or information you provide to Kikt;
          </li>
          <li>
            Attempt to receive money from both Kikt and the recipient of a payment in a transaction dispute;
          </li>
          <li>
           Use the Kikt services in a manner that may lead to:
          </li>
          <ol>
            <li>
             Complaints;
             </li>
             <li>
              Claims, disputes, reversals, chargebacks;
             </li>
             <li>
              Fees, fines, penalties or other liability or losses to Kikt, other customers or you;
             </li>
          </ol>
          <li>
            Have any amounts owed to us;
          </li>
          <li>
            Access the Kikt services from outside the U.S.;
          </li>
          <li>
            Take any action intentionally aimed at deteriorating the Kikt servers, software or website;
          </li>
          <li>
            Facilitate viruses, malware or other computer programming maneuvers aimed at damaging, corrupting, interfering with or gaining unauthorized access to the Kikt system, data or information.
          </li>
          <li>
            Use any type of proxy, robot, automatic or manual process to monitor or copy our websites;
          </li>
          <li>
            Disrupt our websites, software or systems;
          </li>
          <li>
            Harass and/or threaten our employees or users.
          </li>
        </ol>
        <h2>
          Actions We May Take if You Engage in Any Restricted Activities
        </h2>
        <p>
          If your behavior contains any of the actions mentioned above, we may take certain measures in order to protect ourselves. These include:
        </p>
        <ol>
          <li>Limiting your Kikt account, closing or suspending your Kikt account;</li>
          <li>Limiting your access to our websites, software and systems;</li>
          <li>Taking legal action against you;</li>
          <li>Holding you responsible for damages caused by your violation of our policies.</li>
        </ol>
        <p>
          If we decide to close your Kikt account for any reason, we’ll notify you of our actions and allow you to retrieve any money that has been sent to you through our services before the account is terminated.
        </p>
        <p>
          You are responsible for all fees, fines, claims, penalties incurred by us, any user or a third party caused by your violation of this agreement.
        </p>
        <h2>
          Holds and Limitations
        </h2>
        <p>
          In order to protect Kikt and our network’s security, Kikt may take account or transaction-level actions.
        </p>
        <p>
          We have no obligation to disclose details about our risk management or security procedures to you.
        </p>
        <h2>
          Holds
        </h2>
          <p>Kikt may place holds on the account level or transaction level. When Kikt places a temporary hold on a transaction, the funds are not available to both parties involved in the transaction. Kikt reviews many factors before placing holds, including transaction activity and account tenure.</p>
          <p>We may place a hold on your transactions if we believe that your account history shows a high level of risk, or to comply with state or federal requirements.</p>
          <p>Holds based on account risk may remain in place for up to 180 days from the transaction date.</p>
        <h2>
          Account Limitations
        </h2>
          <p>
            Limitations are used by Kikt when we notice restricted actions or suspicious activity amongst our user base. Limitations also allow us to collect information needed to decide whether or not to keep an account open.
          </p>
          <p>We may limit your Kikt account for the following reasons:</p>
          <ol>
            <li>If we suspect someone could be using your Kikt account without your knowledge.</li>
            <li>In order to comply with the law.</li>
            <li>If we believe you have violated our user agreement or any other policies.</li>
            <li>If a financial institution notifies us of suspicious activity.</li>
          </ol>
          <p>You must resolve any account issues before a limitation can be removed. We will usually request some information from you in order to resolve the issue. However, if a risk still exists after you have provided additional information, we may take action to protect ourselves and our users from any type of liability.</p>
          <p>If a court order or other legal process affects you, we may be required to take certain actions such as placing holds or limitations on your Kikt account. We have no obligation to appeal any court order or legal process involving you or your Kikt account.</p>
          <h2> Unauthorized transactions  </h2>
          <p>
            In order to keep your account safe, regularly logging into your Kikt account and reviewing transaction activity is encouraged.
          </p>
          <h2>
            Errors
          </h2>
          <p>Errors include the following:</p>
          <ol>
            <li>A transaction is incorrectly recorded within your Kikt account.</li>
            <li>You sent a payment and the incorrect amount is taken from your bank account.</li>
            <li>A transaction is missing or not properly identified in your Kikt account statements.</li>
            <li>We made a computational error related to your Kikt account.</li>
          </ol>
      </div>
      </Container>
    )
  }
}
