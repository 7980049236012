import React from 'react'
import { loadStripe } from '@stripe/stripe-js';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert,
  Badge
} from "reactstrap";
import { ClipLoader } from 'react-spinners';
import DemoNavbar from "../../components/Navbars/DemoNavbar.jsx";
import SimpleFooter from "../../components/Footers/SimpleFooter.jsx";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_PwZzJO5IQrkIJFQ6C9hhMwjF');

// const handleClick = async (event) => {
//     // Call your backend to create the Checkout session.
//     // const { sessionId } = await fetchCheckoutSession();
//     // When the customer clicks on the button, redirect them to Checkout.
//     const stripe = await stripePromise;
//     const sessionId = await fetchSessionId()
    // const { error } = await stripe.redirectToCheckout(
    //   {
    //     sessionId: "cs_test_rslpm3xgPa9r9vtN04LATTrrGXdNcZetEgVg4GtnxUXIZfY24c78pGoA"
    //   }
//     // );
//     // If `redirectToCheckout` fails due to a browser or network
//     // error, display the localized error message to your customer
//     // using `error.message`.
//   };

function onChange(e){
  var pat = /^[0-9]*\.[0-9]{2}$ or ^[0-9]*\.[0-9][0-9]$/
  pat.test(e)
}

export default class TakeMoney extends React.Component {

  state = {
    amount:"",
    loading:false
  }

  async handleDonation(amount,type){
    console.log("Sending Donation")
    this.setState({loading:true})
    const intAmount = parseInt(amount)
    if (intAmount<=0){
      alert("Invalid amount inserted")
      return
    }
    else{
      const sessionId = await this.fetchSessionId(amount,type)
      const stripe = await stripePromise;

      await stripe.redirectToCheckout({
          sessionId: sessionId
        })
    }
    this.setState({loading:false})
    console.log("Finished sending donation")
  }

  async fetchSessionId(amount,type){
    try{
      console.log("Fetching Session Id")
      const url = "https://zs600xh5p2.execute-api.us-east-1.amazonaws.com/Prod/process-stripe"
      const data = {
        amount:amount,
        type:type
      }
      const response = await fetch(url,{
        method: 'POST',
        cache:'no-cache',
        mode:'cors',
        body: JSON.stringify(data)
      })
        // .then(data => {
        //   console.log('Success:', data);
        // })
        // .catch((error) => {
        //   console.error('Error:', error);
        // })
      // console.log(response.body)
      const response_data = await response.json()
      return response_data["id"]
    }
    catch(err){
      console.log(err)
    }
    // return response["id"]
  }

  render(){
      return (
        <>
          <DemoNavbar />
          <main ref="main">
            <section className="section section-shaped section-lg">
              <div className="shape shape-style-1 shape-default">
              </div>
              <div class="d-flex justify-content-center">
                <div class="card" style={{width:350,height:520,alignItems:"center",marginTop:100,marginBottom:100}}>
                <a href="https://www.tbh.org/">
                  <img style={{width:330,height:50,marginTop:35}} src="https://www.tbh.org/sites/default/files/logo_tag_desk.jpg" alt="Brooklyn Hospital Center Logo"/>
                </a>
                  <div class="card-body">
                    <p class="lead text-muted" style={{textAlign:"center"}}>
                      Donate to Brooklyn Hospital Center
                    </p>
                    <Row style={{marginTop:30,alignItems:"center",justifyContent:"center"}}>
                      <button class="btn  btn-info" onClick={()=>{this.setState({amount:"10"})}}>
                        $10
                      </button>
                      <button class="btn  btn-info" onClick={()=>{this.setState({amount:"20"})}}>
                        $20
                      </button>
                      <button class="btn  btn-info" onClick={()=>{this.setState({amount:"50"})}}>
                        $50
                      </button>
                      <button class="btn  btn-info" onClick={()=>{this.setState({amount:"100"})}}>
                        $100
                      </button>
                    </Row>
                    <Row style={{marginTop:30,alignItems:"center",justifyContent:"center"}}>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input type="number" class="form-control" aria-label="Amount (to the nearest dollar)" placeholder="0" onChange={(e)=>this.setState({amount:e.target.value})} value={this.state.amount}/>
                      <div class="input-group-append">
                        <span class="input-group-text">.00</span>
                      </div>
                    </div>
                    </Row>
                    <Col style={{alignItems:"center",justifyContent:"center",marginTop:20}}>
                      {this.state.loading? <Row style={{alignItems:"center",justifyContent:"center"}}><ClipLoader loading={this.state.loading} /> </Row>:
                      <div>
                        <Row style={{alignItems:"center",justifyContent:"center"}}>
                        <button class="btn  btn-success" disabled={this.state.amount===""||this.state.amount==="0"||this.state.amount.substr(0,1)==="-"} onClick={()=>this.handleDonation(this.state.amount,"one-time")}>
                          One-Time Donation
                        </button>
                        </Row>
                        <Row style={{alignItems:"center",justifyContent:"center",marginTop:20}}>
                        <button class="btn  btn-success" disabled={this.state.amount===""||this.state.amount==="0"||this.state.amount.substr(0,1)==="-"} onClick={()=>this.handleDonation(this.state.amount,"monthly")}>
                          Monthly Donation
                        </button>
                        </Row>
                      </div>}
                    </Col>
                  </div>
                </div>
              </div>
            </section>
          </main>
          <SimpleFooter />
        </>
      );
  }
}
