/*!
=========================================================
* Argon Design System React - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
import {Auth} from 'aws-amplify'
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

class DemoNavbar extends React.Component {
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  }
  constructor(props){
    super(props);
  }
  render() {
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
            style={{position: "absolute",
                    top: 0,
                    width: "100%",
                    zIndex: 100,
                    backgroundColor: "transparent",
                    border: 0,
                    boxShadow: "none"
                  }}
          >
            <Container>
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                  <img
                    alt="Kikt Logo"
                    src={require("../../assets/img/icons/WhiteLogo.png")}
                    width="150"
                  />
              </NavbarBrand>
                <Nav className="navbar-nav-hover align-items-lg-center" navbar>

                </Nav>
              <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  <NavItem className="d-lg-block ml-lg-4">
                    {(this.props.status=="verification")?
                      <Button
                        className="btn-neutral btn-icon"
                        color="default"
                        style={{marginTop:20}}
                        onClick={()=>{Auth.signOut();}}
                      >
                        <span className="btn-inner--icon">
                          <i className="fa fa-sign-out" />
                        </span>
                      <span>
                        Sign Out
                      </span>
                      </Button>
                      :
                      <Button
                        className="btn-neutral btn-icon"
                        color="default"
                        style={{marginTop:20}}
                        href="/login"
                      >
                        <span className="btn-inner--icon">
                          <i className="fa fa-sign-in" />
                        </span>
                        <span className="nav-link-inner--text ml-1">
                          Sign In
                        </span>
                      </Button>
                    }
                  </NavItem>
                </Nav>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}
export default DemoNavbar;
