/*!

=========================================================
* Argon Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import emailjs from 'emailjs-com';
import classnames from "classnames";


import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert
} from "reactstrap";

// core components
import { Auth } from 'aws-amplify';
import DemoNavbar from "../../components/Navbars/DemoNavbar.jsx";
import SimpleFooter from "../../components/Footers/SimpleFooter.jsx";
import { ClipLoader } from 'react-spinners';
let email_reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/
class Support extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  state = {
    emailAddress:"",
    notes:""
  };
  sendEmail(){
    this.setState({emailAddress:"",notes:""})
    emailjs.init("user_WmlZXJCgqV6wqD9A6UzyZ")
    emailjs.send('default_service','template_sby1CvH5',{email:this.state.emailAddress,notes:this.state.notes}).then(res=>console.log(res)).catch(err=>console.log(err))
    alert("Email successfully sent")
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 shape-default">
            </div>
            <Container className="pt-lg-md" style={{alignItems:"center",justifyContent:"center",display:"flex",height:800}}>
                <Row className="justify-content-center mt--300">
                  <Col lg="8">
                    <Card className="bg-gradient-secondary shadow">
                      <CardBody className="p-lg-5">
                        <h4 className="mb-1">Support Center</h4>
                        <p className="mt-0">
                          Fill out the form below and send us a message.
                        </p>

                        <FormGroup
                          className={classnames({
                            focused: this.state.emailFocused
                          })}
                        >
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Email address"
                              type="email"
                              name="email"
                              value={this.state.email}
                              onFocus={e => this.setState({ emailFocused: true })}
                              onBlur={e => this.setState({ emailFocused: false })}
                              onChange={e=>this.setState({emailAddress:e.target.value})}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb-4">
                          <Input
                            className="form-control-alternative"
                            cols="80"
                            name="name"
                            placeholder="Type a message..."
                            rows="4"
                            type="textarea"
                            onChange={e=>this.setState({notes:e.target.value})}
                          />
                        </FormGroup>
                        <div>
                          <Button
                            block
                            className="btn-round"
                            color="default"
                            size="lg"
                            type="button"
                            onClick={()=>this.sendEmail()}
                            disabled={(this.state.emailAddress=="")||(this.state.notes=="")||(email_reg.test(this.state.email)===true)}
                          >
                            Send Message
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Support;
