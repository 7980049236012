import React from 'react';
import QRCode from 'qrcode.react';
//
// Create Document Component
let height = 1.4137*width
let width = 800
export default class HowTo extends React.Component{

    render(){
      console.log(this.props.fileLocation)
      return(
        <embed src={this.props.file} style={{minHeight:"100vh",width:"100%"}}/>
      )
    }
}



// ReactPDF.render(<MyDocument />, `${__dirname}/output.pdf`);
