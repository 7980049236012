// eslint-disable
// this is an auto generated file. This will be overwritten

export const onUpdateTransactionMerchant = `subscription OnUpdateTransactionMerchant($subscriptionId: ID!) {
  onUpdateTransactionMerchant(subscriptionId: $subscriptionId) {
    id
    receipt{
      id
      menuItems
      table
      served
      createdAt
    }
    merchantInformation {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    customerInformation {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    subscriptionId
    customerSubscriptionId
    createdAt
    merchantName
    customerName
    tipAmount
    taxAmount
    amount
    rewardInformation
    paymentStatus
    disputes {
      items {
        id
        reason
        createdAt
      }
      nextToken
    }

  }
}
`;
export const onUpdateTransaction = `subscription OnUpdateTransaction($customerSubscriptionId: ID!) {
  onUpdateTransaction(customerSubscriptionId: $customerSubscriptionId) {
    id
    receipt{
      id
      menuItems
    }
    targetAccount {
      id
      mask
      name
      subtype
      type
      institution
      public_token
      stripeAccountId
      balance
      customer {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
        weeklyCustomerAmount
        weeklyEarnings
        covidInfo
      }
      receivedTransactions {
        nextToken
      }
      transactions {
        nextToken
      }
      createdAt
    }
    sourceAccount {
      id
      mask
      name
      subtype
      type
      institution
      public_token
      stripeAccountId
      balance
      customer {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
        weeklyCustomerAmount
        weeklyEarnings
        covidInfo
      }
      receivedTransactions {
        nextToken
      }
      transactions {
        nextToken
      }
      createdAt
    }
    merchantInformation {
      id
      merchantMenus{
        items{
          id
          name
          menuItems{
            items{
              name
              price
            }
          }
        }
      }
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    customerInformation {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    subscriptionId
    customerSubscriptionId
    createdAt
    merchantName
    customerName
    tipAmount
    taxAmount
    amount
    rewardInformation
    paymentStatus
    disputes {
      items {
        id
        reason
        createdAt
      }
      nextToken
    }
  }
}
`;
export const onUpdateCustomer = `subscription OnUpdateCustomer($subscriptionId: ID!) {
  onUpdateCustomer(subscriptionId: $subscriptionId) {
    id
    email
    onBoardingStage
    monthlyExpenses
    pin
    firstName
    lastName
    ipAddress
    type
    dateOfBirth
    ssn
    address1
    city
    state
    postalCode
    businessClassification
    businessType
    businessName
    title
    ein
    beneficialOwnersJson
    controllerJson
    notificationToken
    verificationStatus
    subscriptionId
    selectedAccount
    sourceAccounts {
      items {
        id
        mask
        name
        subtype
        type
        institution
        public_token
        stripeAccountId
        balance
        createdAt
      }
      nextToken
    }
    targetAccounts {
      items {
        id
        mask
        name
        subtype
        type
        institution
        public_token
        stripeAccountId
        balance
        description
        createdAt
      }
      nextToken
    }
    merchantTransactions {
      items {
        id
        subscriptionId
        customerSubscriptionId
        createdAt
        merchantName
        customerName
        tipAmount
        taxAmount
        amount
        rewardInformation
        paymentStatus
      }
      nextToken
    }
    customerTransactions {
      items {
        id
        subscriptionId
        customerSubscriptionId
        createdAt
        merchantName
        customerName
        tipAmount
        taxAmount
        amount
        rewardInformation
        paymentStatus
      }
      nextToken
    }
    availableBalance
    merchandise
    customerPoints
    awaitingPinVerification
    currentTransactionJson
    merchantRewardDetails
    customerRewardsLol {
      items {
        id
        points
        updatedAt
      }
      nextToken
    }
    merchantRewardLinkLol {
      id
      points
      customerLol {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
        weeklyCustomerAmount
        weeklyEarnings
        covidInfo
      }
      merchantLol {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
        weeklyCustomerAmount
        weeklyEarnings
        covidInfo
      }
      updatedAt
    }
    expectingTip
    dwollaBalance
    weeklyExpenses
    dba
    menuKey
    imageType
    merchantEnabled
    weeklyTarget
    weeklyCustomerAmount
    weeklyEarnings
    covidInfo
    recentVisitorObjects {
      items {
        id
        entryTime
        exitTime
        updatedAt
        createdAt
      }
      nextToken
    }
    recentlyVisitedLocations {
      items {
        id
        entryTime
        exitTime
        updatedAt
        createdAt
      }
      nextToken
    }
    customerReceipts {
      items {
        id
        createdAt
        served
        menuItems
      }
      nextToken
    }
    merchantMenus {
      items {
        id
        name
        updatedAt
        createdAt
      }
      nextToken
    }
    merchantReceipts {
      items {
        id
        createdAt
        served
        menuItems
      }
      nextToken
    }
  }
}
`;
export const onCreateLocationObject = `subscription OnCreateLocationObject {
  onCreateLocationObject {
    id
    businessCovidInfo {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    customerCovidInfo {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    entryTime
    exitTime
    updatedAt
    createdAt
  }
}
`;
export const onUpdateLocationObject = `subscription OnUpdateLocationObject {
  onUpdateLocationObject {
    id
    businessCovidInfo {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    customerCovidInfo {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    entryTime
    exitTime
    updatedAt
    createdAt
  }
}
`;
export const onDeleteLocationObject = `subscription OnDeleteLocationObject {
  onDeleteLocationObject {
    id
    businessCovidInfo {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    customerCovidInfo {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    entryTime
    exitTime
    updatedAt
    createdAt
  }
}
`;
export const onCreateSourceAccount = `subscription OnCreateSourceAccount {
  onCreateSourceAccount {
    id
    mask
    name
    subtype
    type
    institution
    public_token
    stripeAccountId
    balance
    customer {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    receivedTransactions {
      items {
        id
        subscriptionId
        customerSubscriptionId
        createdAt
        merchantName
        customerName
        tipAmount
        taxAmount
        amount
        rewardInformation
        paymentStatus
      }
      nextToken
    }
    transactions {
      items {
        id
        subscriptionId
        customerSubscriptionId
        createdAt
        merchantName
        customerName
        tipAmount
        taxAmount
        amount
        rewardInformation
        paymentStatus
      }
      nextToken
    }
    createdAt
  }
}
`;
export const onUpdateSourceAccount = `subscription OnUpdateSourceAccount {
  onUpdateSourceAccount {
    id
    mask
    name
    subtype
    type
    institution
    public_token
    stripeAccountId
    balance
    customer {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    receivedTransactions {
      items {
        id
        subscriptionId
        customerSubscriptionId
        createdAt
        merchantName
        customerName
        tipAmount
        taxAmount
        amount
        rewardInformation
        paymentStatus
      }
      nextToken
    }
    transactions {
      items {
        id
        subscriptionId
        customerSubscriptionId
        createdAt
        merchantName
        customerName
        tipAmount
        taxAmount
        amount
        rewardInformation
        paymentStatus
      }
      nextToken
    }
    createdAt
  }
}
`;
export const onDeleteSourceAccount = `subscription OnDeleteSourceAccount {
  onDeleteSourceAccount {
    id
    mask
    name
    subtype
    type
    institution
    public_token
    stripeAccountId
    balance
    customer {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    receivedTransactions {
      items {
        id
        subscriptionId
        customerSubscriptionId
        createdAt
        merchantName
        customerName
        tipAmount
        taxAmount
        amount
        rewardInformation
        paymentStatus
      }
      nextToken
    }
    transactions {
      items {
        id
        subscriptionId
        customerSubscriptionId
        createdAt
        merchantName
        customerName
        tipAmount
        taxAmount
        amount
        rewardInformation
        paymentStatus
      }
      nextToken
    }
    createdAt
  }
}
`;
export const onCreateTargetAccount = `subscription OnCreateTargetAccount {
  onCreateTargetAccount {
    id
    mask
    name
    subtype
    type
    institution
    public_token
    stripeAccountId
    balance
    customer {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    transactions {
      items {
        id
        subscriptionId
        customerSubscriptionId
        createdAt
        merchantName
        customerName
        tipAmount
        taxAmount
        amount
        rewardInformation
        paymentStatus
      }
      nextToken
    }
    description
    createdAt
  }
}
`;
export const onUpdateTargetAccount = `subscription OnUpdateTargetAccount {
  onUpdateTargetAccount {
    id
    mask
    name
    subtype
    type
    institution
    public_token
    stripeAccountId
    balance
    customer {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    transactions {
      items {
        id
        subscriptionId
        customerSubscriptionId
        createdAt
        merchantName
        customerName
        tipAmount
        taxAmount
        amount
        rewardInformation
        paymentStatus
      }
      nextToken
    }
    description
    createdAt
  }
}
`;
export const onDeleteTargetAccount = `subscription OnDeleteTargetAccount {
  onDeleteTargetAccount {
    id
    mask
    name
    subtype
    type
    institution
    public_token
    stripeAccountId
    balance
    customer {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    transactions {
      items {
        id
        subscriptionId
        customerSubscriptionId
        createdAt
        merchantName
        customerName
        tipAmount
        taxAmount
        amount
        rewardInformation
        paymentStatus
      }
      nextToken
    }
    description
    createdAt
  }
}
`;
export const onCreateMenu = `subscription OnCreateMenu {
  onCreateMenu {
    id
    name
    updatedAt
    createdAt
    menuItems {
      items {
        id
        name
        updatedAt
        createdAt
        weeklyOrders
        amountRemaining
        price
        recentCustomers
      }
      nextToken
    }
    merchant {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
  }
}
`;
export const onUpdateMenu = `subscription OnUpdateMenu {
  onUpdateMenu {
    id
    name
    updatedAt
    createdAt
    menuItems {
      items {
        id
        name
        updatedAt
        createdAt
        weeklyOrders
        amountRemaining
        price
        recentCustomers
      }
      nextToken
    }
    merchant {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
  }
}
`;
export const onDeleteMenu = `subscription OnDeleteMenu {
  onDeleteMenu {
    id
    name
    updatedAt
    createdAt
    menuItems {
      items {
        id
        name
        updatedAt
        createdAt
        weeklyOrders
        amountRemaining
        price
        recentCustomers
      }
      nextToken
    }
    merchant {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
  }
}
`;
export const onCreateMenuItem = `subscription OnCreateMenuItem {
  onCreateMenuItem {
    id
    name
    updatedAt
    createdAt
    parent {
      id
      name
      updatedAt
      createdAt
      menuItems {
        nextToken
      }
      merchant {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
        weeklyCustomerAmount
        weeklyEarnings
        covidInfo
      }
    }
    weeklyOrders
    amountRemaining
    price
    recentCustomers
  }
}
`;
export const onUpdateMenuItem = `subscription OnUpdateMenuItem {
  onUpdateMenuItem {
    id
    name
    updatedAt
    createdAt
    parent {
      id
      name
      updatedAt
      createdAt
      menuItems {
        nextToken
      }
      merchant {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
        weeklyCustomerAmount
        weeklyEarnings
        covidInfo
      }
    }
    weeklyOrders
    amountRemaining
    price
    recentCustomers
  }
}
`;
export const onDeleteMenuItem = `subscription OnDeleteMenuItem {
  onDeleteMenuItem {
    id
    name
    updatedAt
    createdAt
    parent {
      id
      name
      updatedAt
      createdAt
      menuItems {
        nextToken
      }
      merchant {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
        weeklyCustomerAmount
        weeklyEarnings
        covidInfo
      }
    }
    weeklyOrders
    amountRemaining
    price
    recentCustomers
  }
}
`;
export const onCreateReceipt = `subscription OnCreateReceipt {
  onCreateReceipt {
    id
    createdAt
    customer {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    merchant {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    served
    menuItems
  }
}
`;
export const onUpdateReceipt = `subscription OnUpdateReceipt {
  onUpdateReceipt {
    id
    createdAt
    customer {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    merchant {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    served
    menuItems
  }
}
`;
export const onDeleteReceipt = `subscription OnDeleteReceipt {
  onDeleteReceipt {
    id
    createdAt
    customer {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    merchant {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    served
    menuItems
  }
}
`;
