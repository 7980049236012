/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
// import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
// import { Line, Bar } from "react-chartjs-2";
import ReactCardFlip from 'react-card-flip';
import QRCode from 'qrcode.react';
import { Link } from "react-router-dom";
import API, { graphqlOperation } from '@aws-amplify/api';
import {Storage} from 'aws-amplify'
import {createSourceAccount,deleteSourceAccount,updateCustomer,updateTransaction} from '../graphql/mutations';
import {onUpdateTransactionMerchant,onUpdateCustomer} from '../graphql/subscriptions';
import { FilePicker } from 'react-file-picker';
import Joyride from 'react-joyride';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { ClipLoader } from 'react-spinners';
import PlaidLink from 'react-plaid-link';
// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.jsx";
import Header from "components/Headers/Header.jsx";
// const input = {"subscriptionId":this.props.user.id}
// const listener = (user_id) => API.graphql(graphqlOperation(onUpdateTransactionMerchant,{"subscriptionId":user_id}))
let options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute:'numeric' };
const sampleRewards = [{points:25,description:"1 Free Coffee"}]
const insert = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index)
]
function sortedIndex(array,value){
  var low = 0,
		high = array.length;

	while (low < high) {
		var mid = low + high >>> 1;
		if (parseInt(array[mid].points) < parseInt(value.points)) low = mid + 1;
		else high = mid;
	}
	return low;
}
function openInNewTab(url) {
  var win = window.open(url, '_blank');
  win.focus();
}
let dateObj = new Date()
let currentDate = dateObj.getUTCFullYear() + "-" + ("0" + (dateObj.getMonth() + 1)).slice(-2)
let isAlphanumeric = /^[a-z0-9]+$/i
let validChargeReg = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/
let email_reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/
let name_reg = /^[a-zA-Z]{2,30}$/
let password_reg = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{12,}/
let dob_reg = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/
let city_reg = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/
let postalCode_reg = /\d{5}([ \-]\d{4})?/
let state_reg = /\b([A-Z]{2})\b/
let ssn_reg = /^[0-9]{4}$/
let ein_reg = /^[1-9]\d?-\d{7}$/
let country_reg =/^(AF|AX|AL|DZ|AS|AD|AO|AI|AQ|AG|AR|AM|AW|AU|AT|AZ|BS|BH|BD|BB|BY|BE|BZ|BJ|BM|BT|BO|BQ|BA|BW|BV|BR|IO|BN|BG|BF|BI|KH|CM|CA|CV|KY|CF|TD|CL|CN|CX|CC|CO|KM|CG|CD|CK|CR|CI|HR|CU|CW|CY|CZ|DK|DJ|DM|DO|EC|EG|SV|GQ|ER|EE|ET|FK|FO|FJ|FI|FR|GF|PF|TF|GA|GM|GE|DE|GH|GI|GR|GL|GD|GP|GU|GT|GG|GN|GW|GY|HT|HM|VA|HN|HK|HU|IS|IN|ID|IR|IQ|IE|IM|IL|IT|JM|JP|JE|JO|KZ|KE|KI|KP|KR|KW|KG|LA|LV|LB|LS|LR|LY|LI|LT|LU|MO|MK|MG|MW|MY|MV|ML|MT|MH|MQ|MR|MU|YT|MX|FM|MD|MC|MN|ME|MS|MA|MZ|MM|NA|NR|NP|NL|NC|NZ|NI|NE|NG|NU|NF|MP|NO|OM|PK|PW|PS|PA|PG|PY|PE|PH|PN|PL|PT|PR|QA|RE|RO|RU|RW|BL|SH|KN|LC|MF|PM|VC|WS|SM|ST|SA|SN|RS|SC|SL|SG|SX|SK|SI|SB|SO|ZA|GS|SS|ES|LK|SD|SR|SJ|SZ|SE|CH|SY|TW|TJ|TZ|TH|TL|TG|TK|TO|TT|TN|TR|TM|TC|TV|UG|UA|AE|GB|US|UM|UY|UZ|VU|VE|VN|VG|VI|WF|EH|YE|ZM|ZW|AFG|ALB|DZA|ASM|AND|AGO|AIA|ATA|ATG|ARG|ARM|ABW|AUS|AUT|AZE|BHS|BHR|BGD|BRB|BLR|BEL|BLZ|BEN|BMU|BTN|BOL|BIH|BWA|BVT|BRA|IOT|VGB|BRN|BGR|BFA|BDI|KHM|CMR|CAN|CPV|CYM|CAF|TCD|CHL|CHN|CXR|CCK|COL|COM|COD|COG|COK|CRI|CIV|CUB|CYP|CZE|DNK|DJI|DMA|DOM|ECU|EGY|SLV|GNQ|ERI|EST|ETH|FRO|FLK|FJI|FIN|FRA|GUF|PYF|ATF|GAB|GMB|GEO|DEU|GHA|GIB|GRC|GRL|GRD|GLP|GUM|GTM|GIN|GNB|GUY|HTI|HMD|VAT|HND|HKG|HRV|HUN|ISL|IND|IDN|IRN|IRQ|IRL|ISR|ITA|JAM|JPN|JOR|KAZ|KEN|KIR|PRK|KOR|KWT|KGZ|LAO|LVA|LBN|LSO|LBR|LBY|LIE|LTU|LUX|MAC|MKD|MDG|MWI|MYS|MDV|MLI|MLT|MHL|MTQ|MRT|MUS|MYT|MEX|FSM|MDA|MCO|MNG|MSR|MAR|MOZ|MMR|NAM|NRU|NPL|ANT|NLD|NCL|NZL|NIC|NER|NGA|NIU|NFK|MNP|NOR|OMN|PAK|PLW|PSE|PAN|PNG|PRY|PER|PHL|PCN|POL|PRT|PRI|QAT|REU|ROU|RUS|RWA|SHN|KNA|LCA|SPM|VCT|WSM|SMR|STP|SAU|SEN|SCG|SYC|SLE|SGP|SVK|SVN|SLB|SOM|ZAF|SGS|ESP|LKA|SDN|SUR|SJM|SWZ|SWE|CHE|SYR|TWN|TJK|TZA|THA|TLS|TGO|TKL|TON|TTO|TUN|TUR|TKM|TCA|TUV|VIR|UGA|UKR|ARE|GBR|UMI|USA|URY|UZB|VUT|VEN|VNM|WLF|ESH|YEM|ZMB|ZWE)$/
let fullSsnReg = /^(\d{3}-?\d{2}-?\d{4}|XXX-XX-XXXX)$/

class Index extends React.Component {
  constructor(props){
    super(props)
  }
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    modalVisible:false,
    validAccounts:[],
    transactions:[],
    // rewards: JSON.parse(this.props.user.merchantRewardDetails),
    rewards:JSON.parse(this.props.user.merchantRewardDetails),
    points:"",
    description:"",
    expectingTip:this.props.user.expectingTip,
    updatingTip:false,
    editingDba:false,
    address1:this.props.user.address1,
    businessName:this.props.user.businessName,
    city:this.props.user.city,
    state:this.props.user.state,
    postalCode:this.props.user.postalCode,
    dba:this.props.user.dba,
    steps: [
        {target:"#this-bastard",content:"This Feature"}
    ]
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
    // this.manipulateListener("kill")
  }
  componentDidMount(){
    this.props.user.sourceAccounts.items.map((item,_i)=>{
      this.setState({["isFlipped"+_i]:false})
    })
    // const transactions = this.props.user.merchantTransactions.items
    var sentTransactionsShown
    if (this.props.user.merchantEnabled){
      sentTransactionsShown = false
    }
    else{
      sentTransactionsShown = true
    }
    this.setState({loading:false,sentTransactionsShown:sentTransactionsShown})
    // let input = {"subscriptionId":this.props.user.id}
    // const transaction_subscription = API.graphql(graphqlOperation(onUpdateTransactionMerchant,input))
    // this.manipulateListener("activate")
  }
  manipulateListener(command){
    // const my_listener = listener(this.props.user.id)
    // if (command==="activate"){
    //   my_listener.subscribe({next:(data)=>{
    //     console.log("Got Subscription Event Index",data.value.data.onUpdateTransactionMerchant);
    //     this.setState({transactions:[data.value.data.onUpdateTransactionMerchant,...this.state.transactions]});
    //     }
    //   })
    // }
    // else if (command==="kill"){
    //   my_listener.unsubscribe()
    // }
  }
  qrCode(id){
    const canvas = document.getElementById(id);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "QRCode.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
  async linkAccount(token,data){
    try{
      console.log("Linking Account")
      console.log(data)
      let {account,public_token} = data
      account.institution = data.institution.name
      account.public_token = public_token
      const user_id = this.props.user.id
      const user_deets = this.props.user
      console.log("Pushing Account to DynamoDB")
      account["sourceAccountCustomerId"] = user_id
      var input = account
      console.log(input)
      console.log("Merchant Account")
      var new_account = await API.graphql(graphqlOperation(createSourceAccount,{input})).catch(err=>console.log(err))
      console.log("Finished pushing account to DynamoDB")
      input = {id:user_id,onBoardingStage:"FirstTransaction",selectedAccount:new_account.data.createSourceAccount.id}
      await API.graphql(graphqlOperation(updateCustomer,{input})).then(res=>console.log(res)).catch(err=>console.log(err))
      console.log("Finished Linking Account")
      this.props.user.sourceAccounts.items.push(new_account.data.createSourceAccount)
      this.setState({modalVisible:false})
    }
    catch(err){
      console.log(err)
    }
  }
  handleOnExit(){
    console.log("Plaid Exited")
  }
  async handleOnSuccess(token, metadata){
    try{
      console.log("METADATA",metadata)
      await this.removeExistingAccounts(metadata)
      this.setState({name:this.props.user.firstName+" "+this.props.user.lastName,customer_type:this.props.user.type,modalVisible:true})
    }
    catch(err){
      console.log(err)
    }
  }
  async removeExistingAccounts(metadata){
    try{
      const sourceAccounts = this.props.user.sourceAccounts.items
      const validData = []
      console.log("Checking for valid accounts")
      for (var k=0;k<metadata.accounts.length;k++){
        var remove = false
        var temp_passed_account = metadata.accounts[k]
        if (temp_passed_account.type!="depository"){
          remove = true
        }
        for (var i=0;i<sourceAccounts.length;i++){
          var temp_account = sourceAccounts[i]
          if ( (temp_account.type == temp_passed_account.type) && (temp_account.name==temp_passed_account.name) && (temp_account.subtype==temp_passed_account.subtype)&&(temp_account.mask==temp_passed_account.mask)&&(metadata.institution==temp_account.institution)){
            remove = true
          }
        }
        if (!remove){
          temp_passed_account["institution"]=metadata.institution.name
          temp_passed_account["public_token"]=metadata.public_token
          validData.push(temp_passed_account)
        }
      }
      this.setState({validAccounts:validData})
      console.log("Finished checking for valid accounts")
    }
    catch (err){
      console.log(err)
    }
    // if (this.state.passedData.institution == )
    // console.log("SOURCE ACCOUNTS",sourceAccounts)
  }
  async addReward(){
    try{
      console.log("Adding Reward")
      this.setState({addingReward:true})
      const new_reward = {
        points:this.state.points,
        description:this.state.description
      }
      var rewards = []
      if (this.state.rewards != null){
        const index = await sortedIndex(this.state.rewards,new_reward)
        rewards = insert(this.state.rewards,index,new_reward)
        // for (var i=0;(i<this.state.rewards.length);i++){
        //   if (i==0&&new_reward.points<this.state.rewards[0].points){
        //     rewards = insert(this.state.rewards,i,new_reward)
        //   }
        //   else if (new_reward.points>=this.state.rewards[i].points){
        //     // this.state.rewards.splice(i,0,new_reward)
        //     rewards = insert(this.state.rewards,i,new_reward)
        //   }
        // }
      }
      else {
        rewards = [new_reward]
      }
      let input = {id:this.props.user.id,merchantRewardDetails:JSON.stringify(rewards)}
      const response = await API.graphql(graphqlOperation(updateCustomer,{input})).catch(err=>console.log(err))
      console.log(response)
      console.log("Finished Adding Reward")
      this.setState({modalVisible:false,addingReward:false,description:"",points:"",rewards:rewards})
    }
    catch (err){
      console.log(err)
    }
  }
  async removeReward(item,i){
    console.log("Removing reward")
    this.state.rewards.splice(i,1)
    this.setState({rewards:this.state.rewards})
    let input = {id:this.props.user.id,merchantRewardDetails:JSON.stringify(this.state.rewards)}
    API.graphql(graphqlOperation(updateCustomer,{input})).then(res=>console.log(res)).catch(err=>console.log(err))
    console.log("Finished removing Reward")
  }
  _handleKeyDown = (e) =>{
    if (e.key==="Enter"&&this.state.description!=""&&this.state.points!=""){
      this.addReward()
    }
  }
  async cancelTransaction(transaction){
    console.log("Cancelling Transaction")
    let input = {id:transaction.id,paymentStatus:"cancel"}
    await API.graphql(graphqlOperation(updateTransaction,{input})).then(res=>console.log(res)).catch(err=>console.log(err))
    alert("Transaction cancelled, please refresh to see this update")
    this.setState({modalVisible:false})
    console.log("Finished Cancelling Transaction")
  }
  async refundTransaction(transaction){
    console.log("Refunding Transaction")
    let input = {id:transaction.id,paymentStatus:"refund"}
    await API.graphql(graphqlOperation(updateTransaction,{input})).then(res=>console.log(res)).catch(err=>console.log(err))
    alert("Transaction refunded, please refresh to see this update")
    this.setState({modalVisible:false})
    console.log("Finished Refunding Transaction")
  }
  renderModalBody(){
    switch(this.state.modalStage){
      case "loading":
        return(
          <div>
            <ModalHeader toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}>Please Pick an Account</ModalHeader>
            <ModalBody>
              <div style={{alignItems:'center',justifyContent:"center",width:"100%",display:"flex",flexDirection:"column",height:350}}>
                <ClipLoader loading={this.state.modalStage==="loading"}/>
              </div>
            </ModalBody>
          </div>
        )
     case "removeAccount":
        return(
          <div>
            <ModalHeader toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}>Please confirm the removal of this account</ModalHeader>
            <ModalBody>
              <div>
                <p>Are you sure you want to remove this account?</p>
              </div>
            </ModalBody>
            <ModalFooter style={{flexDirection:"column",display:"flex"}}>
              <Button color="primary" onClick={()=>this.removeAccount()}>Yes, Remove Account</Button>
            </ModalFooter>
          </div>
        )
      case "enableBusinessFeatures":
        return(
          <div>
            <ModalHeader toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}>We need some more details to enable business features</ModalHeader>
            <ModalBody>
              <div style={{alignItems:'center',justifyContent:"center",width:"100%",display:"flex",flexDirection:"column"}}>
                {[
                  {field:"Street Address",icon:"",type:"text",dwollaFormat:"address1"},
                  {field:"Business Name",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"businessName"},
                  {field:"City",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"city"},
                  {field:"State (abbreviation i.e. NY)",secureTextEntry:false,autoCapitalize:"characters",dwollaFormat:"state"},
                  {field:"Postal Code",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"postalCode"},
                  {field:"Doing Business As",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"dba"}
                ].map((item,i)=>{
                  return(
                    <FormGroup key={"field"+i.toString()}>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                    {item.field}
                    </label>
                      <InputGroup className="input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className={item.icon} />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder={item.field} type={item.type} onChange={(e)=>this.setState({[item.dwollaFormat]:e.target.value})} value={this.state[item.dwollaFormat]}/>
                      </InputGroup>
                    </FormGroup>
                  )
                })}
              </div>
            </ModalBody>
            <ModalFooter style={{flexDirection:"column",display:"flex"}}>
              <Button color="primary" onClick={()=>this.updateMerchantFeatures("updateUser")}>Enable Business Features</Button>
            </ModalFooter>
          </div>
        )
      case "verifyUser":
        var data=[
            {field:"Date of Birth YYYY-MM-DD",secureTextEntry:false,autoCapitalize:"none",dwollaFormat:"dateOfBirth"},
            {field:"Street Address",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"address1"},
            {field:"City",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"city"},
            {field:"State (abbreviation i.e. NY)",secureTextEntry:false,autoCapitalize:"characters",dwollaFormat:"state"},
            {field:"Postal Code",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"postalCode"},
            {field:"Last 4 SSN",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"ssn"}
        ]
        return(
          <div>
            <ModalHeader toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}>We need some more details to enable business features</ModalHeader>
            <ModalBody>
              <div style={{alignItems:'center',justifyContent:"center",width:"100%",display:"flex",flexDirection:"column"}}>
                {data.map((item,i)=>{
                  return(
                    <FormGroup key={"field"+i.toString()}>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                    {item.field}
                    </label>
                      <InputGroup className="input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className={item.icon} />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder={item.field} type={item.type} onChange={(e)=>this.setState({[item.dwollaFormat]:e.target.value})}/>
                      </InputGroup>
                    </FormGroup>
                  )
                })}
              </div>
            </ModalBody>
            <ModalFooter style={{flexDirection:"column",display:"flex"}}>
              <Button color="primary" onClick={()=>this.verifyUser("personalUser")}>Verify My Account</Button>
            </ModalFooter>
          </div>
        )
        case "retry":
          var data=[
              {field:"First Name",secureTextEntry:false,autoCapitalize:"none",dwollaFormat:"firstName"},
              {field:"Last Name",secureTextEntry:false,autoCapitalize:"none",dwollaFormat:"lastName"},
              {field:"Date of Birth YYYY-MM-DD",secureTextEntry:false,autoCapitalize:"none",dwollaFormat:"dateOfBirth"},
              {field:"Street Address",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"address1"},
              {field:"City",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"city"},
              {field:"State (abbreviation i.e. NY)",secureTextEntry:false,autoCapitalize:"characters",dwollaFormat:"state"},
              {field:"Postal Code",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"postalCode"},
              {field:"Full SSN (XXX-XX-XXXX)",secureTextEntry:false,autoCapitalize:"words",dwollaFormat:"fullSsn"}
          ]
          return(
            <div>
              <ModalHeader toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}>You need to resubmit your information, please make sure everything is accurate before submitting.</ModalHeader>
              <ModalBody>
                <div style={{alignItems:'center',justifyContent:"center",width:"100%",display:"flex",flexDirection:"column"}}>
                  {data.map((item,i)=>{
                    return(
                      <FormGroup key={"field"+i.toString()}>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                      {item.field}
                      </label>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className={item.icon} />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder={item.field} type={item.type} onChange={(e)=>this.setState({[item.dwollaFormat]:e.target.value})}/>
                        </InputGroup>
                      </FormGroup>
                    )
                  })}
                </div>
              </ModalBody>
              <ModalFooter style={{flexDirection:"column",display:"flex"}}>
                <Button color="primary" onClick={()=>this.verifyUser("retry")}>Verify My Account</Button>
              </ModalFooter>
            </div>
          )
      case "transactionCancellation":
        return(
          <div>
            <ModalHeader toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}>Please confirm the cancellation of this transaction</ModalHeader>
            <ModalBody>
              <div>
                <p>Are you sure you want to cancel the transfer of ${parseFloat(this.state.selectedTransaction.amount/100).toFixed(2)} from {this.state.selectedTransaction.customerName}?</p>
              </div>
            </ModalBody>
            <ModalFooter style={{flexDirection:"column",display:"flex"}}>
              <Button color="primary" onClick={()=>this.cancelTransaction(this.state.selectedTransaction)}>Yes, Cancel Transaction</Button>
            </ModalFooter>
          </div>
        )
      case "transactionRefund":
        return(
          <div>
            <ModalHeader toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}>Please confirm the cancellation of this transaction</ModalHeader>
            <ModalBody>
              <div>
                <p>Are you sure you want to refund the transfer of ${parseFloat(this.state.selectedTransaction.amount/100).toFixed(2)} from {this.state.selectedTransaction.customerName}?</p>
              </div>
            </ModalBody>
            <ModalFooter style={{flexDirection:"column",display:"flex"}}>
              <Button color="primary" onClick={()=>this.refundTransaction(this.state.selectedTransaction)}>Yes, Refund Transaction</Button>
            </ModalFooter>
          </div>
        )
      case "addReward":
        return(
          <div>
            <ModalHeader toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}>Please add the reward details below</ModalHeader>
            <ModalBody>
              <div style={{alignItems:'center',justifyContent:"center",width:"100%",display:"flex",flexDirection:"column"}}>
                {[
                  {field:"Dollar Amount",icon:"fas fa-star",type:"number",dwollaFormat:"points"},
                  {field:"Description",icon:"fas fa-info-circle",type:"text",dwollaFormat:"description"}
                ].map((item,i)=>{
                  return(
                    <FormGroup key={i}>
                      <InputGroup className="input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className={item.icon} style={{ color:(this.state[item.dwollaFormat]=="")?"":"#6074DD" }}/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder={item.field}
                          type={item.type}
                          onChange={(e)=>this.setState({[item.dwollaFormat]:e.target.value})}
                          onKeyDown={(e)=>this._handleKeyDown(e)}
                          value={this.state[item.dwollaFormat]}/>
                      </InputGroup>
                    </FormGroup>
                  )
                })}
              </div>
              <div style={{alignItems:"center",justifyContent:"center",display:"flex"}}>
                {(!this.state.addingReward)?<Button style={{width:150,alignItems:"center",display:"flex",justifyContent:"center"}}
                        onClick={()=>this.addReward()}
                        color={(this.state.description!=""&&this.state.points!="")?"primary":"secondary"}
                        disabled={(this.state.description==""||this.state.points=="")}
                        >
                  Submit
                </Button>:
                <div>
                  <ClipLoader
                    loading={this.state.addingReward}
                  />
                </div>
              }
              </div>
            </ModalBody>
          </div>
        )
    }
  }
  validateDataFormats(stage){
    if (dob_reg.test(this.state.dateOfBirth)==false&&(stage==="personalUser")){
      alert("Please enter your date of birth in a valid format, YYYY-MM-DD. Make sure there are no spaces.")
      // this.state.dateOfBirthRef.focus()
      return false
    }
    else if ((this.state.address1 == undefined) || (this.state.address1 == "")){
      alert("Please enter a valid street address")
      // this.state.address1Ref.focus()
      return false
    }
    else if ((city_reg.test(this.state.city)==false)||(this.state.city=="")){
      alert("Please enter a valid city name")
      // this.state.cityRef.focus()
      return false
    }
    else if ((state_reg.test(this.state.state)==false) || (this.state.state == "UNSPECIFIED")){
      alert("Please enter a valid state abbreviation, i.e. NY. Make sure there are no spaces.")
      // this.state.stateRef.focus()
      return false
    }
    else if ((postalCode_reg.test(this.state.postalCode)==false) || (this.state.postalCode == "UNSPECIFIED")){
      alert("Please enter a valid postal code. Make sure there are no spaces.")
      // this.state.postalCodeRef.focus()
      return false
    }
    else  if ((ssn_reg.test(this.state.ssn)==false)&&(stage!=="enableBusinessFeatures")){
      if (stage==="retry"){
        if (fullSsnReg.test(this.state.fullSsn)==false){
          alert("Please enter your full ssn in the format XXX-XX-XXXX. Make sure there are no spaces.")
          // this.state.fullSsnRef.focus()
          return false
        }
        else{
          return true
        }
      }
      else{
        alert("Please enter the last 4 digits of your ssn. Make sure there are no spaces.")
        // this.state.ssnRef.focus()
        return false
      }
    }
    else if (stage==="enableBusinessFeatures"){
      if ((this.state.dba===undefined)||(this.state.dba==="")){
        alert("Please insert the name under which you will be doing business")
        return false
      }
    }
    return true
  }
  async updateTip(expectingTip){
    this.setState({updatingTip:true})
    let input = {
      id: this.props.user.id,
      expectingTip:expectingTip
    }
    await API.graphql(graphqlOperation(updateCustomer,{input})).then(res=>console.log(res)).catch(err=>console.log(err))
    this.setState({updatingTip:false,expectingTip:expectingTip})
  }
  async verifyUser(request){
    let validated = await this.validateDataFormats(request)
    var input
    if (validated){
      input = {
        dateOfBirth:this.state.dateOfBirth,
        address1:this.state.address1,
        city:this.state.city,
        state:this.state.state,
        postalCode:this.state.postalCode,
        ssn:this.state.ssn,
        businessType:this.state.businessType,
        id:this.props.user.id,
        verificationStatus:"verifyCustomer"
      }
    }
    else{
      return
    }
    if (request==="retry"){
      input.firstName = this.state.firstName
      input.lastName = this.state.lastName
      input.ssn = this.state.fullSsn
    }
    const response = await API.graphql(graphqlOperation(updateCustomer,{input})).catch(err=>console.log(err))
    this.props.user.verificationStatus="awaitingVerification"
    this.setState({modalVisible:false})
    // console.log(response)
    alert(
      `Congratulations, you have successfully submitted your information. We will send you an update soon.`
    )
  }
  async updateMerchantFeatures(merchantEnabled){
    if (merchantEnabled==="updateUser"){
      this.setState({updatingMerchantFeatures:true})
      let validated = await this.validateDataFormats("enableBusinessFeatures")
      if (validated){
            let input = {
              id:this.props.user.id,
              address1:this.state.address1,
              city:this.state.city,
              state:this.state.state,
              postalCode:this.state.postalCode,
              dba:this.state.dba,
              businessName:this.state.businessName,
              merchantEnabled:true
            }
            // this.props.user.merchantEnabled = true
            // this.setState({userDeets:this.props.user})
            const response = await API.graphql(graphqlOperation(updateCustomer,{input})).catch(err=>console.log(err))
            console.log(response)
            if ((this.props.user.verificationStatus!=="verified")&&(this.props.user.verificationStatus!=="verifyCustomer")&&(this.props.user.verificationStatus!=="awaitingVerification")){
              // Alert.alert(
              //   'Next Steps',
              //   `Congratulations, you have successfully enabled business features. If you also want to accept payments you will need to verify your account.`,
              //   [{text:'Dismiss',onPress:()=>{
              //     this.setState({modalVisible:false,modalLoading:false})
              //   }},
              //   {text:"Verify",onPress:()=>{
              //     this.setState({modalLoading:false,modalStage:"verifyUser"})
              //   }}
              // ]
              // )
              this.setState({updatingMerchantFeatures:false,modalVisible:false})
              return
            }
            else{
              this.setState({updatingMerchantFeatures:false,modalVisible:false})
              alert(
                `Congratulations, you have successfully enabled business features`
              )
              return
            }
          }
    }
    else{
      let input ={
        id:this.props.user.id,
        merchantEnabled:merchantEnabled
      }
      await API.graphql(graphqlOperation(updateCustomer,{input})).then(res=>console.log(res)).catch(err=>console.log(err))
      this.setState({updatingMerchantFeatures:false})
    }
  }
  enableBusinessFeatures(){
    this.setState({modalVisible:true,modalStage:"enableBusinessFeatures"})
  }
  renderPaymentStatus(paymentStatus,customerPerspective){
  switch(paymentStatus){
      case "pendingApproval":
        return "Pending Approval";
      case "failed":
        return "Failed"
      case "pendingDeposit":
        return (customerPerspective)?"Sent and pending deposit":"Pending deposit into your account";
      case "cancelled":
        return "Cancelled";
      case "refunded":
        return "Refunded";
      case "disputed":
        return "Disputed";
      case "reward":
        return (customerPerspective)?"Redeemed a reward":"Gave a reward";
      case "deposited":
        return (customerPerspective)?"Sent and deposited":"Received and deposited"
      default:
        return "Unavailable"
    }
  }
  copyToClipboard(e) {
    this.textArea.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    this.setState({copied:true})
  }
  async uploadMenu(data){
    try{
      console.log("Uploading Menu")
      this.setState({uploadingMenu:true})
      var file = data.target.files[0]
      var menuName = `${this.props.user.id}_menu.pdf`
      var contentType = "application/pdf"
      const response = await Storage.put(menuName,file,{contentType:contentType})
      let input = {id:this.props.user.id,menuKey:menuName}
      await API.graphql(graphqlOperation(updateCustomer,{input}))
      this.props.user.menuKey = menuName
      this.setState({uploadingMenu:false})
      console.log(response)
      console.log("Finished Uploading Menu")
    }
    catch(err){
      console.log(err)
    }
  }
  editTransaction(item){
    switch(item.paymentStatus){
        case "pendingApproval":
          return;
        case "failed":
          return "Failed"
        case "pendingDeposit":
          var transferredDate = new Date(item.createdAt)
          let today = new Date()
          let transferredDay = transferredDate.getUTCDay()
          let transferredHour = transferredDate.getUTCHours()
          var elapsed_time = today-transferredDate
          var cutOffDate, cutOffDay, cutOffDayOfMonth
          var cutOffTime = 0
          var cutOffHour = 0
          if ([0,1,2,3].includes(transferredDay)){
            console.log("Transfer initialized in first 4 weekdays")
            if (transferredHour<22){
              console.log("Transfer initalized before 4PM")
              cutOffDayOfMonth = transferredDate.getUTCDate()
            }
            else {
              cutOffDayOfMonth = transferredDate.getUTCDate()+1
            }
          }
          else if (transferredDay==4){
            console.log("Transfer initialized on Friday")
            cutOffDayOfMonth = transferredDate.getUTCDate()+3
            console.log(cutOffDate)
          }
          else if (transferredDay==5){
            console.log("Transferred initialized on Saturday")
            cutOffDayOfMonth = transferredDate.getUTCDate()+2
          }
          else if (transferredDay==6){
            console.log("Transferred initialized on Sunday")
            cutOffDayOfMonth = transferredDate.getUTCDate()+1
          }
          cutOffDate = new Date(
            transferredDate.getUTCFullYear(),
            transferredDate.getUTCMonth(),
            cutOffDayOfMonth,
            22
          )
          if (today < cutOffDate){
            console.log("Currently before cutoff date, allowing transaction cancellation")
            this.setState({modalVisible:true,modalStage:"transactionCancellation",selectedTransaction:item})
          }
          else{
            console.log("Too late for cutoff, not allowing cancellation, but allowing refund")
            this.setState({modalVisible:true,modalStage:"transactionRefund",selectedTransaction:item})
          }
          return;
        default:
          alert("This transaction cannot be modified")
          return;
      }
  }
  async removeAccountFromBeingRendered(){
    console.log("Stopping removed account from being rendered")
    console.log(this.props.user.sourceAccounts)
    for (var i=0;i<this.props.user.sourceAccounts.items.length;i++){
      if (this.props.user.sourceAccounts.items[i].id===this.state.selectedAccountId){
        delete this.props.user.sourceAccounts.items[i]
        return
      }
    }
    console.log("Finished stopping removed account from being rendered")
  }
  async removeAccount(){
    this.setState({modalStage:"loading"})
    console.log("Sending Account removal to Graphql")
    let input = {id:this.state.selectedAccountId}
    await API.graphql(graphqlOperation(deleteSourceAccount,{input})).then(res=>console.log("Deleted Account Response",res)).catch(err=>console.log(err))
    console.log("Finished sending delete event to GraphQL")
    this.removeAccountFromBeingRendered()
    this.setState({modalVisible:false})
  }
  removeAccountApproval(selectedAccountId){
    this.setState({modalVisible:true,modalStage:"removeAccount",selectedAccountId:selectedAccountId})
  }
  async updateDba(){
    console.log("Updating DBA")
    if (this.state.newDba!=""){
      let input = {id:this.props.user.id,dba:this.state.newDba}
      this.props.user.dba = this.state.newDba
      await API.graphql(graphqlOperation(updateCustomer,{input})).then(res=>console.log(res)).catch(err=>console.log(err))
    }
    else {
      console.log("Invalid dba, not doing anything")
    }
  }
  async downloadMonthlyStatement(){
    console.log("Downloading monthly statement")
    console.log("Finished downloading monthly statement")
  }
  oneTransaction(item,i){
    if (this.props.user.merchantEnabled&&!this.state.sentTransactionsShown){
      return(
        <tr key={"transaction"+i.toString()}>
        {/*<th scope="row">{item.customerName}</th>*/}
        <th> {(this.props.user.id===item.customerSubscriptionId)?item.merchantName:item.customerName}</th>
        <td>${(parseInt(item.amount)/100).toFixed(2)}</td>
        <td>{this.renderPaymentStatus(item.paymentStatus,(this.props.user.id===item.customerSubscriptionId))} {(item.customerSubscriptionId!==this.props.user.id)&&<i className="fa fa-times" style={{color:"black",marginLeft:3,cursor:"pointer"}} onClick={()=>this.editTransaction(item)}/>}</td>
        <td>${(item.tipAmount!=null)?((parseInt(item.tipAmount)/100).toFixed(2)):(0).toFixed(2)}</td>
        <td>${( (parseInt(item.amount) - (parseInt(item.amount)/1.0875) ) /100 ).toFixed(2)}</td>
        <td>${((parseInt(item.amount)*0.00005)).toFixed(2)}</td>
        <td>{new Date(item.createdAt).toLocaleString('en-US',options)}</td>
        </tr>
      )
    }
    else{
      return(
        <tr key={"transaction"+i.toString()}>
        {/*<th scope="row">{item.customerName}</th>*/}
        <th> {(this.props.user.id===item.customerSubscriptionId)?item.merchantName:item.customerName}</th>
        <td>${(parseInt(item.amount)/100).toFixed(2)}</td>
        <td>{this.renderPaymentStatus(item.paymentStatus,(this.props.user.id===item.customerSubscriptionId))} {(item.customerSubscriptionId!==this.props.user.id)&&<i className="fa fa-times" style={{color:"black",marginLeft:3,cursor:"pointer"}} onClick={()=>this.editTransaction(item)}/>}</td>
        <td>{new Date(item.createdAt).toLocaleString('en-US',options)}</td>
        </tr>
      )
    }
  }
  async downloadMenu(){
    console.log("Downloading Menu")
    this.setState({downloadingMenu:true})
    await Storage.get(this.props.user.menuKey).then(res=>openInNewTab(res)).catch(err=>console.log(err))
    this.setState({downloadingMenu:false})
    console.log("Finished downloading menu")
  }
  render() {
    if (this.state.loading){
      return(
        <div/>
      )
    }
    return (
      <>
        <Header />
        <Modal
          isOpen={this.state.modalVisible}
          toggle={()=>this.setState({modalVisible:!this.state.modalVisible})}
          centered={true}
        >
          {this.renderModalBody()}
        </Modal>
        {/* Page content */}
        <Container className="mt--7" fluid>
          {false&&<Joyride steps={this.state.steps} styles={{
            options: {
              arrowColor: '#e3ffeb',
              backgroundColor: '#e3ffeb',
              overlayColor: 'rgba(79, 26, 0, 0.4)',
              primaryColor: '#000',
              textColor: '#004a14',
              width: 900,
              zIndex: 1000,
            }
          }}/>}
          <QRCode
            style={{position:"absolute",opacity:0}}
            id={"my-qr-code"}
            value={(this.props.user.merchantEnabled)?`https://www.kikt.io/kikt-merchant-id/?id=${this.props.user.id}`:`https://www.kikt.io/kikt-customer-id/?id=${this.props.user.id}`}
            size={400}
            level={"H"}
            includeMargin={true}
          />
          <textarea
            ref={(textArea)=> this.textArea = textArea}
            style={{opacity:0}}
            value={(this.props.user.merchantEnabled)?`https://www.kikt.io/kikt-merchant-id/?id=${this.props.user.id}`:`https://www.kikt.io/kikt-customer-id/?id=${this.props.user.id}`}
          />
          <Row>
          <Col className="mb-5 mb-xl-0" xl="7">
            <Card className="shadow" style={{marginBottom:60}}>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">{(this.state.sentTransactionsShown)?"Sent Transactions":"Received Transactions"}</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={()=>this.setState({sentTransactionsShown:!this.state.sentTransactionsShown})}
                    >
                      {(this.state.sentTransactionsShown)?"Received":"Sent"}
                    </Button>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      to="/admin/transactions"
                      size="sm"
                      tag={Link}
                      id="this-bastard"
                    >
                      See all
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Status</th>
                    {this.props.user.merchantEnabled&&!this.state.sentTransactionsShown&&<th scope="col">Tip</th>}
                    {this.props.user.merchantEnabled&&!this.state.sentTransactionsShown&&<th scope="col">Tax</th>}
                    {this.props.user.merchantEnabled&&!this.state.sentTransactionsShown&&<th scope="col">Fee</th>}
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                <tbody>
                    {
                      this.state.sentTransactionsShown?
                      this.props.user.customerTransactions.items.map((item,i)=>{
                        return this.oneTransaction(item,i)
                      }):
                      this.props.user.merchantTransactions.items.map((item,i)=>{
                        return this.oneTransaction(item,i)
                      })
                    }
                </tbody>
              </Table>
            </Card>
          </Col>
            <Col xl="5">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Accounts
                      </h6>
                      <h3 className="mb-0">Connected Accounts</h3>
                    </div>
                    <div style={{alignItems:"flex-end",marginRight:20}}>
                      <PlaidLink
                        clientName="Kikt"
                        env="production"
                        publicKey="1cb8be529ed06a86412a380e65b8ea"
                        onExit={this.handleOnExit}
                        product={["auth"]}
                        selectAccount={true}
                        onSuccess={(token,data)=>this.linkAccount(token,data)}
                        style={{borderWidth:0,backgroundColor:"white"}}
                      >
                        <div style={{backgroundColor:"#7634E9",borderRadius:25,width:50,height:50, alignItems:'center',justifyContent:'center',marginTop:30,marginRight:15,display:"flex",cursor:"pointer"}}>
                          <i className="fa fa-plus" style={{color:"white"}}/>
                        </div>
                      </PlaidLink>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody style={{alignItems:"center",justifyContent:"center",display:"flex"}}>
                  <div>
                  {
                    this.props.user.sourceAccounts.items.map((item,_i)=>{
                      return(
                        <div style={{marginBottom:20}} key={_i.toString()+"div"}>
                        <QRCode
                          style={{position:"absolute",opacity:0}}
                          id={item.id}
                          value={item.id}
                          size={400}
                          level={"H"}
                          includeMargin={true}
                        />
                        <ReactCardFlip isFlipped={this.state["isFlipped"+_i]} flipDirection="horizontal">
                          <button key="front" style={{width:340,height:180,backgroundColor:"#1F1F1F",borderRadius:15,display:"flex",flexDirection:"row",cursor:"pointer"}} onClick={()=>this.setState({["isFlipped"+_i.toString()]:true})}>
                            <div style={{flex:0.3, justifyContent:"flex-start",alignItems:"center",marginLeft:10}}>
                              <div style={{backgroundColor:"#7634E9",borderRadius:25,width:50,height:50, alignItems:'center',justifyContent:'center',marginTop:30,display:"flex"}}>
                                <i className="fa fa-credit-card" style={{color:"white"}}/>
                              </div>
                              <i className="fa fa-info-circle" style={{color:"#F6F6F6",fontSize:15,marginTop:27,marginLeft:60}}/>
                            </div>
                            <div style={{flex:0.7,justifyContent:"flex-start",display:"flex",flexDirection:"column",marginTop:15}}>
                              <div style={{justifyContent:"flex-start",alignItems:"center",display:"flex",flexDirection:"row"}}>
                                <p style={{color:"#888888",marginTop:5,fontFamily:"RobotoMedium",fontSize:15,fontWeight:"400"}}>
                                  {item.institution.toUpperCase()}
                                </p>
                              </div>
                              <div style={{justifyContent:"flex-start",alignItems:"center",display:"flex",flexDirection:"row"}}>
                                <p style={{color:"#888888",fontFamily:"RobotoMedium",fontSize:15,fontWeight:"400"}}>
                                  {this.props.user.firstName.toUpperCase()+" "+this.props.user.lastName.toUpperCase()}
                                </p>
                              </div>
                                <div style={{justifyContent:"flex-start",alignItems:"center",display:"flex",flexDirection:"row"}}>
                                    <p style={{color:"#888888",fontFamily:"RobotoMedium",fontSize:15,fontWeight:"400"}}>
                                      {item.name.toUpperCase()}
                                    </p>
                                </div>
                            </div>
                          </button>
                          <div key="back" style={{width:340,height:180,backgroundColor:"#1F1F1F",borderRadius:15,flexDirection:"column",display:"flex"}} onClick={()=>this.setState({["isFlipped"+_i.toString()]:false})}>
                            <div style={{height:55,width:340}}/>
                              <div style={{}}>
                                {/*<div style={{width:320,height:75,marginLeft:40,display:"flex",justifyContent:"flex-end",flexDirection:"column"}}>
                                  <p style={{color:"#888888",fontSize:15,fontWeight:"600",cursor:"pointer"}} onClick={(e)=>{e.stopPropagation();this.qrCode(item.id)}}>
                                    DOWNLOAD QR CODE
                                  </p>
                                </div>*/}
                                <div style={{width:320,height:75,marginLeft:40,display:"flex",justifyContent:"flex-start",flexDirection:"column"}}>
                                  <p style={{color:"#888888",fontSize:15,fontWeight:"600",cursor:"pointer",}} onClick={(e)=>{e.stopPropagation();this.removeAccountApproval(item.id)}}>
                                    REMOVE ACCOUNT
                                  </p>
                                </div>
                            </div>
                          </div>
                        </ReactCardFlip>
                        </div>
                      )
                    })
                  }
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
          <Col className="mb-5 mb-xl-0">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row>
              <CardBody>
                <div class="col-auto col" style={{flexDirection:"row",justifyContent:"space-between",alignItems:"flex-end",display:"flex"}}>
                  <span style={{color:"black"}}><h3>Merchant Features</h3></span>
                  {
                    this.props.user.merchantEnabled?
                    <span class="badge-lg badge badge-success" style={{backgroundColor:"#b0eed3",cursor:"pointer"}} onClick={()=>this.updateMerchantFeatures(false)} disabled={this.state.updatingMerchantFeatures}>{(this.state.updatingMerchantFeatures)?<ClipLoader size={15} loading={this.state.updatingMerchantFeatures}/>:"Active"}</span>
                      :
                    <span class="badge-lg badge badge-success" style={{backgroundColor:"#172b4d",cursor:"pointer",color:"white"}} onClick={()=>this.enableBusinessFeatures()} disabled={this.state.updatingMerchantFeatures}>{(this.state.updatingMerchantFeatures)?<ClipLoader size={15} loading={this.state.updatingMerchantFeatures}/>:"Disabled"}</span>
                  }
                </div>
              </CardBody>
              </Row>
              </CardHeader>
            </Card>
          </Col>
          <Col className="mb-5 mb-xl-0">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row>
              <CardBody>
                <div class="col-auto col" style={{flexDirection:"row",justifyContent:"space-between",alignItems:"flex-end",display:"flex"}}>
                  <span style={{color:"black"}}><h3>Verification Status</h3></span>
                  {
                    (this.props.user.verificationStatus==="unverified")?
                    <span class="badge-lg badge badge-success" style={{backgroundColor:"#172b4d",cursor:"pointer",color:"white"}} onClick={()=>this.setState({modalVisible:true,modalStage:"verifyUser"})} disabled={this.state.updatingMerchantFeatures}>{(this.state.updatingMerchantFeatures)?<ClipLoader size={15} loading={this.state.updatingMerchantFeatures}/>:"Verify"}</span>
                    :
                      (this.props.user.verificationStatus==="verified")?
                      <span class="badge-lg badge badge-success" style={{backgroundColor:"#b0eed3"}} disabled={true}>Verified</span>
                      :
                        (this.props.user.verificationStatus==="suspended")?
                        <span class="badge-lg badge badge-success" style={{backgroundColor:"#172b4d",color:"white"}} disabled={true}>Suspended</span>
                        :
                          ((this.props.user.verificationStatus==="awaitingVerification")||(this.props.user.verificationStatus==="verifyCustomer"))?
                          <span class="badge-lg badge badge-success" style={{backgroundColor:"#172b4d",color:"white"}} disabled={true}>Awaiting Verification</span>
                          :
                            (this.props.user.verificationStatus==="retry")?
                            <span class="badge-lg badge badge-success" style={{backgroundColor:"#172b4d",color:"white",cursor:"pointer"}} onClick={()=>this.setState({modalVisible:true,modalStage:"retry"})}>Retry</span>
                            :
                            <span class="badge-lg badge badge-success" style={{backgroundColor:"#172b4d",color:"white"}} disabled={true}>Disabled</span>
                  }
                </div>
              </CardBody>
              </Row>
              </CardHeader>
            </Card>
          </Col>
          {this.props.merchantEnabled&&<Col className="mb-5 mb-xl-0" xl="7">
            <Card className="shadow" style={{marginBottom:60}}>
              <CardHeader className="border-0">
                <Row>
                <div className="col" style={{display:"flex",justifyContent:"flex-start",alignItems:'center'}}>
                    <h3 className="mb-0">Rewards</h3>
                </div>
                <div className="col" style={{display:"flex",justifyContent:"flex-end",alignItems:'center'}}>
                  <div
                    style={{backgroundColor:"#7634E9",borderRadius:25,width:50,height:50, alignItems:'center',justifyContent:'center',marginRight:15,display:"flex",cursor:"pointer"}}
                    onClick={()=>this.setState({modalVisible:true,modalStage:"addReward"})}
                  >
                    <i className="fa fa-plus" style={{color:"white"}}/>
                  </div>
                </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Amount</th>
                    <th scope="col">Description</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                    {
                      this.state.rewards!=null&&this.state.rewards.map((item,i)=>{
                        return(
                          <tr key={"rewards"+i.toString()}>
                            <th scope="row">{item.points}</th>
                            <td>{item.description}</td>
                            <div style={{justifyContent:"center",display:"flex",marginTop:7}}>
                              <div
                                style={{backgroundColor:"#7634E9",borderRadius:15,width:30,height:30, alignItems:'center',justifyContent:'center',display:"flex",cursor:"pointer"}}
                                onClick={()=>this.removeReward(item,i)}
                              >
                                <i className="fa fa-minus" style={{color:"white"}}/>
                              </div>
                            </div>
                          </tr>
                        )
                      })
                    }
                </tbody>
              </Table>
            </Card>
          </Col>}
          {this.props.user.merchantEnabled&&(false)&&<Col className="mb-5 mb-xl-0">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row>
              <CardBody>
                <div class="col-auto col" style={{flexDirection:"row",justifyContent:"space-between",alignItems:"flex-end",display:"flex"}}>
                  <span style={{color:"black"}}><h3>Menu</h3></span>
                  {
                    (this.props.user.menuKey!=null)?
                    <div style={{flexDirection:'column',display:"flex"}}>
                      <span class="badge-lg badge badge-success" style={{backgroundColor:"#b0eed3",marginBottom:5,cursor:"pointer"}} onClick={()=>this.downloadMenu()}>{(this.state.dowloadingMenu)?<ClipLoader size={15} loading={this.state.downloadingMenu}/>:"Open"}</span>
                      <div>
                        <input type="file" style={{opacity:0,position:"absolute",width:92,cursor:"pointer"}} onChange={(data)=>this.uploadMenu(data)} accept="application/pdf"/>
                        <span class="badge-lg badge badge-success" style={{backgroundColor:"#172b4d",color:"white",marginTop:5,width:92}} disabled={this.state.updatingTip}>{(this.state.uploadingMenu)?<ClipLoader size={15} loading={this.state.uploadingMenu}/>:"Replace"}</span>
                      </div>
                    </div>
                      :
                      <div style={{cursor:"pointer"}}>
                        <input type="file" style={{opacity:0,position:"absolute",width:75,cursor:"pointer"}} onChange={(data)=>this.uploadMenu(data)} accept="application/pdf"/>
                        <span class="badge-lg badge badge-success" style={{backgroundColor:"#172b4d",color:"white",cursor:"pointer"}} disabled={this.state.updatingMenu}>{(this.state.uploadingMenu)?<ClipLoader size={15} loading={this.state.uploadingMenu}/>:"Upload"}</span>
                      </div>
                  }
                </div>
              </CardBody>
              </Row>
              </CardHeader>
            </Card>
          </Col>}
          {this.props.user.merchantEnabled&&<Col className="mb-5 mb-xl-0">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row>
              <CardBody>
                <div class="col-auto col" style={{flexDirection:"row",justifyContent:"space-between",alignItems:"flex-end",display:"flex"}}>
                  <span style={{color:"black"}}><h3>Tipping</h3></span>
                  {
                    this.state.expectingTip?
                    <span class="badge-lg badge badge-success" style={{backgroundColor:"#b0eed3",cursor:"pointer"}} onClick={()=>this.updateTip(false)} disabled={this.state.updatingTip}>{(this.state.updatingTip)?<ClipLoader size={15} loading={this.state.updatingTip}/>:"Active"}</span>
                      :
                    <span class="badge-lg badge badge-success" style={{backgroundColor:"#172b4d",cursor:"pointer",color:"white"}} onClick={()=>this.updateTip(true)} disabled={this.state.updatingTip}>{(this.state.updatingTip)?<ClipLoader size={15} loading={this.state.updatingTip}/>:"Disabled"}</span>
                  }
                </div>
              </CardBody>
              </Row>
              </CardHeader>
            </Card>
          </Col>}
          </Row>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="3">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <CardBody>
                      <div class="col-auto col" style={{flexDirection:"column",justifyContent:"space-between",alignItems:"flex-start",display:"flex"}}>
                        <span style={{color:"black",display:"flex",flexDirection:"row"}}><h3>Kikt Balance</h3><i style={{cursor:"pointer"}} className="fas fa-info-circle" onClick={()=>alert("In the rare case that you remove a bank account before we have the chance to deposit your funds, your remaining balance will be shown here. This balance will automatically be transferred once you add a new account.")}/></span>
                        <span><h4 class="text-success">{this.props.user.dwollaBalance}</h4></span>
                      </div>
                    </CardBody>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
            <Col className="mb-5 mb-xl-0" xl="3">
            <Card className="shadow">
              <CardHeader className="border-0">
                  <Row>
                    <CardBody>
                      <div class="col-auto col" style={{flexDirection:"column",justifyContent:"space-between",alignItems:"flex-start",display:"flex"}}>
                        <span style={{color:"black",display:"flex",flexDirection:"row"}}><h3>{(this.props.user.merchantEnabled)?"Monthly Earnings":"Monthly Expenses"}</h3><i style={{cursor:"pointer"}} className="fas fa-info-circle" onClick={()=>alert("The sum of all received transactions is shown below.")}/></span>
                        <span><h4 class="text-success">${(this.props.user.merchantEnabled)?((JSON.parse(this.props.user.weeklyEarnings)[currentDate]===undefined)?"0.00":(JSON.parse(this.props.user.weeklyEarnings)[currentDate]/100).toFixed(2)):(JSON.parse(this.props.user.monthlyExpenses)[currentDate]===undefined)?"0.00":(JSON.parse(this.props.user.monthlyExpenses)[currentDate]/100).toFixed(2)}</h4></span>
                      </div>
                    </CardBody>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
            <Col className="mb-5 mb-xl-0" xl="3">
            <Card className="shadow">
              <CardHeader className="border-0">
                  <Row>
                    <CardBody>
                      <div class="col-auto col" style={{flexDirection:"column",justifyContent:"space-between",alignItems:"flex-start",display:"flex"}}>
                        <span style={{color:"black",display:"flex",flexDirection:"row"}}><h3>QR Code</h3><i style={{cursor:"pointer"}} className="fas fa-info-circle" onClick={()=>alert("By scanning your QR code or opening your link people can send you money")}/></span>
                        <div style={{display:"flex",flexDirection:"row"}}>
                          <span class="badge-lg badge badge-success" style={{backgroundColor:"#172b4d",cursor:"pointer",color:"white"}} onClick={()=>this.qrCode("my-qr-code")}>{"Download"}</span>
                          <span class="badge-lg badge badge-success" style={{backgroundColor:"#172b4d",cursor:"pointer",color:"white",marginLeft:5}} onClick={()=>this.copyToClipboard()}>{(this.state.copied)?"Copied":"Copy Link"}</span>
                        </div>
                      </div>
                    </CardBody>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
            {this.props.user.merchantEnabled&&<Col className="mb-5 mb-xl-0" xl="5">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <CardBody>
                      <div class="col-auto col" style={{flexDirection:"column",justifyContent:"space-between",alignItems:"flex-start",display:"flex"}}>
                        <span style={{color:"black",display:"flex",flexDirection:"row"}}><h3>{(true)?"Doing Business As":"Campaign Title"}</h3><i className="fas fa-info-circle" onClick={()=>alert("This is the title which will be rendered when people scan your QR code.")} style={{cursor:"pointer"}}/></span>
                        <span>
                          {(!this.state.editingDba)?
                            <span style={{display:"flex",flexDirection:"row"}}>
                              <h4 class="text-success">{this.props.user.dba}</h4>
                              <i className="fas fa-edit" style={{marginTop:10,marginLeft:10,cursor:"pointer"}} onClick={()=>this.setState({editingDba:!this.state.editingDba,newDba:this.props.user.dba})}/>
                            </span>
                            :
                            <span style={{display:"flex",flexDirection:"row"}}>
                              <Input class="text-success" value={this.state.newDba} style={{width:250}} onChange={(e)=>this.setState({newDba:e.target.value})}></Input>
                              <i className="fas fa-times" style={{marginTop:10,marginLeft:10,cursor:"pointer"}} onClick={()=>this.setState({editingDba:!this.state.editingDba,newDba:this.props.user.dba})}/>
                              <i className="fas fa-check" style={{marginTop:10,marginLeft:20,cursor:"pointer"}} onClick={()=>{this.setState({editingDba:!this.state.editingDba,newDba:this.props.user.dba});this.updateDba()}}/>
                            </span>
                          }
                        </span>
                      </div>
                    </CardBody>
                  </Row>
                </CardHeader>
              </Card>
            </Col>}
            {(false)&&<Col className="mb-5 mb-xl-0" xl="5">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <CardBody>
                      <div class="col-auto col" style={{flexDirection:"column",justifyContent:"space-between",alignItems:"flex-start",display:"flex"}}>
                        <span style={{color:"black",display:"flex",flexDirection:"row"}}><h3>Monthly Statement</h3><i className="fas fa-info-circle" onClick={()=>alert("A PDF summarizing the activity from the past week")} style={{cursor:"pointer"}}/></span>
                        {/*<span>
                          {(!this.state.editingDba)?
                            <span style={{display:"flex",flexDirection:"row"}}>
                              <h4 class="text-success">{this.props.user.dba}</h4>
                              <i className="fas fa-edit" style={{marginTop:10,marginLeft:10,cursor:"pointer"}} onClick={()=>this.setState({editingDba:!this.state.editingDba,newDba:this.props.user.dba})}/>
                            </span>
                            :
                            <span style={{display:"flex",flexDirection:"row"}}>
                              <Input class="text-success" value={this.state.newDba} style={{width:250}} onChange={(e)=>this.setState({newDba:e.target.value})}></Input>
                              <i className="fas fa-times" style={{marginTop:10,marginLeft:10,cursor:"pointer"}} onClick={()=>this.setState({editingDba:!this.state.editingDba,newDba:this.props.user.dba})}/>
                              <i className="fas fa-check" style={{marginTop:10,marginLeft:20,cursor:"pointer"}} onClick={()=>{this.setState({editingDba:!this.state.editingDba,newDba:this.props.user.dba});this.updateDba()}}/>
                            </span>
                          }
                        </span>*/}
                        <span class="badge-lg badge badge-success" style={{backgroundColor:"#172b4d",cursor:"pointer",color:"white",marginLeft:5}} onClick={()=>this.downloadMonthlyStatement()}>{(this.state.downloadingMonthlyStatement)?<ClipLoader loading={this.state.addingReward}/>:"Download"}</span>
                      </div>
                    </CardBody>
                  </Row>
                </CardHeader>
              </Card>
            </Col>}
          </Row>
        </Container>
      </>
    );
  }
}

export default Index;
