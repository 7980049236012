/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      covidInfo
      merchantReceipts(sortDirection:DESC,limit:30){
        items{
          id
          createdAt
          served
          menuItems
          table
          customer{
            id
            firstName
            lastName
          }
          merchant{
            id
            firstName
            lastName
          }
          attachedTransaction{
            id
            paymentStatus
          }
        }
      }
      merchantMenus(sortDirection:DESC,limit:30) {
        items{
          id
          name
          menuItems(sortDirection:DESC,limit:30){
            items{
              id
              name
              price
              weeklyOrders
              amountRemaining
              recentCustomers
              options
              description
            }
          }
        }
      }
      recentVisitorObjects{
        items{
          id
          businessCovidInfo{
            id
          }
        }
      }
      weeklyCustomerAmount
      weeklyEarnings
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts(sortDirection:DESC) {
        items {
          id
          mask
          name
          subtype
          type
          institution
          public_token
          stripeAccountId
          balance
          createdAt
        }
        nextToken
      }
      targetAccounts(sortDirection:DESC) {
        items {
          id
          mask
          name
          subtype
          type
          institution
          public_token
          stripeAccountId
          balance
          description
          createdAt
        }
        nextToken
      }
      merchantTransactions(sortDirection:DESC) {
        items {
          id
          subscriptionId
          customerSubscriptionId
          createdAt
          merchantName
          customerName
          tipAmount
          taxAmount
          amount
          rewardInformation
          paymentStatus
          receipt{
            id
            menuItems
            served
            createdAt
          }
          merchantInformation{
            id
            menuKey
          }
          customerInformation{
            id
            firstName
            lastName
            dba
            merchantEnabled
            selectedAccount
          }
        }
        nextToken
      }
      customerTransactions(sortDirection:DESC) {
        items {
          id
          receipt{
            id
            menuItems
          }
          subscriptionId
          customerSubscriptionId
          createdAt
          merchantName
          customerName
          tipAmount
          taxAmount
          amount
          rewardInformation
          paymentStatus
          merchantInformation{
            id
            menuKey
            merchantEnabled
            selectedAccount
            dba
            firstName
            lastName
            merchantRewardDetails
            merchantMenus(sortDirection:DESC,limit:30){
              items{
                id
                name
                menuItems(sortDirection:DESC,limit:30){
                  items{
                    id
                    name
                    price
                    weeklyOrders
                    amountRemaining
                    recentCustomers
                  }
                }
              }
            }
            expectingTip
          }
          customerInformation{
            id
            firstName
            lastName
            dba
            selectedAccount
            merchantEnabled
          }
        }
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol(sortDirection:DESC) {
        items {
          id
          points
          merchantLol{
            id
            merchantRewardDetails
            dba
            menuKey
          }
          updatedAt
        }
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        customerLol {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        merchantLol {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
      }
      nextToken
    }
  }
`;
export const getSourceAccount = /* GraphQL */ `
  query GetSourceAccount($id: ID!) {
    getSourceAccount(id: $id) {
      id
      mask
      name
      subtype
      type
      institution
      public_token
      stripeAccountId
      balance
      customer {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
      }
      receivedTransactions {
        items {
          id
          subscriptionId
          customerSubscriptionId
          createdAt
          merchantName
          customerName
          tipAmount
          taxAmount
          amount
          rewardInformation
          paymentStatus
        }
        nextToken
      }
      transactions {
        items {
          id
          subscriptionId
          customerSubscriptionId
          createdAt
          merchantName
          customerName
          tipAmount
          taxAmount
          amount
          rewardInformation
          paymentStatus
        }
        nextToken
      }
      createdAt
    }
  }
`;
export const listSourceAccounts = /* GraphQL */ `
  query ListSourceAccounts(
    $filter: ModelSourceAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSourceAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mask
        name
        subtype
        type
        institution
        public_token
        stripeAccountId
        balance
        customer {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        receivedTransactions {
          nextToken
        }
        transactions {
          nextToken
        }
        createdAt
      }
      nextToken
    }
  }
`;
export const getTargetAccount = /* GraphQL */ `
  query GetTargetAccount($id: ID!) {
    getTargetAccount(id: $id) {
      id
      mask
      name
      subtype
      type
      institution
      public_token
      stripeAccountId
      balance
      customer {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
      }
      transactions {
        items {
          id
          subscriptionId
          customerSubscriptionId
          createdAt
          merchantName
          customerName
          tipAmount
          taxAmount
          amount
          rewardInformation
          paymentStatus
        }
        nextToken
      }
      description
      createdAt
    }
  }
`;
export const listTargetAccounts = /* GraphQL */ `
  query ListTargetAccounts(
    $filter: ModelTargetAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTargetAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mask
        name
        subtype
        type
        institution
        public_token
        stripeAccountId
        balance
        customer {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        transactions {
          nextToken
        }
        description
        createdAt
      }
      nextToken
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      targetAccount {
        id
        mask
        name
        subtype
        type
        institution
        public_token
        stripeAccountId
        balance
        customer {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        receivedTransactions {
          nextToken
        }
        transactions {
          nextToken
        }
        createdAt
      }
      sourceAccount {
        id
        mask
        name
        subtype
        type
        institution
        public_token
        stripeAccountId
        balance
        customer {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        receivedTransactions {
          nextToken
        }
        transactions {
          nextToken
        }
        createdAt
      }
      merchantInformation {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
      }
      customerInformation {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
      }
      subscriptionId
      customerSubscriptionId
      createdAt
      merchantName
      customerName
      tipAmount
      taxAmount
      amount
      rewardInformation
      paymentStatus
      disputes {
        items {
          id
          reason
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        targetAccount {
          id
          mask
          name
          subtype
          type
          institution
          public_token
          stripeAccountId
          balance
          createdAt
        }
        sourceAccount {
          id
          mask
          name
          subtype
          type
          institution
          public_token
          stripeAccountId
          balance
          createdAt
        }
        merchantInformation {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        customerInformation {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        subscriptionId
        customerSubscriptionId
        createdAt
        merchantName
        customerName
        tipAmount
        taxAmount
        amount
        rewardInformation
        paymentStatus
        disputes {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getRewardLol = /* GraphQL */ `
  query GetRewardLol($id: ID!) {
    getRewardLol(id: $id) {
      id
      points
      customerLol {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
      }
      merchantLol {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        sourceAccounts {
          nextToken
        }
        targetAccounts {
          nextToken
        }
        merchantTransactions {
          nextToken
        }
        customerTransactions {
          nextToken
        }
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        customerRewardsLol {
          nextToken
        }
        merchantRewardLinkLol {
          id
          points
          updatedAt
        }
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
      }
      updatedAt
    }
  }
`;
export const listRewardLols = /* GraphQL */ `
  query ListRewardLols(
    $filter: ModelRewardLolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRewardLols(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        points
        customerLol {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        merchantLol {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDispute = /* GraphQL */ `
  query GetDispute($id: ID!) {
    getDispute(id: $id) {
      id
      parentTransaction {
        id
        targetAccount {
          id
          mask
          name
          subtype
          type
          institution
          public_token
          stripeAccountId
          balance
          createdAt
        }
        sourceAccount {
          id
          mask
          name
          subtype
          type
          institution
          public_token
          stripeAccountId
          balance
          createdAt
        }
        merchantInformation {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        customerInformation {
          id
          email
          onBoardingStage
          monthlyExpenses
          pin
          firstName
          lastName
          ipAddress
          type
          dateOfBirth
          ssn
          address1
          city
          state
          postalCode
          businessClassification
          businessType
          businessName
          title
          ein
          beneficialOwnersJson
          controllerJson
          notificationToken
          verificationStatus
          subscriptionId
          selectedAccount
          availableBalance
          merchandise
          customerPoints
          awaitingPinVerification
          currentTransactionJson
          merchantRewardDetails
          expectingTip
          dwollaBalance
          weeklyExpenses
          dba
          menuKey
          imageType
          merchantEnabled
          weeklyTarget
        }
        subscriptionId
        customerSubscriptionId
        createdAt
        merchantName
        customerName
        tipAmount
        taxAmount
        amount
        rewardInformation
        paymentStatus
        disputes {
          nextToken
        }
      }
      reason
      createdAt
    }
  }
`;
export const listDisputes = /* GraphQL */ `
  query ListDisputes(
    $filter: ModelDisputeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDisputes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        parentTransaction {
          id
          subscriptionId
          customerSubscriptionId
          createdAt
          merchantName
          customerName
          tipAmount
          taxAmount
          amount
          rewardInformation
          paymentStatus
        }
        reason
        createdAt
      }
      nextToken
    }
  }
`;
export const getMoreCustomerTransactions = `query GetCustomer($id: ID!,$nextToken:String) {
  getCustomer(id: $id) {
		customerTransactions(nextToken:$nextToken,sortDirection:DESC,limit:100){
      items{
        id
        amount
        customerName
        merchantName
        paymentStatus
        merchantInformation{
          firstName
          lastName
          dba
          id
        }
        customerInformation{
          firstName
          lastName
          dba
          id
        }
        createdAt
      }
      nextToken
    }
  }
}
`;
export const getMenu = `query GetMenu($id: ID!) {
  getMenu(id: $id) {
    id
    name
    updatedAt
    createdAt
    menuItems {
      items {
        id
        name
        updatedAt
        createdAt
        weeklyOrders
        amountRemaining
        recentCustomers
      }
      nextToken
    }
    merchant {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
  }
}
`;
export const listMenus = `query ListMenus(
  $filter: ModelMenuFilterInput
  $limit: Int
  $nextToken: String
) {
  listMenus(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      updatedAt
      createdAt
      menuItems {
        nextToken
      }
      merchant {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
        weeklyCustomerAmount
        weeklyEarnings
        covidInfo
      }
    }
    nextToken
  }
}
`;
export const getMenuItem = `query GetMenuItem($id: ID!) {
  getMenuItem(id: $id) {
    id
    name
    updatedAt
    createdAt
    parent {
      id
      name
      updatedAt
      createdAt
      menuItems {
        nextToken
      }
      merchant {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
        weeklyCustomerAmount
        weeklyEarnings
        covidInfo
      }
    }
    weeklyOrders
    amountRemaining
    recentCustomers
  }
}
`;
export const listMenuItems = `query ListMenuItems(
  $filter: ModelMenuItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listMenuItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      updatedAt
      createdAt
      parent {
        id
        name
        updatedAt
        createdAt
      }
      weeklyOrders
      amountRemaining
      recentCustomers
    }
    nextToken
  }
}
`;
export const getReceipt = `query GetReceipt($id: ID!) {
  getReceipt(id: $id) {
    id
    createdAt
    customer {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    merchant {
      id
      email
      onBoardingStage
      monthlyExpenses
      pin
      firstName
      lastName
      ipAddress
      type
      dateOfBirth
      ssn
      address1
      city
      state
      postalCode
      businessClassification
      businessType
      businessName
      title
      ein
      beneficialOwnersJson
      controllerJson
      notificationToken
      verificationStatus
      subscriptionId
      selectedAccount
      sourceAccounts {
        nextToken
      }
      targetAccounts {
        nextToken
      }
      merchantTransactions {
        nextToken
      }
      customerTransactions {
        nextToken
      }
      availableBalance
      merchandise
      customerPoints
      awaitingPinVerification
      currentTransactionJson
      merchantRewardDetails
      customerRewardsLol {
        nextToken
      }
      merchantRewardLinkLol {
        id
        points
        updatedAt
      }
      expectingTip
      dwollaBalance
      weeklyExpenses
      dba
      menuKey
      imageType
      merchantEnabled
      weeklyTarget
      weeklyCustomerAmount
      weeklyEarnings
      covidInfo
      recentVisitorObjects {
        nextToken
      }
      recentlyVisitedLocations {
        nextToken
      }
      customerReceipts {
        nextToken
      }
      merchantMenus {
        nextToken
      }
      merchantReceipts {
        nextToken
      }
    }
    served
    menuItems
  }
}
`;
export const listReceipts = `query ListReceipts(
  $filter: ModelReceiptFilterInput
  $limit: Int
  $nextToken: String
) {
  listReceipts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      customer {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
        weeklyCustomerAmount
        weeklyEarnings
        covidInfo
      }
      merchant {
        id
        email
        onBoardingStage
        monthlyExpenses
        pin
        firstName
        lastName
        ipAddress
        type
        dateOfBirth
        ssn
        address1
        city
        state
        postalCode
        businessClassification
        businessType
        businessName
        title
        ein
        beneficialOwnersJson
        controllerJson
        notificationToken
        verificationStatus
        subscriptionId
        selectedAccount
        availableBalance
        merchandise
        customerPoints
        awaitingPinVerification
        currentTransactionJson
        merchantRewardDetails
        expectingTip
        dwollaBalance
        weeklyExpenses
        dba
        menuKey
        imageType
        merchantEnabled
        weeklyTarget
        weeklyCustomerAmount
        weeklyEarnings
        covidInfo
      }
      served
      menuItems
    }
    nextToken
  }
}
`;
export const getMoreMerchantTransactions = `query GetCustomer($id: ID!,$nextToken:String) {
  getCustomer(id: $id) {
		merchantTransactions(nextToken:$nextToken,sortDirection:DESC,limit:100){
      items{
        id
        amount
        customerName
        merchantName
        paymentStatus
        merchantInformation{
          firstName
          lastName
          dba
          id
        }
        customerInformation{
          firstName
          lastName
          dba
          id
        }
        createdAt
      }
      nextToken
    }
  }
}
`;
export const getMoreTransactions = `query GetCustomer($id: ID!,$nextToken:String) {
  getCustomer(id: $id) {
		merchantTransactions(nextToken:$nextToken,sortDirection:DESC){
      items {
        id
        subscriptionId
        customerSubscriptionId
        createdAt
        merchantName
        customerName
        tipAmount
        taxAmount
        amount
        rewardInformation
        paymentStatus
        merchantInformation{
          id
          menuKey
          merchantEnabled
          selectedAccount
          dba
          firstName
          lastName
          merchantRewardDetails
        }
        customerInformation{
          id
          firstName
          lastName
          dba
          selectedAccount
          merchantEnabled
        }
      }
      nextToken
    }
  }
}
`
