/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.jsx";
import AuthLayout from "layouts/Auth.jsx";
import Unauthorized from "landing/src/index.js";
import Verification from "./landing/src/views/examples/Verification.jsx";
import PrivacyPolicy from "./privacy/PrivacyPolicy";
import TermsOfService from "./privacy/TermsOfService";
import TestQRCode from "./landing/src/views/examples/TestQRCode.jsx";
import KiktQRCode from "./landing/src/views/examples/KiktQRCode.jsx";
import Support from "./landing/src/views/examples/Support.jsx";
import Sound from 'react-sound';

import Amplify, {Auth} from 'aws-amplify';
import awsconfig from './aws-exports';
import {withAuthenticator} from 'aws-amplify-react';
import {getCustomer} from './graphql/queries';
import {onUpdateTransactionMerchant,onUpdateCustomer} from './graphql/subscriptions';
import API, { graphqlOperation } from '@aws-amplify/api';

Amplify.configure(awsconfig);

// const myAudio = new Audio('https://notificationsounds.com/notification-sounds/piece-of-cake-611/download/mp3')

class Main extends React.Component{

  state={
    loading:true
  }

  async componentDidMount(){
    try{
      const user = await Auth.currentAuthenticatedUser()
      console.log("Getting User Information")
      // var temp_id = "625fe7e2-08ca-4492-bebd-cb016c437e72"
      // console.log(user)
      const user_info = await API.graphql(graphqlOperation(getCustomer,{id:user.attributes.sub}))
      // user_info.data.getCustomer.verificationStatus = "retry"
      console.log("Got user info:",user_info.data.getCustomer)
      // user_info.data.getCustomer.verificationStatus="verified"
      this.setState({user:user_info.data.getCustomer,loading:false})
      if (true){
        let input = {"subscriptionId":user_info.data.getCustomer.id}
        var customer_subscription = API.graphql(graphqlOperation(onUpdateCustomer,input))
        customer_subscription.subscribe({next:(data)=>{
          console.log("Got Subscription Event",data.value.data.onUpdateCustomer);
          // if (data.value.data.onUpdateCustomer.weeklyEarnings===undefined){
          //   console.log("WTF")
          //   data.value.data.onUpdateCustomer.weeklyEarnings=this.state.user.weeklyEarnings
          // }
          this.setState({user:data.value.data.onUpdateCustomer});
          }
        })
      }
      if (user_info.data.getCustomer.verificationStatus==="verified"&&user_info.data.getCustomer.merchantEnabled&&(user_info.data.getCustomer.selectedAccount!==null)){
        let input = {"subscriptionId":user_info.data.getCustomer.id}
        const transaction_subscription = API.graphql(graphqlOperation(onUpdateTransactionMerchant,input))
        transaction_subscription.subscribe({next:(data)=>{
          console.log("Got Subscription Event at Index",data.value.data.onUpdateTransactionMerchant);
          const user_copy = Object.assign({},this.state.user)
          user_copy.merchantTransactions.items = [data.value.data.onUpdateTransactionMerchant,...user_copy.merchantTransactions.items]
          if (data.value.data.onUpdateTransactionMerchant.receipt!==null){
            this.setState({playingSound:true})
            data.value.data.onUpdateTransactionMerchant.receipt.customer = data.value.data.onUpdateTransactionMerchant.customerInformation
            data.value.data.onUpdateTransactionMerchant.receipt.attachedTransaction=data.value.data.onUpdateTransactionMerchant
            user_copy.merchantReceipts.items=[data.value.data.onUpdateTransactionMerchant.receipt,...user_copy.merchantReceipts.items]
          }
          this.setState({user:user_copy});
          }
        })
      }
      // Set up MFA
      if (user.attributes.phone_number_verified&&(user.preferredMFA==="NOMFA")){
        console.log("Enabling MFA")
        Auth.setPreferredMFA(user, 'SMS');
        console.log("Finished enabling mfa")
      }
    }
    catch (err){
      console.log(err)
    }
    // this.setState({loading:false,user:user})
    // console.log("USER",this.state.user)
  }


  render(){
    if (this.state.loading){
      return(
        <BrowserRouter/>
      )
    }
    else{
      // if (this.state.user.verificationStatus!="verified"&&this.state.user.verificationStatus!=""){
      //   return(
      //     <BrowserRouter>
      //       <Verification user={this.state.user}/>
      //     </BrowserRouter>
      //   )
      // }
      // else{
        return(
          <BrowserRouter>
          {/*(this.state.playingSound)&&<Sound url='https://notificationsounds.com/notification-sounds/piece-of-cake-611/download/mp3' autoLoad playStatus={Sound.status.PLAYING} onFinishedPlaying={()=>this.setState({playingSound:false})}/>*/}

            <Switch>
              <Route path="/admin" render={props => <AdminLayout {...props} user={this.state.user} playingSound={this.state.playingSound}/>} />
              <Route path="/auth" render={props => <AuthLayout {...props} user={this.state.user} />} />
              <Route path="/privacy-policy" render={props => <PrivacyPolicy {...props} user={this.state.user}/> } />
              <Route path="/terms-of-service" render={props => <TermsOfService {...props} user={this.state.user} /> } />
              <Route
                path="/test-qr-code"
                exact
                render={props=><TestQRCode {...props}/>}
              />
              <Route
                path="/kikt-qr-code"
                exact
                render={props=><KiktQRCode {...props}/>}
              />
              <Route
                path="/support"
                exact
                render={props=><Support {...props}/>}
              />
              <Redirect from="/" to="/admin/index" />
            </Switch>
          </BrowserRouter>
        )
      // }
    }
  }
}

const AuthenticatedApp = withAuthenticator(Main,false,[
  <Unauthorized/>
])

ReactDOM.render(
  <AuthenticatedApp/>,
  document.getElementById("root")
);
